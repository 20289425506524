import { Box, ClickAwayListener, TextField } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import * as React from "react";
import { useEffect, useState } from "react";
import { Region } from "../../Api/Model";
import RegionItem from "./RegionItem";

interface Props {
    onlySuburb?: boolean;
    disabled?: boolean;
    regions: Region[];
    selectedRegion?: Region;
    onClickCallback: (clickedItem: Region, allChildren: number[]) => void;
}

const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        justifyContent: "space-between",
    },
    content: {
        backgroundColor: "white",
        border: "1px solid black",
        position: "absolute",
        zIndex: 2,
        width: "100%",
    },
    tree: {
        height: "200px",
        overflow: "auto",
    },
    treeList: {
        listStyleType: "none",
        margin: "5px",
        padding: "5px",
    },
}));

export interface RegionWithChildren {
    region: Region;
    children?: Region[];
}

const buildLookupTable = (regions: Region[]): Map<number, RegionWithChildren> => {
    var lookupTable = new Map<number, RegionWithChildren>();

    regions.forEach((region) => {
        lookupTable.set(region.pkRegion, { region: region, children: [] });
    });

    regions.forEach((region) => {
        let parent = lookupTable.get(region.fkParentRegion);
        if (parent) {
            parent.children.push(region);
        }
    });
    return lookupTable;
};

const extractAllChildren = (region: Region, lookup: Map<number, RegionWithChildren>): number[] => {
    let current = lookup.get(region.pkRegion);
    if(current.children?.length > 0) {
        let list = []
        current.children.forEach(r => {
            list = list.concat(extractAllChildren(r, lookup))
        })
        return list;
    } else {
        return [current.region.pkRegion];
    }
}

const RegionDropdown: React.FC<Props> = (props: Props) => {
    const classes = useStyles();

    const { regions, selectedRegion, onClickCallback, disabled, onlySuburb } = props;
    const [regionLookup, setRegionLookup] = useState<Map<number, RegionWithChildren>>(new Map());
    const [optionsVisible, setOptionsVisible] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [parentRegions, setParentRegions] = useState<Region[]>([]);
    const [filteredRegions, setFilteredRegions] = useState<Region[]>([]);

    useEffect(() => {
        let lookup = buildLookupTable(regions);
        setRegionLookup(lookup);
        var parents: Region[] = [];
        regions.forEach((region) => {
            if (!lookup.get(region.fkParentRegion)) {
                parents.push(region);
            }
        });
        setParentRegions(parents);
    }, [regions]);

    //todo potentially introduce bounce delay
    useEffect(() => {
        if (searchText?.length > 0) {
            let text = searchText.toLowerCase();
            setFilteredRegions(regions
                .filter( r => !!!onlySuburb || r.isSuburb)
                .filter((r) => r.txtRegionName.toLowerCase().includes(text))
            );
        } else {
            setFilteredRegions([]);
        }
    }, [regions, searchText, onlySuburb]);

    const toggleOptions = () => {
        if(!!!disabled) setOptionsVisible(!optionsVisible);
    };

    const onClickAway = (event: any) => {
        if (optionsVisible) setOptionsVisible(false);
    };

    const searchTextChange = (e) => {
        setSearchText(e.target.value);
    };

    const onRegionSelected = (selection: Region) => {
        setOptionsVisible(false);
        onClickCallback(selection, extractAllChildren(selection, regionLookup));
        setSearchText("");
    };
    return (
        <ClickAwayListener mouseEvent="onClick" onClickAway={onClickAway}>
            <Box position="relative">
                <Box className={classes.header} onClick={toggleOptions}>
                    <TextField
                        disabled={disabled}
                        name="location"
                        id="location"
                        label="Select town, suburb or region"
                        value={selectedRegion ? selectedRegion.txtRegionName : "Select a Region"}
                        variant="outlined"
                        fullWidth
                    />
                </Box>
                <Box>
                    <Box className={classes.content} style={{ display: optionsVisible ? "block" : "none" }}>
                        <div className={classes.tree}>
                            <ul style={{ display: !!!searchText ? "block" : "none" }} className={classes.treeList}>
                                {parentRegions.map((region) => {
                                    return (
                                        <RegionItem
                                            onlySuburb={onlySuburb}
                                            key={region.pkRegion}
                                            item={region}
                                            regionLookup={regionLookup}
                                            selectedRegion={selectedRegion}
                                            onSelectCallback={onRegionSelected}
                                        />
                                    );
                                })}
                            </ul>
                            {searchText?.length > 0 && (
                                <ul>
                                    {filteredRegions.length > 0
                                        ? filteredRegions.map((region) => {
                                              return (
                                                  <RegionItem
                                                    onlySuburb={onlySuburb}
                                                      key={region.pkRegion}
                                                      item={region}
                                                      selectedRegion={selectedRegion}
                                                      onSelectCallback={onRegionSelected}
                                                  />
                                              );
                                          })
                                        : "No Results"}
                                </ul>
                            )}
                        </div>
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={searchText}
                            onChange={searchTextChange}
                            placeholder={"Find a Location"}
                            name="search"
                        />
                    </Box>
                </Box>
            </Box>
        </ClickAwayListener>
    );
};

export default RegionDropdown;
