import ApiListing from "../../Api/ApiListing";
import { ListingApprovalDTO, ListingDTO, ListingFullDTO, ListingWebsiteDTO } from "../../Api/Model";
import { FilterValues as ApprovalsFilterValues } from "../../Components/Dashboard/Approvals/Filters";
import { FilterValues as ListingsFilterValues } from "../../Components/Dashboard/Listings/Filters";
import { TableSetting } from "../../Components/UI/EnhancedTable";
import { AppState } from "../root-reducer";
import { actionTypes as at } from "./constants";

export const fetchMyListingsAction = () => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: at.DASHBOARD_FETCH_LISTINGS,
                payload: {},
            });
            const listings = await ApiListing.getListings(
                "",
                "",
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                true,
                true,
                10000
            );
            dispatch(fetchMyListingsSuccessAction(listings));
        } catch (err) {
            dispatch(fetchMyListingsErrorAction(err));
        }
    };
};

export const fetchMyListingsSuccessAction = (listings: ListingDTO[]) => {
    return {
        type: at.DASHBOARD_FETCH_LISTINGS_SUCCESS,
        payload: {
            listings: listings,
        },
    };
};

export const fetchMyListingsErrorAction = (error: Error) => {
    return {
        type: at.DASHBOARD_FETCH_LISTINGS_ERROR,
        payload: {
            error: error,
        },
    };
};

export const listingsFilterUpdatedAction = (values: ListingsFilterValues) => {
    return {
        type: at.DASHBOARD_LISTINGS_FILTER_UPDATED,
        payload: values,
    };
};

export const listingsTableSettingUpdatedAction = (setting: TableSetting) => {
    return {
        type: at.DASHBOARD_LISTINGS_TABLE_SETTING_UPDATED,
        payload: setting,
    };
};

export const listingDeletedAction = (pkListing: number) => {
    return {
        type: at.DASHBOARD_LISTING_DELETED,
        payload: {
            pkListing,
        },
    };
};

export const listingUpdatedAction = (listing: ListingDTO) => {
    return {
        type: at.DASHBOARD_LISTING_UPDATED,
        payload: {
            listing,
        },
    };
};

export const listingUpdatedFromWizardAction = (full: ListingFullDTO) => {
    return async (dispatch, getState) => {
        let state: AppState = getState();
        const { pkListing, isVisible, txtListingName, dtApproved, dtLastUpdated, bitApproved, fkListingType, txtTypeName } = full.details;
        let listing = state.dashboardReducer.listings.find((l) => l.pkListing === pkListing);
        if (listing) {
            let copy = { ...listing };
            copy.txtListingName = txtListingName;
            copy.dtLastUpdated = dtLastUpdated;
            copy.isVisible = isVisible;
            copy.dtApproved = dtApproved;
            copy.isApproved = bitApproved;
            copy.fkListingType = fkListingType;
            copy.txtListingTypeName = txtTypeName;
            dispatch(listingUpdatedAction(copy));
        }
        let listingApprovals = state.dashboardReducer.approvalsListings.find(l => l.listingID === pkListing)
        if(listingApprovals) {
            let copy = {...listingApprovals}
            copy.listingName = txtListingName;
            copy.dtLastUpdated = dtLastUpdated;
            copy.isVisible = isVisible;
            copy.dtApproved = dtApproved;
            copy.isApproved = bitApproved;
            copy.listingTypeID = fkListingType;
            copy.listingTypeName = txtTypeName;
            dispatch(listingApprovalsUpdatedAction(copy));
        }
    };
};

export const listingApprovalsUpdatedAction = (listing: ListingApprovalDTO) => {
    return {
        type: at.DASHBOARD_APPROVALS_LISTING_UPDATED,
        payload: {
            listing,
        },
    };
};

export const fetchMyWebsiteListingsAction = (licenseId: number) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: at.DASHBOARD_FETCH_MY_WEBSITE_LISTINGS,
                payload: {
                    license: licenseId,
                },
            });
            const listings = await ApiListing.getMyWebsiteListings(licenseId);
            dispatch({
                type: at.DASHBOARD_FETCH_MY_WEBSITE_LISTINGS_SUCCESS,
                payload: {
                    listings: listings,
                    license: licenseId,
                },
            });
        } catch (err) {
            dispatch({
                type: at.DASHBOARD_FETCH_MY_WEBSITE_LISTINGS_ERROR,
                payload: {
                    error: err,
                },
            });
        }
    };
};

export const myWebsiteListingUpdated = (listing: ListingWebsiteDTO) => {
    return {
        type: at.DASHBOARD_MY_WEBSITE_LISTING_UPDATED,
        payload: {
            listing: listing,
        },
    };
};

export const fetchListingsApprovalsAction = (licenseId: number, includeExpired: boolean) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: at.DASHBOARD_FETCH_APPROVALS_LISTINGS,
                payload: {
                    license: licenseId,
                    expired: includeExpired
                },
            });
            const listings = await ApiListing.getApprovalListings(licenseId, includeExpired);
            dispatch({
                type: at.DASHBOARD_FETCH_APPROVALS_LISTINGS_SUCCESS,
                payload: {
                    listings: listings,
                    license: licenseId,
                    expired: includeExpired
                },
            });
        } catch (err) {
            console.log(err);
            dispatch({
                type: at.DASHBOARD_FETCH_APPROVALS_LISTINGS_ERROR,
                payload: {
                    error: err,
                },
            });
        }
    };
};

export const approvalsListingUpdatedAction = (listing: ListingApprovalDTO) => {
    return {
        type: at.DASHBOARD_APPROVALS_LISTING_UPDATED,
        payload: {
            listing: listing,
        },
    };
};

export const actionApprovalsListingAdded = (listing: ListingApprovalDTO) => {
    return {
        type: at.DASHBOARD_APPROVALS_LISTING_ADDED,
        payload: {
            listing: listing,
        },
    };
};

export const actionApprovalsRefresh = () => {
    return async (dispatch, getState) => {
        let state: AppState = getState();
        dispatch(fetchListingsApprovalsAction(state.dashboardReducer.approvalsLicense, state.dashboardReducer.approvalsExpired));
    }
}

export const approvalsFilterUpdatedAction = (values: ApprovalsFilterValues) => {
    return {
        type: at.DASHBOARD_APPROVALS_FILTER_UPDATED,
        payload: values,
    };
};

export const approvalsTableSettingUpdatedAction = (setting: TableSetting) => {
    return {
        type: at.DASHBOARD_APPROVALS_TABLE_SETTING_UPDATED,
        payload: setting,
    };
};
