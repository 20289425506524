import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const useStyles = makeStyles((theme) => ({
    noDataContainer: {
        minHeight: 'calc(100vh - 348px)'
    },
    header: {
        fontSize: '35px',
        marginBottom:'20px',
        marginTop:'10px'
    },
    text: {

    }
}));

const ListingTableFirstTimeUser = () => {
    const classes = useStyles();

    return (
        <Container className={classes.noDataContainer}>

            <Typography className={classes.header} variant="h3" align="center">Welcome to localista</Typography>
            <Typography className={classes.text} variant="h6" align="center">Select 'Add New Listing' to get started.</Typography>
        </Container>
    )
};

export default ListingTableFirstTimeUser;