
export default class FileUtil {

	public static readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
		// this: File or Blob
		return new Promise((resolve) => {
			let fr = new FileReader();
			fr.onload = () => {
				resolve(fr.result as ArrayBuffer);
			};
			fr.readAsArrayBuffer(file);
		})
	}

	/**
		 * Converts a File (such as from Dropper) to a base64 image string for use in img src
		 */
	static fileToBase64Image(file: File): Promise<string> {
		return new Promise<string>((resolve, _) => {
			let reader = new FileReader();
			reader.onload = () => {
				var image = new Image();
				image.src = reader.result as string;
				image.onload = () => {
					let imageString: string = reader.result as string;
					resolve(imageString);
				};
			};

			reader.readAsDataURL(file);
		});
	}

	/**
	 * Converts a base64 image to a File object
	 * 
	 * https://stackoverflow.com/a/15754051/646998
	 */
	static base64ImageToFile(base64: string, filename: string, type: string): Promise<File> {
		return new Promise<File>((resolve, _) => {
			const bytesString = atob(base64.split(',')[1]);
			const arrayBuffer = new ArrayBuffer(bytesString.length);
			var array = new Uint8Array(arrayBuffer);

			for (var i = 0; i < bytesString.length; i++) {
				array[i] = (bytesString.charCodeAt(i));
			}

			var blob = new Blob([new Uint8Array(array)], { type: type });
			var file = new File([blob], filename, { type: blob.type });
			resolve(file);
		});
	}

}