import { Grid, Typography, TextField, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ApiListing from '../../../../Api/ApiListing';
import { ImageDTO } from '../../../../Api/Model';
import { updateListingSuccessAction, uploadImageAction } from '../../../../Store/Wizard/actions';
import FileUtil from '../../../../Util/FileUtil';
import ScoopUtil from '../../../../Util/ScoopUtil';
import LoadingDialog from '../../../UI/LoadingDialog';
import CropImage from './CropImage';
import SelectImage from './SelectImage';

interface Props {
    pkListing: number;
    file?: File;
    image?: ImageDTO;
    onFinish: () => void;
}

const AddUpdateImage = (props: Props) => {
    const { pkListing, file, image } = props;
    const dispatch = useDispatch();
    let params = useParams<{ id: any; licenseId: any }>();

    const [selectedFile, setSelectedFile] = useState<File>(file);
    const [croppedFile, setCroppedFile] = useState<File>(null);
    const [croppedFileBase64, setCroppedFileBase64] = useState<string>(null);
    const [caption, setCaption] = useState<string>(image?.txtCaption);
    const [credit, setCredit] = useState<string>(image?.txtCredit);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (croppedFile) {
            FileUtil.fileToBase64Image(croppedFile).then(base64 => setCroppedFileBase64(base64))
        }
    }, [croppedFile])

    const onSave = () => {
        if (image) {
            setSaving(true);
            ApiListing.updateImage(pkListing, caption, credit, image.isPrimary, image.pkImage, params.licenseId)
                .then(res => {
                    dispatch(updateListingSuccessAction(res));
                    props.onFinish();
                }).finally(() => setSaving(false));
        } else {
            dispatch(uploadImageAction(pkListing, caption, credit, croppedFile, params.licenseId));
            props.onFinish();
        }

    }

    if (!selectedFile && !!!image) {
        return (
            <SelectImage
                minHeight={900}
                minWidth={1200}
                onFileSelected={file => {
                    setSelectedFile(file);
                }}
                onError={message => console.log('Error: ', message)}

            />
        )
    }

    if (selectedFile && !croppedFile && !!!image) {
        return (
            <CropImage
                minHeight={900}
                minWidth={1200}
                file={selectedFile}
                onFileCropped={(file) => setCroppedFile(file)}
                onCancel={props.onFinish}
            />
        )
    }

    if (selectedFile && croppedFile && !croppedFileBase64) {
        return (
            <div>
                Loading
            </div>
        )
    }

    if (image || (selectedFile && croppedFile && croppedFileBase64)) {
        return (
            <>
                <Grid container spacing={6}>


                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            {image ? 'Update image' : 'Add image'}
                        </Typography>
                        <img style={{ width: "100%" }} src={image ? ScoopUtil.urlImageLarge(image) : croppedFileBase64} alt="preview" />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="caption"
                            id="caption"
                            label="Image caption"
                            placeholder="Image caption"
                            multiline
                            rows={2}
                            value={caption}
                            onChange={(e) => {
                                setCaption(e.target.value)
                            }}
                            variant="outlined"
                            fullWidth
                        // helperText={`${200 - teaser.length} characters left`}
                        // error={teaser.length > 200}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="credit"
                            id="credit"
                            label="Image credit"
                            placeholder="Image credit"
                            value={credit}
                            onChange={(e) => {
                                setCredit(e.target.value)
                            }}
                            variant="outlined"
                            fullWidth
                        // helperText={`${200 - teaser.length} characters left`}
                        // error={teaser.length > 200}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={onSave}>Save</Button>
                        <Button onClick={props.onFinish}>Cancel</Button>
                    </Grid>


                </Grid>
                {saving && <LoadingDialog text={'Processing updates'} />}

            </>
    )
    }

    return <>
        {saving && <LoadingDialog text={'Processing updates'} />}
    </>


};

export default AddUpdateImage;