import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


interface Props {
  onConfirm: () => void;
  onClose: () => void;
  message: string;
  title?: string;
  confirmText?: string;
  closeText?: string;
}

const AlertDialog = (props: Props) => {
  const { title, message, confirmText, closeText, onConfirm, onClose } = props;
  return (
      <Dialog
        open={true}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {closeText ? closeText : 'Cancel'}
          </Button>
          <Button onClick={onConfirm} color="primary" autoFocus>
            {confirmText ? confirmText : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default AlertDialog;