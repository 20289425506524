import { Button, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ApiListing from "../../../../Api/ApiListing";
import { ListingDirectoryDTO, ListingFullDTO, ListingTypeDTO } from "../../../../Api/Model";
import { snackMessageAction, snackMessageCaughtErrorAction } from "../../../../Store/App/actions";
import { selectDirectories, selectTypes } from "../../../../Store/App/selectors";
import { fetchMyListingsAction } from "../../../../Store/Dashboard/actions";
import {
    listingModifiedAction,
    updateListingSuccessAction,
    updateWizardStepAction,
} from "../../../../Store/Wizard/actions";
import { selectAllowEdit, selectListing } from "../../../../Store/Wizard/selectors";
import ScoopUtil from "../../../../Util/ScoopUtil";
import { deepEqual } from "../../../../Util/Util";
import WizardGuide from "../../WizardGuide";
import WizardHeader from "../../WizardHeader";
import WizardMain from "../../WizardMain";
import WizardSidebar from "../../WizardSidebar";
import { WizardStep } from "../../WizardSteps";
import BackForwardButtons from "../BackForwardButtons";
import { FormDetailsAdminValues, FormDetailsValues } from "../form-values";
import SectionGrid from "../SectionGrid";
import DetailsForm from "./DetailsForm";
import ListingDetailsSection from "./ListingDetailsSection";
import UsersFilter from "./UsersFilter";
import { selectUser } from "../../../../Store/User/selectors";
import AdminOnlyForm from "./AdminOnlyForm";

const mapListingFormValues = (
    listing: ListingFullDTO,
    types: ListingTypeDTO[],
    directories: ListingDirectoryDTO[]
): FormDetailsValues => {
    const { txtListingName, fkListingType } = listing.details;
    const typeForListing = types.find((t) => t.pkListingType === fkListingType);
    const directoryForListing = directories.find((t) => t.pkListingTypeGroupId === typeForListing?.pkListingTypeGroup);
    return {
        title: txtListingName,
        typeId: fkListingType,
        directoryId: directoryForListing?.pkListingTypeGroupId || 0,
    };
};

const ALERT_MESSAGE =
    "By changing listing-type, previously selected categories in the 'features' tab will be deleted on confirmation. Update as required.";

const DetailsStep = () => {
    const dispatch = useDispatch();
    let params = useParams<{ id: any; licenseId: any }>();
    const user = useSelector(selectUser);
    const listing = useSelector(selectListing);
    const allowEdit = useSelector(selectAllowEdit);
    const types = useSelector(selectTypes);
    const directories = useSelector(selectDirectories);

    const [origValues, setOrigValues] = useState<FormDetailsValues>(undefined);
    const [values, setValues] = useState<FormDetailsValues>(undefined);
    const [adminOrigValues, setAdminOrigValues] = useState<FormDetailsAdminValues>(undefined);
    const [adminValues, setAdminValues] = useState<FormDetailsAdminValues>(undefined);
    const [formValid, setFormValid] = useState(false);

    const [newSeo, setNewSeo] = useState<string>(undefined);

    const isAdmin = user?.userLevel === 2;

    useEffect(() => {
        if (types && directories) {
            let initialValues: FormDetailsValues = {
                title: "",
                typeId: 0,
                directoryId: 0,
            };
            let initialAdminValues: FormDetailsAdminValues = {
                includeInSearch: true,
                includeInSitemaps: true,
            };
            if (listing) {
                initialValues = mapListingFormValues(listing, types, directories);
                initialAdminValues = {
                    includeInSearch: listing.details.includeInSearch,
                    includeInSitemaps: listing.details.includeInSitemaps,
                };
            }
            setOrigValues(initialValues);
            setValues(initialValues);
            setAdminOrigValues(initialAdminValues);
            setAdminValues(initialAdminValues);
        }
    }, [listing, types, directories]);

    const generatePromise = (): Promise<ListingFullDTO> => {
        if (formValid) {
            let promise: Promise<ListingFullDTO> = undefined;

            if (listing === undefined) {
                let userId = ScoopUtil.tryGetParamNumber("userId");
                promise = ApiListing.createListing(values.title, values.typeId, userId).then((res) => {
                    dispatch(fetchMyListingsAction());
                    return res;
                });
            } else if (!deepEqual(origValues, values)) {
                promise = ApiListing.updateListing(
                    listing.details.pkListing,
                    values.title,
                    values.typeId,
                    params.licenseId
                );
            }
            if (isAdmin && !deepEqual(adminOrigValues, adminValues)) {
                if (promise === undefined) {
                    promise = ApiListing.updateListingSearchSitemaps(
                        listing.details.pkListing,
                        adminValues.includeInSearch,
                        adminValues.includeInSitemaps,
                        params.licenseId
                    );
                } else {
                    promise = promise.then((res) => {
                        return ApiListing.updateListingSearchSitemaps(
                            res.details.pkListing,
                            adminValues.includeInSearch,
                            adminValues.includeInSitemaps,
                            params.licenseId
                        );
                    });
                }
            }
            return promise;
        }
        return Promise.reject("Form is not valid");
    };

    const onWizardStepChange = (step: WizardStep) => {
        if (formValid) {
            let promise: Promise<ListingFullDTO> = generatePromise();
            dispatch(updateWizardStepAction(step, promise));
        }
    };

    const onSaveHook = (): Promise<any> => {
        if (listing || formValid) {
            return generatePromise();
        } else {
            return Promise.resolve();
        }
    };

    const onValuesChanged = (values: FormDetailsValues) => {
        setValues(values);
        if (origValues.typeId !== 0 && values.typeId !== origValues.typeId) {
            dispatch(snackMessageAction(ALERT_MESSAGE, "warning"));
        }
    };

    useEffect(() => {
        dispatch(listingModifiedAction(!deepEqual(origValues, values) || !deepEqual(adminOrigValues, adminValues)));
        setFormValid(values?.title?.length > 0 && values?.typeId > 0);
        if (origValues?.title !== values?.title) {
            setNewSeo(undefined);
        }
    }, [values, origValues, adminOrigValues, adminValues, dispatch]);

    const onGenerateClick = async () => {
        try {
            let updatePromise = generatePromise();
            if (updatePromise) {
                let updated = await updatePromise;
                dispatch(updateListingSuccessAction(updated));
            }
            let s = await ApiListing.generateNewSeoNamePreview(listing.details.pkListing, params.licenseId);
            setNewSeo(s);
        } catch (err) {
            dispatch(snackMessageCaughtErrorAction(err));
        }
    };

    const onConfirmClick = async () => {
        try {
            let updated = await ApiListing.generateNewSeoName(listing.details.pkListing, params.licenseId);
            setNewSeo(undefined);
            dispatch(updateListingSuccessAction(updated));
        } catch (err) {
            dispatch(snackMessageCaughtErrorAction(err));
        }
    };

    return (
        <>
            <WizardHeader
                buttonText={!!!formValid && listing === undefined ? "RETURN" : undefined}
                disabled={!!!formValid && listing !== undefined}
                backToListingsHook={onSaveHook}
            />

            <WizardSidebar disableAll={!!!formValid} onSaveHook={onSaveHook} />

            <WizardMain>
                {values && (
                    <SectionGrid title={"Title and Type"}>
                        <DetailsForm
                            disabled={!allowEdit}
                            types={types}
                            directories={directories}
                            values={values}
                            onChange={onValuesChanged}
                        />
                        <Grid item xs={12}>
                            <TextField
                                disabled
                                label="Listing SEO Name"
                                value={listing?.details.txtListingNameSEO || ""}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        {newSeo ? (
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                        disabled
                                        label="New Listing SEO Name"
                                        value={newSeo}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" onClick={onConfirmClick}>
                                        Confirm
                                    </Button>
                                    <Button
                                        style={{ marginLeft: "10px" }}
                                        variant="contained"
                                        onClick={() => setNewSeo(undefined)}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </>
                        ) : (
                            <Grid item xs={12}>
                                <Button disabled={listing === undefined} variant="contained" onClick={onGenerateClick}>
                                    Generate New SEO
                                </Button>
                            </Grid>
                        )}
                    </SectionGrid>
                )}
                {listing?.approval?.allowListingEdit && (
                    <SectionGrid title="Listing owner">
                        <Grid item xs={12}>
                            <UsersFilter licenseId={params.licenseId} listing={listing.approval} />
                        </Grid>
                    </SectionGrid>
                )}
                {listing && (
                    <SectionGrid title={"Listing Details"}>
                        <ListingDetailsSection listing={listing} />
                    </SectionGrid>
                )}

                {isAdmin && adminValues && (
                    <SectionGrid title={"Administrator-users only"}>
                        <AdminOnlyForm values={adminValues} onChange={setAdminValues} />
                    </SectionGrid>
                )}

                <BackForwardButtons
                    disabled={!!!formValid}
                    onClickNext={() =>
                        onWizardStepChange(
                            ScoopUtil.allowApprove(listing) ? WizardStep.Approval : WizardStep.ContactInformation
                        )
                    }
                />
            </WizardMain>

            <WizardGuide step={WizardStep.Details} />
        </>
    );
};

export default DetailsStep;
