import { Checkbox, FormControlLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";
import { FormDetailsAdminValues } from "../form-values";

interface Props {
    values: FormDetailsAdminValues;
    onChange: (values: FormDetailsAdminValues) => void;
}

const AdminOnlyForm = (props: Props) => {
    const { values, onChange } = props;
    const { includeInSearch, includeInSitemaps } = values;

    const onSearchChange = (checked: boolean) => {
        onChange({ ...values, includeInSearch: checked });
    };

    const onSitemapsChange = (checked: boolean) => {
        onChange({ ...values, includeInSitemaps: checked });
    }

    return (
        <>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={includeInSearch}
                            color="primary"
                            onClick={() => onSearchChange(!includeInSearch)}
                        />
                    }
                    label={`Include in localista search`}
                />
            </Grid>

            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={includeInSitemaps}
                            color="primary"
                            onClick={() => onSitemapsChange(!includeInSitemaps)}
                        />
                    }
                    label={`Include in Sitemaps`}
                />
            </Grid>
        </>
    );
};

export default AdminOnlyForm;
