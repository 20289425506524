import { DatePicker } from "@mui/lab";
import { FormControlLabel, FormLabel, InputAdornment, Radio } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React from "react";
import DateUtil from "../../../../Util/DateUtil";

interface Props {
    disabled: boolean;
    dtEventTo?: string;
    maxOccurrences: number;
    onChange: (maxOccurrences: number, dateTo?: string) => void;
}

const RecurringDatesStop = (props: Props) => {
    const { disabled, dtEventTo, maxOccurrences } = props;

    const onStopRepeatChange = (value: number) => {
        if (value > 0) {
            props.onChange(value);
        } else {
            props.onChange(0, dtEventTo);
        }
    };

    const onMaxOccurrencesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value);
        props.onChange(value < 1 ? 1 : value);
    };

    const onDateToChange = (date: string) => {
        const value = DateUtil.formatDateString(new Date(date));
        props.onChange(0, value);
    };

    return (
        <>
            <Grid item xs={12}>
                <FormLabel component="legend">Stop repeating</FormLabel>
            </Grid>
            <Grid item xs={12} container>
                <Grid item xs={4} md={2}>
                    <FormControlLabel
                        disabled={disabled}
                        checked={maxOccurrences > 0}
                        onClick={() => onStopRepeatChange(1)}
                        control={<Radio color="primary" />}
                        label="After"
                    />
                </Grid>
                <Grid item xs={8} md={4}>
                    <TextField
                        disabled={disabled}
                        type="number"
                        value={maxOccurrences}
                        onChange={onMaxOccurrencesChange}
                        name="occurrences"
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position="end">occurrences</InputAdornment>,
                        }}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} container>
                <Grid item xs={4} md={2}>
                    <FormControlLabel
                        disabled={disabled}
                        checked={maxOccurrences === 0}
                        onClick={() => onStopRepeatChange(0)}
                        control={<Radio color="primary" />}
                        label="On"
                    />
                </Grid>
                <Grid item xs={8} md={4}>
                    <DatePicker
                        disabled={disabled}
                        disablePast
                        inputFormat="dd/MM/yyyy"
                        value={maxOccurrences === 0 ? dtEventTo : null}
                        onChange={(newValue) => onDateToChange(newValue)}
                        renderInput={(props) => <TextField fullWidth {...props} />}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default RecurringDatesStop;
