import { Box, Divider, Grid, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            display: "inline-flex",
            width: "100%",
        },
        all: {
            backgroundColor: "#EDF6FB",
            padding: "15px",
            borderRadius: "10px",
        },
        your: {
            width: "450px",
            backgroundColor: "#F1F6F1",
            marginLeft: "30px",
            padding: "15px",
            borderRadius: "10px",
        },
        title: {
            marginBottom: "10px",
            width: "100%",
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "bold"
        },
        sectionBox: {},
        valueBox: {
            textAlign: "center",
            "& p" : {
                fontSize: "14px"
            }
        },
        value: {
            fontWeight: "600",
            fontSize: "20px!important",
        },
    })
);

export interface DetailsData {
    searches: number;
    total: number;
    avgFree?: number;
    avgPremium?: number;
    listing: number;
    avgListing?: number;
}

interface Props {
    name: string;
    data: DetailsData;
}

const PerformanceMetricDetails = (props: Props) => {
    const classes = useStyles();
    const { name, data } = props;
    const { searches, total, avgFree, avgPremium, listing, avgListing } = data;
    return (
        <Box className={classes.wrapper}>
            <Grid container className={classes.all}>
                <Grid container item xs={6}>
                    <Grid item xs={12}>
                        <Typography className={classes.title}>Results for all listings</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.valueBox}>
                        <Typography className={classes.value}>{searches?.toLocaleString()}</Typography>
                        <Typography>total searches</Typography>
                        <Typography>over 12 months</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.valueBox}>
                        <Typography className={classes.value}>{total?.toLocaleString()}</Typography>
                        <Typography>{`total ${name}`}</Typography>
                        <Typography>over 12 months</Typography>
                    </Grid>
                </Grid>
                {avgFree ? (
                    <>
                        <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />
                        <Grid container item xs={3}>
                            <Grid item xs={12}>
                                <Typography className={classes.title}>Free listings</Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.valueBox}>
                                <Typography className={classes.value}>{avgFree?.toLocaleString()}</Typography>
                                <Typography>{`average ${name} per`}</Typography>
                                <Typography>listing per month</Typography>
                            </Grid>
                        </Grid>
                    </>
                ) : null}
                {avgPremium ? (
                    <>
                        <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />
                        <Grid container item xs={3}>
                            <Grid item xs={12}>
                                <Typography className={classes.title}>Premium listings</Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.valueBox}>
                                <Typography className={classes.value}>{avgPremium?.toLocaleString()}</Typography>
                                <Typography>{`average ${name} per`}</Typography>
                                <Typography>listing per month</Typography>
                            </Grid>
                        </Grid>
                    </>
                ) : null}
            </Grid>
            <Grid container className={classes.your}>
                <Grid item xs={12}>
                    <Typography className={classes.title}>Your listing</Typography>
                </Grid>
                <Grid item xs={6} className={classes.valueBox}>
                    <Typography className={classes.value}>{listing?.toLocaleString()}</Typography>
                    <Typography>{`total ${name}`}</Typography>
                    <Typography>over 12 months</Typography>
                </Grid>

                <Grid item xs={6} className={classes.valueBox}>
                    <Typography className={classes.value}>{avgListing?.toLocaleString()}</Typography>
                    <Typography>{`${name} per month`}</Typography>
                    <Typography>over 12 months</Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default PerformanceMetricDetails;
