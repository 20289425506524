import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { AnalyticsDTO } from "../../../Api/Model";
import MonthlyTable from "./MonthlyTable";
import PerformanceChart from "./PerformanceChart";
import PerformanceMetricsTab from "./PerformanceMetricsTabs";
import { format } from 'date-fns'

const useStyles = makeStyles((theme) => ({
    title: {
        "& h2": {
            fontWeight: "700",
            fontSize: "30px"
        },
    },
    section: {
        backgroundColor: "#F3F3F3",
        padding: "10px",
        borderRadius: "15px",
        marginTop: "15px",
        marginBottom: "10px",
        "& h5": {
            fontWeight: "600",
            fontSize: "22px",
        },
    },
    chart: {
        width: "100%",
        height: "420px",
    },
    dashboard: {
        backgroundColor: theme.palette.common.white,
        margin: "15px",
    },
    table: {
        paddingBottom: "5px"
    }
}));

interface Props {
    analytics: AnalyticsDTO;
}

const AnalyticsDashboard = (props: Props) => {
    const { analytics } = props;
    const classes = useStyles();
    const [dateLabel, setDateLabel] = useState('')

    useEffect(() => {
        const datesOrdered = analytics.performance
            .map(p => new Date(p.year, p.month -1, 1))
            .sort((d1, d2) => d1.getTime() - d2.getTime());
        setDateLabel(`${format(datesOrdered[0], 'MMM yyyy')} to ${format(datesOrdered[datesOrdered.length - 1], 'MMM yyyy')}`);
    }, [analytics])

    return (
        <Box className={classes.dashboard}>
            <Box className={classes.title}>
                <Typography variant="h2">{analytics.listingName}</Typography>
            </Box>
            <Box>
                <Box className={classes.section}>
                    <Typography variant="h5">Views - your listing v industry averages</Typography>
                </Box>
            </Box>

            <Box className={classes.chart}>
                <PerformanceChart performance={analytics.performance} />
            </Box>

            <Box className={classes.section}>
                <Typography variant="h5">Performance metrics</Typography>
                <Typography variant="h5">{`for all '${analytics.typeName?.toLowerCase()}' listings in ${analytics.regionName} - ${dateLabel}`}</Typography>
            </Box>

            <Box>
                <PerformanceMetricsTab analytics={analytics} />
            </Box>

            <Box className={classes.section}>
                <Typography variant="h5">Your listing performance over 12 months</Typography>
            </Box>

            <Box className={classes.table}>
                <MonthlyTable performance={analytics.performance} />
            </Box>
        </Box>
    );
};

export default AnalyticsDashboard;
