import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListingLevelLinkDTO } from "../../../../Api/Model";
import { updateWizardStepAction } from "../../../../Store/Wizard/actions";
import { selectListing, selectWizardConfig } from "../../../../Store/Wizard/selectors";
import WizardGuide from "../../WizardGuide";
import WizardHeader from "../../WizardHeader";
import WizardMain from "../../WizardMain";
import WizardSidebar from "../../WizardSidebar";
import { WizardStep } from "../../WizardSteps";
import BackForwardButtons from "../BackForwardButtons";
import FormGrid from "../FormGrid";
import SectionGrid from "../SectionGrid";
import ListingLevelDetailsDialog from "./ListingLevelDetailsDialog";
import ListingLevelTable from "./ListingLevelTable";
import PremiumFormEvents from "./PremiumFormEvents";
import PremiumFormNonEvents from "./PremiumFormNonEvents";
import UserActionButton from "./UserActionButton";

const PremiumLevelsStep = () => {
    const dispatch = useDispatch();
    const listing = useSelector(selectListing);
    const { allowOpeningHours } = useSelector(selectWizardConfig);
    const [opened, setOpened] = useState<number>(undefined);
    const [showTransactions, setShowTransaction] = useState<ListingLevelLinkDTO>(undefined);

    const onWizardStepChange = (step: WizardStep) => {
        dispatch(updateWizardStepAction(step));
    };

    const onSaveHook = (): Promise<any> => {
        return undefined;
    };

    const createActionButton = (ll: ListingLevelLinkDTO): JSX.Element => {
        return (
            <UserActionButton
                levelLink={ll}
                onOpen={setOpened}
                opened={opened}
                onShowTransactions={(ll) => {
                    setShowTransaction(ll);
                    setOpened(undefined);
                }}
            />
        );
    };

    return (
        <>
            <WizardHeader backToListingsHook={onSaveHook} />

            <WizardSidebar onSaveHook={onSaveHook} />

            <WizardMain>
                <FormGrid>
                    <SectionGrid title={"Premium Listing Subscriptions"}>
                        {listing?.details?.allowEvents ? <PremiumFormEvents /> : <PremiumFormNonEvents />}
                    </SectionGrid>

                    <SectionGrid title={"Listing levels"}>
                        <Grid item xs={12}>
                            <ListingLevelTable createActionButton={createActionButton} />
                        </Grid>
                    </SectionGrid>
                </FormGrid>
                <BackForwardButtons
                    onClickPrevious={() => onWizardStepChange(allowOpeningHours ? WizardStep.OpeningHours : WizardStep.Categories)}
                    onClickNext={() => onWizardStepChange(WizardStep.Images)}
                />
            </WizardMain>

            <WizardGuide />

            {showTransactions && (
                <ListingLevelDetailsDialog link={showTransactions} onClose={() => setShowTransaction(undefined)} />
            )}
        </>
    );
};

export default PremiumLevelsStep;
