import {
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { OpeningHoursData } from "../../../../Api/Model";
import DateUtil from "../../../../Util/DateUtil";
import FormGrid from "../FormGrid";
import SectionGrid from "../SectionGrid";
import { FormOpeningHoursValues } from "../form-values";
import HoursForm from "./HoursForm";

const styles = makeStyles({
  statusFormControl: {
    marginBottom: "10px",
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
    "& .MuiRadio-root": {
      marginRight: "15px",
    },
  },
});

interface Props {
  disabled: boolean;
  values: FormOpeningHoursValues;
  onChange: (values: FormOpeningHoursValues, valid: boolean) => void;
  lastUpdated?: string;
}

const CHARACTERS_LIMIT_ERROR_INFO = 255;

const OpeningHoursForm = (props: Props) => {
  const { values, disabled, lastUpdated, onChange } = props;
  const { statusId } = values;
  let classes = styles();

  const [infoError, setInfoError] = useState(false);
  const [infoHelp, setInfoHelp] = useState<string>("");

  useEffect(() => {
    if (values.additionalInfo.length > CHARACTERS_LIMIT_ERROR_INFO) {
        setInfoError(true);
        setInfoHelp(`${values.additionalInfo.length - CHARACTERS_LIMIT_ERROR_INFO} characters over the limit`);
    } else {
        setInfoError(false);
        setInfoHelp(`${CHARACTERS_LIMIT_ERROR_INFO - values.additionalInfo.length} characters left`);
    }
}, [values.additionalInfo]);

  const onStatusChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const copy = { ...values };
    copy.statusId = Number(e.target.value);
    onChange(copy, true);
  };

  const onAdditionalInfoChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const copy = { ...values };
    copy.additionalInfo = e.target.value;
    onChange(copy, copy.additionalInfo.length <= CHARACTERS_LIMIT_ERROR_INFO);
  };

  const onHoursChanged = (data: OpeningHoursData, valid: boolean) => {
    const copy = { ...values };
    copy.data = data;;
    onChange(copy, true);
  };
  

  return (
    <FormGrid>
      <SectionGrid
        title={"Opening hours"}
        note={`Last updated ${DateUtil.formatDateFromString(lastUpdated)}`}
      />
      <SectionGrid
        title={"Operating status"}
      >
        <Grid item xs={12}>
          <RadioGroup value={statusId} onChange={onStatusChanged}>
            <FormControl className={classes.statusFormControl}>
              <FormControlLabel
                label={
                  <>
                    <FormLabel>Open with main hours</FormLabel>
                    <FormHelperText>Set main business hours</FormHelperText>
                  </>
                }
                value={1}
                control={<Radio />}
              />
            </FormControl>

            <FormControl className={classes.statusFormControl}>
              <FormControlLabel
                label={
                  <>
                    <FormLabel>Open with no main hours</FormLabel>
                    <FormHelperText>
                      Don't show any business hours
                    </FormHelperText>
                  </>
                }
                value={2}
                control={<Radio />}
              />
            </FormControl>

            <FormControl className={classes.statusFormControl}>
              <FormControlLabel
                label={
                  <>
                    <FormLabel>Temporarily closed</FormLabel>
                    <FormHelperText>
                      Show that your business will open again in the future
                    </FormHelperText>
                  </>
                }
                value={3}
                control={<Radio />}
              />
            </FormControl>

            <FormControl className={classes.statusFormControl}>
              <FormControlLabel
                label={
                  <>
                    <FormLabel>Permanently closed</FormLabel>
                    <FormHelperText>
                      Show that your business no longer exists
                    </FormHelperText>
                  </>
                }
                value={4}
                control={<Radio />}
              />
            </FormControl>
          </RadioGroup>
        </Grid>

        <Grid item xs={12}>
          <TextField
            disabled={disabled}
            fullWidth
            label="Additional information"
            variant="outlined"
            value={values.additionalInfo}
            name="additionalInfo"
            id="additionalInfo"
            onChange={onAdditionalInfoChanged}
            helperText={infoHelp}
            error={infoError}
            multiline
            rows={3}
          />
        </Grid>

      </SectionGrid>
      {statusId === 1 && <SectionGrid title="Opening days & hours">
          <HoursForm disabled={disabled} data={values.data} onChange={onHoursChanged} />
          </SectionGrid>
          }

    </FormGrid>
  );
};

export default OpeningHoursForm;
