import { ButtonGroup, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { green, red } from '@mui/material/colors';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activateListingAction } from '../../Store/Wizard/actions';
import { selectListing, selectListingModified } from '../../Store/Wizard/selectors';
import ReturnButton from './ReturnButton';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    contianer: {
        backgroundColor: theme.palette.common.white,
        height: '64px',
        padding: '0px 10px'
    },
    activeButton: {
        backgroundColor: "white"
    },
    inactiveButton: {
        backgroundColor: "grey",
        color: "white"
    },
    previewContainer: {
        marginRight: '20px'
    },
    toggleButtonGroup: {
        border: '1px solid black'
    },
    toggleButtonActive: {
        color: 'white!important',
        padding: '8px 22px',
        backgroundColor: green[700],
        cursor: 'default',
        '&:hover': {
            backgroundColor: green[500]
        }
    },
    toggleButtonInactive: {
        color: 'white!important',
        padding: '8px 22px',
        backgroundColor: red[700],
        cursor: 'default',
        '&:hover': {
            backgroundColor: red[500]
        }
    },
    toggleButton: {
        color: 'black!important' as any,
        padding: '8px 22px',
        backgroundColor: '#B9B9B9!important',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark
        }
    },
    listingName: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center'
        },
    }
}));

interface Props {
    buttonText?: string;
    disabled?: boolean;
    backToListingsHook: () => Promise<any>;
}

const WizardHeader = (props: Props) => {
    let params = useParams<{ id: any; licenseId: any }>();

    const theme = useTheme();
    const widthXS = useMediaQuery(theme.breakpoints.down('sm'));
    
    const { disabled, buttonText } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const listing = useSelector(selectListing);
    const modified = useSelector(selectListingModified);

    const [isVisible, setIsVisible] = useState<boolean>(false);

    const handleActive = (checked: boolean) => {
        if (listing) {
            dispatch(activateListingAction(checked, params.licenseId));
        }
    };

    useEffect(() => {
        if (listing) {
            setIsVisible(listing.details.isVisible);
        }
    }, [listing])

    return (<>
        <Grid className={classes.contianer} container alignItems="center">
            {!!!widthXS && <Grid item>
                <ButtonGroup className={classes.previewContainer}>
                    <ReturnButton 
                        onSaveHook={props.backToListingsHook} 
                        disabled={disabled} 
                        buttonText={buttonText ? buttonText : modified ? 'SAVE & RETURN' : 'RETURN'}
                        size="large"
                    />
                </ButtonGroup>
            </Grid>}
            <Grid item xs>
                <Typography className={classes.listingName} component="h2">
                    {listing ? listing.details.txtListingName : "New listing"}
                </Typography>
            </Grid>
            {!!!widthXS && <Grid item>
                <ToggleButtonGroup className={classes.toggleButtonGroup} exclusive aria-label="device">
                    <ToggleButton title="Activate listing" value={true} disabled={isVisible || modified} onClick={() => handleActive(true)} className={isVisible ? classes.toggleButtonActive : classes.toggleButton}>
                        Active
                    </ToggleButton>
                    <ToggleButton title="Inactivate listing" value={false} disabled={!!!isVisible || modified} onClick={() => handleActive(false)} className={isVisible ? classes.toggleButton : classes.toggleButtonInactive}>
                        Inactive
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>}
        </Grid>
    </>
    )
};

export default WizardHeader;