import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import makeStyles from '@mui/styles/makeStyles';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(3),
    },
}));

const Footer = () => {

    const classes = useStyles();

    return (
        <footer className={classes.footer}>
            <Typography color="inherit" align="center">
                <Link color="inherit" href="https://localista.com.au/wp-content/uploads/2020/12/Localista-Terms-and-Conditions.pdf" target="_blank">
                    Terms and Conditions
                </Link>{' | '}
                <Link color="inherit" href="https://localista.com.au/wp-content/uploads/2020/12/Localista-Privacy-Policy.pdf" target="_blank">
                    Privacy
                </Link>
                </Typography>
            <Typography color="inherit" align="center">
                {'Copyright © '}
                <Link color="inherit" href="https://scoop.com.au/">
                    Localista {config.ENV}
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </footer>
    )
};

export default Footer;