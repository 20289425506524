import { LinearProgress } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import makeStyles from '@mui/styles/makeStyles';
import { SnackbarProvider } from 'notistack';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import Dashboard from './Components/Dashboard/Dashboard';
import LandingPage from './Components/LandingPage/LandingPage';
import ErrorsDialog from './Components/UI/ErrorsDialog';
import Footer from './Components/UI/Footer';
import NavigationBar from './Components/UI/NavigationBar';
import ScoopSnackMessage from './Components/UI/ScoopSnackMessage';
import Wizard from './Components/Wizard/Wizard';
import { fetchMetadataAction, fetchMetadataAdminAction, fetchMetadataPublicAction, fetchUsersAction as fetchUsersForAutocompleteAction } from './Store/App/actions';
import { fetchListingsApprovalsAction, fetchMyListingsAction, fetchMyWebsiteListingsAction } from './Store/Dashboard/actions';
import { validateTokenAndFetchUserAction } from './Store/User/actions';
import { selectUser } from './Store/User/selectors';
import { selectUploadingImages } from './Store/Wizard/selectors';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { actionFetchUsers } from './Store/Users/actions';
import WidgetPage from './Components/WidgetPage';
import Config from './config';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.grey[200],
    },
    uploadingBar: {
        backgroundColor: theme.palette.success.main,
        height: '8px'
    }
}));

const alertUser = e => {
    e.returnValue = ''
}

const App = () => {
    const dispatch = useDispatch();

    const classes = useStyles();

    const user = useSelector(selectUser);
    const uploadingImages = useSelector(selectUploadingImages);

    useEffect(() => {
        if(user) {
            dispatch(fetchMetadataAction());
            dispatch(fetchMyListingsAction());
        }
        if(user && user.approvalLicenses?.length > 0) {
            dispatch(fetchListingsApprovalsAction(user.approvalLicenses[0].pkLicense, false));
            dispatch(fetchUsersForAutocompleteAction());
        }
        if(user && user.contentLicenses?.length > 0) {
            dispatch(fetchMyWebsiteListingsAction(user.contentLicenses[0].pkLicense));
        }
        if(user && user.userLevel === 2) {
            dispatch(fetchMetadataAdminAction());
            dispatch(actionFetchUsers());
        }
    }, [dispatch, user]);

    useEffect(() => {
        dispatch(validateTokenAndFetchUserAction());
        dispatch(fetchMetadataPublicAction());
    }, [dispatch]);

    useEffect(() => {
        if (uploadingImages > 0) {
            window.addEventListener('beforeunload', alertUser)
        } else {
            window.removeEventListener('beforeunload', alertUser)
        }
    }, [uploadingImages]);

    useEffect(() => {
        (window as any).Chargebee.init({
            site: Config.CHARGEBEE_SITE
        });
        return () => {
            window.removeEventListener('beforeunload', alertUser)
        }
    }, []);

    return (
        <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} autoHideDuration={5000}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <BrowserRouter>
                    <ScoopSnackMessage />
                    <ErrorsDialog />
                    <CssBaseline />
                    <NavigationBar />
                    {uploadingImages > 0 && <LinearProgress className={classes.uploadingBar} />}
                    <main className={classes.root}>
                        <Switch>
                            {user ? <>
                                <Route exact path="/">
                                    <Dashboard />
                                </Route>
                                <Route exact path="/:tab">
                                    <Dashboard />
                                </Route>
                                <Route exact path="/wizard">
                                    <Wizard />
                                </Route>
                                <Route exact path="/wizard/:id">
                                    <Wizard />
                                </Route>
                                <Route exact path="/wizard/:id/:licenseId">
                                    <Wizard />
                                </Route>
                                <Route exact path="/widget/:license/:region/:type">
                                    <WidgetPage/>
                                </Route>
                            </>
                                :
                                <>

                                    <Route path="/">
                                        <LandingPage />
                                    </Route>
                                </>
                            }
                        </Switch>
                    </main>
                    <Footer />
                </BrowserRouter>
            </LocalizationProvider >
        </SnackbarProvider >
    );
}


export default App;

