import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListingTypeDTO, Region, ScoopStatus, Status } from "../../../Api/Model";
import { approvalsFilterUpdatedAction, fetchListingsApprovalsAction } from "../../../Store/Dashboard/actions";
import {
    selectApprovalsExpired,
    selectApprovalsFilter,
    selectApprovalsListingsLoading,
    selectApprovalsSelectedLicense
} from "../../../Store/Dashboard/selectors";
import { selectApprovalLicenses } from "../../../Store/User/selectors";
import RegionDropdown from "../../UI/RegionDropdown";

export interface ValidFilterValues {
    types: number[];
    regions: number[];
}

export interface FilterValues {
    userKeyword: string;
    listingKeyword: string;
    status: Status;
    scoopStatus?: ScoopStatus;
    typeId: number;
    region?: {
        region: Region;
        allChildren: number[];
    };
}

interface Props {
    allowedRegions: Region[];
    allowedTypes: ListingTypeDTO[];
}

const Filters = (props: Props) => {
    const dispatch = useDispatch();
    const filterValues = useSelector(selectApprovalsFilter);
    const { allowedRegions, allowedTypes } = props;
    const { userKeyword, listingKeyword, status, scoopStatus, typeId, region } = filterValues;
    const approvalLicenses = useSelector(selectApprovalLicenses);

    const licenseId = useSelector(selectApprovalsSelectedLicense);
    const listingsLoading = useSelector(selectApprovalsListingsLoading);
    const showExpired = useSelector(selectApprovalsExpired);

    const onLicenseIdChange = (e: SelectChangeEvent<Number>) => {
        dispatch(fetchListingsApprovalsAction(e.target.value as any as number, showExpired));
    };

    const onFilterValuesUpdate = (values: FilterValues) => {
        dispatch(approvalsFilterUpdatedAction(values));
    };

    const onExpiredChange = (e: SelectChangeEvent<Number>) => {
        let value = (e.target.value as any as number) > 0 ? true : false;
        dispatch(fetchListingsApprovalsAction(licenseId, value));
    };

    const onListingStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onFilterValuesUpdate({ ...filterValues, status: e.target.value as Status });
    };

    const onScoopStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newStatus: ScoopStatus;
        if (e.target.value === "all") {
            newStatus = undefined;
        } else {
            newStatus = e.target.value as ScoopStatus;
        }
        onFilterValuesUpdate({ ...filterValues, scoopStatus: newStatus });
    };

    const onRegionSelected = (region: Region, allChildren: number[]) => {
        onFilterValuesUpdate({ ...filterValues, region: { region, allChildren } });
    };

    const onTypeChange = (e: SelectChangeEvent<Number>) => {
        let value = e.target.value as any as number;
        onFilterValuesUpdate({ ...filterValues, typeId: value });
    };

    const onUserKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onFilterValuesUpdate({ ...filterValues, userKeyword: e.target.value });
    };

    const onListingKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onFilterValuesUpdate({ ...filterValues, listingKeyword: e.target.value });
    };

    return (
        <>
            <Grid item sm={4} xs={12}>
                <FormControl variant="outlined" fullWidth disabled={listingsLoading}>
                    <InputLabel id="select-type-label">Approval License</InputLabel>
                    <Select label="Approval License" value={licenseId || 0} onChange={onLicenseIdChange}>
                        {approvalLicenses?.map((lic) => (
                            <MenuItem
                                key={`license_${lic.pkLicense}`}
                                value={lic.pkLicense}
                            >{`${lic.txtLicenseName} (${lic.pkLicense})`}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item sm={4} xs={4}>
                <RegionDropdown
                    disabled={listingsLoading}
                    selectedRegion={region?.region}
                    regions={allowedRegions}
                    onClickCallback={onRegionSelected}
                />
            </Grid>

            <Grid item sm={4} xs={4}>
                <FormControl variant="outlined" fullWidth disabled={listingsLoading}>
                    <InputLabel id="select-type-label">Listing Type</InputLabel>
                    <Select label="Listing Type" value={typeId} onChange={onTypeChange}>
                        <MenuItem key={`type_unselected`} value={0}>
                            All
                        </MenuItem>
                        {allowedTypes.map((t) => (
                            <MenuItem key={`type_${t.pkListingType}`} value={t.pkListingType}>
                                {t.txtTypeName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item sm={3} xs={12}>
                <TextField
                    disabled={listingsLoading}
                    variant="outlined"
                    label="Search Title"
                    placeholder="Search Title"
                    value={listingKeyword}
                    fullWidth
                    onChange={onListingKeywordChange}
                />
            </Grid>

            <Grid item sm={3} xs={12}>
                <TextField
                    disabled={listingsLoading}
                    variant="outlined"
                    label="Search Users"
                    placeholder="Search Users"
                    value={userKeyword}
                    fullWidth
                    onChange={onUserKeywordChange}
                />
            </Grid>

            <Grid item sm={2} xs={4}>
                <FormControl variant="outlined" fullWidth disabled={listingsLoading}>
                    <InputLabel id="select-type-label">Activated</InputLabel>
                    <Select label="Activated" value={status} onChange={onListingStatusChange}>
                        <MenuItem key={`all`} value={Status.All}>
                            All
                        </MenuItem>
                        <MenuItem key={`excluded`} value={Status.Active}>
                            Active
                        </MenuItem>
                        <MenuItem key={`included`} value={Status.Inactive}>
                            Inactive
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item sm={2} xs={4}>
                <FormControl variant="outlined" fullWidth disabled={listingsLoading}>
                    <InputLabel id="select-type-label">Approved</InputLabel>
                    <Select label="Approved" value={scoopStatus || "all"} onChange={onScoopStatusChange}>
                        <MenuItem key={`all`} value={"all"}>
                            All
                        </MenuItem>
                        <MenuItem key={`approved`} value={ScoopStatus.APPROVED}>
                            Approved
                        </MenuItem>
                        <MenuItem key={`unapproved`} value={ScoopStatus.UNAPPROVED}>
                            Unapproved
                        </MenuItem>
                        <MenuItem key={`pending`} value={ScoopStatus.PENDING}>
                            Pending
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item sm={2} xs={4}>
                <FormControl variant="outlined" fullWidth disabled={listingsLoading}>
                    <InputLabel id="select-type-label">Include Expired</InputLabel>
                    <Select label="Include Expired" value={showExpired ? 1 : 0} onChange={onExpiredChange}>
                        <MenuItem key={`show`} value={1}>
                            Yes
                        </MenuItem>
                        <MenuItem key={`noShow`} value={0}>
                            No
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </>
    );
};

export default Filters;
