import { getValidToken } from "../Store/LocalStorage/UserStorage";
import Api from "./Api";
import { ListingFullDTO, PremiumDetailsDTO, PremiumDetailsUserDTO, PremiumEstimateDTO, PremiumPlanDTO } from "./Model";

export interface BodyPremiumListing
{
    itemPriceId: string;
    itemId: string;
    itemFamilyId: string;
    referralCode?: string;
    listingLevelLinkId?: number;
    itemType: string;
    currencyCode: string;
    startDate?: string;
    endDate?: string;
}

export default class ApiPremium extends Api {


    public static getListingMetadataPremiumPlans(listingType: number): Promise<PremiumPlanDTO[]> {
        const token = getValidToken();
        if (token) {
            return this.axios
                .get(`/admin/premium/plans?listingTypeId=${listingType}`, {
                    headers: { Authorization: "Bearer " + token },
                })
                .then((res: any) => {
                    return res.data.plans;
                });
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static getEstimate(listingID: number, body: BodyPremiumListing): Promise<PremiumEstimateDTO> {
        const token = getValidToken();
        if (token) {
            return this.axios
                .post(`/admin/premium/estimate?listingId=${listingID}`, body, {
                    headers: { Authorization: "Bearer " + token },
                })
                .then((res: any) => {
                    return res.data;
                });
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static subscribeToPlan(listingID: number, body: BodyPremiumListing): Promise<any> {
        const token = getValidToken();
        if (token) {
            return this.axios
                .post(`/admin/premium/subscribe?listingId=${listingID}`, body, {
                    headers: { Authorization: "Bearer " + token },
                })
                .then((res: any) => {
                    return res.data;
                });
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static processHostedPage(listingID: number, hostedPageId: string): Promise<ListingFullDTO> {
        const token = getValidToken();
        if (token) {
            const body = {
                hostedPageId
            };
            return this.axios
                .post(`/admin/premium/hosted-page?listingId=${listingID}`, body, {
                    headers: { Authorization: "Bearer " + token },
                })
                .then((res: any) => {
                    return res.data;
                });
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static cancelPremiumLevel(listingID: number, listingLevelLinkId: number): Promise<ListingFullDTO> {
        const token = getValidToken();
        if (token) {
            return this.axios
                .delete(`/admin/premium/cancel?listingId=${listingID}&listingLevelLinkId=${listingLevelLinkId}`, {
                    headers: { Authorization: "Bearer " + token },
                })
                .then((res: any) => {
                    return res.data;
                });
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static reactivateSubscription(listingID: number, listingLevelLinkId: number): Promise<ListingFullDTO> {
        const token = getValidToken();
        if (token) {
            return this.axios
                .delete(`/admin/premium/reactivate?listingId=${listingID}&listingLevelLinkId=${listingLevelLinkId}`, {
                    headers: { Authorization: "Bearer " + token },
                })
                .then((res: any) => {
                    return res.data;
                });
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static getPremiumDetails(listingID: number, listingLevelLinkId: number): Promise<PremiumDetailsDTO> {
        const token = getValidToken();
        if (token) {
            const params = {
                listingId: listingID,
                listingLevelLinkId: listingLevelLinkId
            }
            return this.axios
                .get(`/admin/premium/details`, {
                    params: params,
                    headers: { Authorization: "Bearer " + token },
                })
                .then((res: any) => {
                    return res.data;
                });
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static getInvoicePdfLink(chargebeeInvoiceID?: string): Promise<string> {
        const token = getValidToken();
        if (token) {
            const params = {
                chargebeeInvoiceId: chargebeeInvoiceID,
            }
            return this.axios
                .get(`/admin/premium/invoice/pdf`, {
                    params: params,
                    headers: { Authorization: "Bearer " + token },
                })
                .then((res: any) => {
                    return res.data.downloadUrl;
                });
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static getCreditNotePdfLink(chargebeeCreditNoteID?: string): Promise<string> {
        const token = getValidToken();
        if (token) {
            const params = {
                chargebeeCreditNoteId: chargebeeCreditNoteID,
            }
            return this.axios
                .get(`/admin/premium/credit-note/pdf`, {
                    params: params,
                    headers: { Authorization: "Bearer " + token },
                })
                .then((res: any) => {
                    return res.data.downloadUrl;
                });
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static updatePayment(listingID: number): Promise<any> {
        const token = getValidToken();
        if (token) {
            return this.axios
                .post(`/admin/premium/payment-update?listingId=${listingID}`, {}, {
                    headers: { Authorization: "Bearer " + token },
                })
                .then((res: any) => {
                    return res.data;
                });
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static getPremiumDetailsUser(): Promise<PremiumDetailsUserDTO> {
        const token = getValidToken();
        if (token) {
            return this.axios
                .get(`/admin/premium/user`, {
                    headers: { Authorization: "Bearer " + token },
                })
                .then((res: any) => {
                    return res.data;
                });
        } else {
            return Promise.reject("Not logged in");
        }
    }

}
