import { Fade, IconButton, Paper, Popper, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { ReactNode, useRef } from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative'
        },
        actions: {
            padding: '5px'
        },
        actionRow: {
            display: 'flex',
            alignItems: 'center',
        },
        actionLabel: {
            flexGrow: 1,
            textAlign: 'right',
            marginRight: '5px'
        },
        noPadding: {
            padding: 0
        }
    }),
);


interface Props {
    open: boolean;
    onToggle: () => void;
    children: ReactNode;
    size?: 'small' | 'medium' | 'large';
    noPadding?: boolean;
}

const ActionButton = (props: Props) => {
    const classes = useStyles();
    const anchorEl = useRef(null);

    const { open, onToggle, children, size, noPadding } = props;



    return <>
        <IconButton className={noPadding ? classes.noPadding : undefined} ref={anchorEl} onClick={onToggle} size={size || 'large'}>
            {open ? <MoreHorizIcon /> : <MoreVertIcon />}
        </IconButton>
        <Popper open={open} anchorEl={anchorEl.current} placement={'top-end'} transition style={{zIndex: 10000}}>
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper className={classes.actions}>
                        {children}
                    </Paper>
                </Fade>
            )}
        </Popper>

    </>;
}

export default ActionButton;

interface QuickActionProps {
    label: String;
    icon: React.ReactElement;
    onClick: () => void;
}

export const QuickAction = (props: QuickActionProps) => {
    const classes = useStyles();

    return (
        <div className={classes.actionRow}>
            <Typography className={classes.actionLabel}>
                {props.label}
            </Typography>
            <IconButton onClick={props.onClick} size="large">
                {props.icon}
            </IconButton>
        </div>
    );
}