import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkIcon from "@mui/icons-material/Link";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PhoneIcon from "@mui/icons-material/Phone";
import PintrestIcon from "@mui/icons-material/Pinterest";
import XIcon from "@mui/icons-material/X";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import ScoopUtil from "../../../../Util/ScoopUtil";
import { FormContactsValues } from "../form-values";
import FormGrid from "../FormGrid";
import SectionGrid from "../SectionGrid";

interface Props {
    disabled: boolean;
    values: FormContactsValues;
    onChange: (values: FormContactsValues, valid: boolean) => void;
    showBookingLink: boolean;
    isAdmin: boolean;
    showAffiliateLink: boolean;
}

const ContactInformationForm = (props: Props) => {
    const { values, disabled, showBookingLink, isAdmin, showAffiliateLink } = props;
    const {
        phoneNumber,
        email,
        website,
        bookingLink,
        affiliateLink,
        facebookUrl,
        twitterUrl,
        instagramUrl,
        linkedinUrl,
        pintrestUrl,
        otherUrl,
    } = values;

    const [usedSocialLinks, setUsedSocialLinks] = useState(0);

    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [websiteError, setWebsiteError] = useState(false);
    const [bookingLinkError, setBookingLinkError] = useState(false);
    const [affiliateLinkError, setAffiliateLinkError] = useState(false);
    const [facebookError, setFacebookError] = useState(false);
    const [twitterError, setTwitterError] = useState(false);
    const [instagramError, setInstagramError] = useState(false);
    const [linkedinError, setLinkedinError] = useState(false);
    const [pintrestError, setPintrestError] = useState(false);
    const [otherError, setOtherError] = useState(false);

    useEffect(() => {
        var count = 0;
        if (facebookUrl?.length > 0) count++;
        if (twitterUrl?.length > 0) count++;
        if (instagramUrl?.length > 0) count++;
        if (linkedinUrl?.length > 0) count++;
        if (pintrestUrl?.length > 0) count++;
        if (otherUrl?.length > 0) count++;
        setUsedSocialLinks(count);
    }, [facebookUrl, instagramUrl, linkedinUrl, otherUrl, pintrestUrl, twitterUrl]);

    const validateUrlLink = (
        link: string,
        domains: string[],
        error: boolean,
        setError: (error: boolean) => void
    ): boolean => {
        let valid = true;
        let linkFormatted = link;
        if (link?.length > 0) {
            if (!(link.startsWith("http") || link.startsWith("wwww"))) {
                linkFormatted = "https://" + link;
            }
        }
        if (!ScoopUtil.isUrlValidOrEmpty(linkFormatted, domains)) {
            setError(true);
            valid = false;
        } else if (error) {
            setError(false);
        }
        return valid;
    };

    const onChange = (v: FormContactsValues) => {
        let formValid = true;
        if (!validateUrlLink(v.facebookUrl, ["facebook"], facebookError, setFacebookError)) formValid = false;
        if (!validateUrlLink(v.twitterUrl, ["twitter", "x"], twitterError, setTwitterError)) formValid = false;
        if (!validateUrlLink(v.linkedinUrl, ["linkedin"], linkedinError, setLinkedinError)) formValid = false;
        if (!validateUrlLink(v.instagramUrl, ["instagram"], instagramError, setInstagramError)) formValid = false;
        if (!validateUrlLink(v.pintrestUrl, ["pinterest"], pintrestError, setPintrestError)) formValid = false;
        if (!validateUrlLink(v.otherUrl, undefined, otherError, setOtherError)) formValid = false;

        if (v.phoneNumber?.length > 0 && !ScoopUtil.isPhoneNumberValid(v.phoneNumber)) {
            setPhoneNumberError(true);
            formValid = false;
        } else if (phoneNumberError) {
            setPhoneNumberError(false);
        }
        if (v.email?.length > 0 && !ScoopUtil.isEmailValid(v.email)) {
            setEmailError(true);
            formValid = false;
        } else if (emailError) {
            setEmailError(false);
        }

        if (!validateUrlLink(v.website, undefined, websiteError, setWebsiteError)) formValid = false;
        if (!validateUrlLink(v.bookingLink, undefined, bookingLinkError, setBookingLinkError)) formValid = false;
        if (!validateUrlLink(v.affiliateLink, undefined, affiliateLinkError, setAffiliateLinkError)) formValid = false;

        props.onChange(v, formValid);
    };

    const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const copy = { ...values };
        copy[e.target.id] = e.target.value;
        onChange(copy);
    };

    return (
        <FormGrid>
            <SectionGrid title={"Contact information"}>
                <Grid item xs={12}>
                    <TextField
                        disabled={disabled}
                        fullWidth
                        label="Phone number"
                        placeholder="0400222333"
                        variant="outlined"
                        value={phoneNumber}
                        name="phone"
                        id="phoneNumber"
                        onChange={onTextFieldChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PhoneIcon color="disabled" />
                                </InputAdornment>
                            ),
                        }}
                        error={phoneNumberError}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        disabled={disabled}
                        fullWidth
                        label="Email"
                        placeholder="example@example.com"
                        variant="outlined"
                        value={email}
                        id="email"
                        onChange={onTextFieldChange}
                        name="email"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon color="disabled" />
                                </InputAdornment>
                            ),
                        }}
                        error={emailError}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        disabled={disabled}
                        label="Website"
                        value={website}
                        id="website"
                        onChange={onTextFieldChange}
                        placeholder="Copy your websites's url here"
                        variant="outlined"
                        name="website"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={websiteError}
                    />
                </Grid>

                {showBookingLink && <Grid item xs={12}>
                    <TextField
                        disabled={disabled}
                        label="Booking link"
                        value={bookingLink}
                        id="bookingLink"
                        onChange={onTextFieldChange}
                        placeholder="Copy your url here"
                        variant="outlined"
                        name="bookingLink"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={bookingLinkError }
                    />
                </Grid>}

                {showAffiliateLink && <Grid item xs={12}>
                    <TextField
                        disabled={!isAdmin}
                        label="Affiliate link"
                        value={affiliateLink}
                        id="affiliateLink"
                        onChange={onTextFieldChange}
                        placeholder="Copy your url here"
                        variant="outlined"
                        name="affiliateLink"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={affiliateLinkError }
                        helperText={!isAdmin ? <Box color="red">This field is locked for localista admin users only.</Box> : undefined}
                    />
                </Grid>}

            </SectionGrid>

            <SectionGrid title={"Social Media"}>
                <Grid item xs={12}>
                    <TextField
                        disabled={disabled || (usedSocialLinks >= 3 && facebookUrl === "")}
                        helperText={
                            usedSocialLinks >= 3 && facebookUrl === "" ? "Only three social links can be used" : ""
                        }
                        style={{ width: "100%" }}
                        label="Facebook"
                        placeholder="Copy your facebook page's url here"
                        variant="outlined"
                        value={facebookUrl}
                        id="facebookUrl"
                        onChange={onTextFieldChange}
                        name="facebook"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FacebookIcon color="disabled" />
                                </InputAdornment>
                            ),
                        }}
                        error={facebookError}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        disabled={disabled || (usedSocialLinks >= 3 && twitterUrl === "")}
                        helperText={
                            usedSocialLinks >= 3 && twitterUrl === "" ? "Only three social links can be used" : ""
                        }
                        style={{ width: "100%" }}
                        label="X (Twitter)"
                        placeholder="Copy your X (Twitter) page's url here"
                        variant="outlined"
                        value={twitterUrl}
                        id="twitterUrl"
                        onChange={onTextFieldChange}
                        name="twitter"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <XIcon color="disabled" />
                                </InputAdornment>
                            ),
                        }}
                        error={twitterError}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        disabled={disabled || (usedSocialLinks >= 3 && instagramUrl === "")}
                        helperText={
                            usedSocialLinks >= 3 && instagramUrl === "" ? "Only three social links can be used" : ""
                        }
                        style={{ width: "100%" }}
                        label="Instagram"
                        value={instagramUrl}
                        id="instagramUrl"
                        onChange={onTextFieldChange}
                        placeholder="Copy your Instagram url here"
                        variant="outlined"
                        name="instagram"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <InstagramIcon color="disabled" />
                                </InputAdornment>
                            ),
                        }}
                        error={instagramError}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        disabled={disabled || (usedSocialLinks >= 3 && linkedinUrl === "")}
                        helperText={
                            usedSocialLinks >= 3 && linkedinUrl === "" ? "Only three social links can be used" : ""
                        }
                        style={{ width: "100%" }}
                        label="Linkedin"
                        value={linkedinUrl}
                        id="linkedinUrl"
                        onChange={onTextFieldChange}
                        placeholder="Copy your Linkedin url here"
                        variant="outlined"
                        name="linkedin"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LinkedInIcon color="disabled" />
                                </InputAdornment>
                            ),
                        }}
                        error={linkedinError}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        disabled={disabled || (usedSocialLinks >= 3 && pintrestUrl === "")}
                        style={{ width: "100%" }}
                        label="Pintrest"
                        value={pintrestUrl}
                        id="pintrestUrl"
                        onChange={onTextFieldChange}
                        placeholder="Copy your Pintrest url here"
                        variant="outlined"
                        name="pintrest"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PintrestIcon color="disabled" />
                                </InputAdornment>
                            ),
                        }}
                        error={pintrestError}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        disabled={disabled || (usedSocialLinks >= 3 && otherUrl === "")}
                        style={{ width: "100%" }}
                        label="Other"
                        value={otherUrl}
                        id="otherUrl"
                        onChange={onTextFieldChange}
                        placeholder="Have another social account?  Enter it here"
                        variant="outlined"
                        name="otherurl"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LinkIcon color="disabled" />
                                </InputAdornment>
                            ),
                        }}
                        error={otherError}
                    />
                </Grid>
            </SectionGrid>
        </FormGrid>
    );
};

export default ContactInformationForm;
