import {
    Box,
    Checkbox,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UserClientLicenseDTO, UserDTO, UserProfileDTO } from "../../Api/Model";
import { selectMetadataAdmin, selectMetadataPublic } from "../../Store/App/selectors";
import DateUtil from "../../Util/DateUtil";
import ScoopUtil from "../../Util/ScoopUtil";
import MetadataUtil, { CountryWithStates } from "../../Util/MetadataUtil";

export interface UserFormValues {
    email: string;
    company: string;
    displayname: string;
    firstname: string;
    lastname: string;
    address: string;
    suburb: string;
    postcode: string;
    countryId: number;
    stateId: number;
    phone: string;
    industries: number[];
    active: boolean;
    clientId: number;
    notes: string;
    password: string;
}

interface Props {
    isAdmin?: boolean;
    newUser?: boolean;
    values: UserFormValues;
    onChange: (formValues: UserFormValues) => void;
    user?: UserDTO;
    licenses?: UserClientLicenseDTO[];
}

const useStyles = makeStyles((theme) => ({
    label: {
        fontWeight: "bold",
        [theme.breakpoints.up("sm")]: {
            width: "120px",
        },
    },
    row: {
        marginBottom: "10px",
    },
}));

export const mapProfileToUserFormValues = (user?: UserProfileDTO): UserFormValues => {
    console.log('mappingProfile:', user?.countryId, user?.countryId || -1);
    return {
        email: user?.userName || "",
        company: user?.company || "",
        displayname: user?.displayName || "",
        firstname: user?.firstName || "",
        lastname: user?.lastName || "",
        address: user?.address || "",
        suburb: user?.suburb || "",
        postcode: user?.postCode || "",
        countryId: user?.countryId >= 0 ? user.countryId : -1,
        stateId: user?.stateId || -1,
        phone: user?.telephone || "",
        industries: user?.industries.map((i) => i.fkUserIndustryID) || [],
        active: user !== undefined ? user.active : true,
        clientId: user?.clientId || 0,
        notes: user?.notes || "",
        password: "",
    };
};

export const isUserFormValuesValid = (values: UserFormValues) => {
    const { email, industries, displayname, stateId } = values;
    return email?.length > 0 && industries?.length > 0 && ScoopUtil.isDisplaynameValid(displayname) && stateId > 0;
};

const UserForm = (props: Props) => {
    const { isAdmin, newUser, values, onChange, user, licenses } = props;
    let metadata = useSelector(selectMetadataPublic);
    let metadataAdmin = useSelector(selectMetadataAdmin);
    const {
        email,
        company,
        displayname,
        firstname,
        lastname,
        address,
        suburb,
        postcode,
        countryId,
        stateId,
        phone,
        industries,
        clientId,
        notes,
        active,
        password,
    } = values;

    const [countries, setCountries] = useState<CountryWithStates[]>([]);
    const [displaynameError, setDisplaynameError] = useState<string>(undefined);

    useEffect(() => {
        if (metadata) {
            let sortedCountries = MetadataUtil.mergeCountriesAndStates(metadata);
            setCountries(sortedCountries);
        }
    }, [metadata]);

    const onIndustryChange = (event: SelectChangeEvent<number[]>) => {
        let newIndustries = event.target.value as number[];
        if (newIndustries.length > 5) {
        } else {
            onChange({ ...values, industries: newIndustries });
        }
    };

    const onValueChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let copy = { ...values };
        copy[event.target.name] = event.target.value;
        onChange(copy);
    };

    const onStateChange = (event: SelectChangeEvent<number>) => {
        onChange({ ...values, stateId: event.target.value as number });
    };

    const onClientChange = (event: SelectChangeEvent<number>) => {
        onChange({ ...values, clientId: event.target.value as number });
    };

    const onStatusChange = (event: SelectChangeEvent<number>) => {
        onChange({ ...values, active: event.target.value > 0 ? true : false });
    };

    useEffect(() => {
        if (displayname.length > 0 && !ScoopUtil.isDisplaynameValid(displayname)) {
            setDisplaynameError("No spaces allowed and minimum 10 characters");
        } else {
            setDisplaynameError(undefined);
        }
    }, [displayname]);

    const onCountryChange = (event: SelectChangeEvent<number>) => {
        let value = event.target.value as number;
        onChange({ ...values, countryId: value,  stateId: -1 });
    }

    console.log("countries", countryId, countries);

    return (
        <Grid container spacing={2} padding={1}>
            <Grid item xs={12}>
                <Typography variant="h6">User details</Typography>
            </Grid>
            <Grid item md={6} xs={12}>
                <TextField
                    required
                    error={!!!(email?.length > 0)}
                    name="email"
                    id="email"
                    label="Login/Contact Email"
                    placeholder="Email"
                    value={email}
                    onChange={onValueChange}
                    variant="outlined"
                    fullWidth
                />
            </Grid>

            <Grid item md={6} xs={12}>
                <TextField
                    name="company"
                    id="company"
                    label="Company"
                    placeholder="Company"
                    value={company}
                    onChange={onValueChange}
                    variant="outlined"
                    fullWidth
                />
            </Grid>

            <Grid item md={6} xs={12}>
                <TextField
                    required
                    name="displayname"
                    id="displayname"
                    label="Display name"
                    placeholder="Display name"
                    value={displayname}
                    onChange={onValueChange}
                    variant="outlined"
                    fullWidth
                    error={displaynameError !== undefined}
                    helperText={displaynameError}
                />
            </Grid>

            <Grid item md={6} xs={12}>
                <FormControl required variant="outlined" fullWidth error={industries?.length < 1}>
                    <InputLabel id="industries">Industry</InputLabel>
                    <Select
                        name="industries"
                        id="industries"
                        label="Industries"
                        value={industries}
                        multiple
                        onChange={onIndustryChange}
                        renderValue={(selected: number[]) =>
                            metadata.industries
                                .filter((i) => selected.includes(i.pkUserIndustryID))
                                .map((i) => i.industryName)
                                .join(", ")
                        }
                    >
                        {metadata?.industries.map((i) => (
                            <MenuItem key={`ind_${i.pkUserIndustryID}`} value={i.pkUserIndustryID}>
                                <Checkbox checked={industries.indexOf(i.pkUserIndustryID) > -1} />
                                <ListItemText primary={i.industryName} />
                            </MenuItem>
                        ))}
                    </Select>

                    <FormHelperText>Select up to 5 industry categories</FormHelperText>
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h6">Contact details</Typography>
            </Grid>

            <Grid item md={6} xs={12}>
                <TextField
                    name="firstname"
                    id="firstname"
                    label="First Name"
                    placeholder="First Name"
                    value={firstname}
                    onChange={onValueChange}
                    variant="outlined"
                    fullWidth
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <TextField
                    name="lastname"
                    id="lastname"
                    label="Last Name"
                    placeholder="Last Name"
                    value={lastname}
                    onChange={onValueChange}
                    variant="outlined"
                    fullWidth
                />
            </Grid>

            <Grid item md={6} xs={12}>
                <TextField
                    name="address"
                    id="address"
                    label="Address"
                    placeholder="Address"
                    value={address}
                    onChange={onValueChange}
                    variant="outlined"
                    fullWidth
                />
            </Grid>
            <Grid item md={4} xs={12}>
                <TextField
                    name="suburb"
                    id="suburb"
                    label="Suburb"
                    placeholder="Suburb"
                    value={suburb}
                    onChange={onValueChange}
                    variant="outlined"
                    fullWidth
                />
            </Grid>
            <Grid item md={2} xs={12}>
                <TextField
                    name="postcode"
                    id="postcode"
                    label="Postcode"
                    placeholder="Postcode"
                    value={postcode}
                    onChange={onValueChange}
                    variant="outlined"
                    fullWidth
                />
            </Grid>

            <Grid item md={4} xs={12}>
                <FormControl required variant="outlined" fullWidth>
                    <InputLabel id="country">Country</InputLabel>
                    <Select
                        name="country"
                        id="country"
                        label="Country"
                        value={countryId}
                        onChange={onCountryChange}
                    >
                        {countries.map((c) => (
                            <MenuItem key={`country_${c.id}`} value={c.id}>
                                {c.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item md={4} xs={12}>
                <FormControl required variant="outlined" fullWidth>
                    <InputLabel id="state">State</InputLabel>
                    <Select name="state" id="state" label="State" value={stateId} onChange={onStateChange}>
                        {metadata?.states?.filter(s => s.fkCountryID === countryId).map((s) => (
                            <MenuItem key={`state_${s.pkStateID}`} value={s.pkStateID}>
                                {s.stateName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <TextField
                    name="phone"
                    id="phone"
                    label="Phone"
                    placeholder="Phone"
                    value={phone}
                    onChange={onValueChange}
                    variant="outlined"
                    fullWidth
                />
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h6">License group</Typography>
            </Grid>
            <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth disabled={!isAdmin}>
                    <InputLabel id="state">License group</InputLabel>
                    <Select name="state" id="state" label="Listing group" value={clientId} onChange={onClientChange}>
                        <MenuItem key={`client_0`} value={0}>
                            No license selected
                        </MenuItem>
                        {metadataAdmin?.clients?.map((c) => (
                            <MenuItem key={`client_${c.id}`} value={c.id}>
                                {`${c.name} (${c.id})`}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>
                        Choose first option "No license selected" to remove license group from user
                    </FormHelperText>
                </FormControl>
            </Grid>
            {licenses?.length > 0 && (
                <Grid item xs={12}>
                    {licenses
                        .sort(
                            (a, b) =>
                                a.licenseTypeName.localeCompare(b.licenseTypeName) ||
                                a.txtLicenseName.localeCompare(b.txtLicenseName)
                        )
                        .map((l) => (
                            <Line label={l.licenseTypeName} value={`${l.txtLicenseName} (${l.pkLicense})`} />
                        ))}
                </Grid>
            )}

            {isAdmin && (
                <>
                    <Grid item xs={12}>
                        <Typography variant="h6">Administrator section</Typography>
                    </Grid>

                    <Grid item sm={5} xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="select-type-label">User status</InputLabel>
                            <Select label="User status" name="active" value={active ? 1 : 0} onChange={onStatusChange}>
                                <MenuItem key={`active`} value={1}>
                                    Active
                                </MenuItem>
                                <MenuItem key={`inactive`} value={0}>
                                    Inactive
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item sm={7} xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                type="password"
                                name="password"
                                id="password"
                                label="Password"
                                value={password}
                                onChange={onValueChange}
                                variant="outlined"
                                fullWidth
                            />
                            <FormHelperText>
                                {newUser
                                    ? "If password left blank, then strong password is automatically generated and sent via email to user."
                                    : "Leave blank if you don't want update password"}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="notes"
                            id="notes"
                            label="Notes"
                            multiline
                            maxRows={10}
                            minRows={3}
                            value={notes}
                            onChange={onValueChange}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    {user && (
                        <Grid item xs={12}>
                            <Line label="User type" value={user.userType} />
                            <Line label="Last login" value={DateUtil.formatDateFromString(user.lastLogin)} />
                            <Line label="Date created" value={DateUtil.formatDateFromString(user.created)} />
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
};

export default UserForm;

const Line = (props: { label: string; value: string | number }): JSX.Element => {
    const classes = useStyles();
    return (
        <Box className={classes.row} display="flex">
            <div className={classes.label}>{props.label}:</div>
            {props.value}
        </Box>
    );
};
