import React from 'react';
import { useDispatch } from 'react-redux';
import { updateWizardStepAction } from '../../../../Store/Wizard/actions';
import WizardGuide from '../../WizardGuide';
import WizardHeader from '../../WizardHeader';
import WizardMain from '../../WizardMain';
import WizardSidebar from '../../WizardSidebar';
import { WizardStep } from '../../WizardSteps';
import BackForwardButtons from '../BackForwardButtons';
import ApprovalForm from './ApprovalForm';

const ApprovalStep = () => {
    const dispatch = useDispatch();

    const onWizardStepChange = (step: WizardStep) => {
        dispatch(updateWizardStepAction(step));
    }

    const onSaveHook = (): Promise<any> => {
        return undefined;
    }

    return (
        <>
            <WizardHeader
                buttonText={'RETURN'}
                backToListingsHook={onSaveHook}
            />

            <WizardSidebar
                disableAll={false}
                onSaveHook={onSaveHook}
            />

            <WizardMain>
                <ApprovalForm/>
                <BackForwardButtons
                    onClickPrevious={() => onWizardStepChange(WizardStep.Details)}
                    onClickNext={() => onWizardStepChange(WizardStep.ContactInformation)}
                />
            </WizardMain>

            <WizardGuide />

        </>


    )
};

export default ApprovalStep;