import { isBefore } from "date-fns";
import {
    FileDTO,
    ImageDTO,
    ListingApprovalDTO,
    ListingDTO,
    ListingFullDTO,
    ListingLevelDTO,
    ListingLevelLinkDTO,
    ScoopStatus,
    UserDTO,
    UserProfileDTO,
} from "../Api/Model";
import config from "../config";

const baseUrlImages = `${config.BUCKET_URL}/images`;
const baseUrlFiles = `${config.BUCKET_URL}/files`;

const IS_HTML_REGEX = new RegExp(/<\/?[a-z][\s\S]*>/i);

const IS_URL_VALID_REGEX = new RegExp(
      "^(https?:\\/\\/)?(www\\.)?" + // optional protocol and optional www
      "(([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,})" + // domain name and extension
      "(\\/[-a-zA-Z0-9@:%._\\+~#=]*)*" + // optional path
      "(\\?[;&a-zA-Z0-9%_\\+.~#?&//=]*)?" + // optional query string
      "(\\#[-a-zA-Z0-9_]*)?$", // optional fragment locator
      "i"
    );

const CURRENCY_FORMATTER = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "AUD",

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

export default class ScoopUtil {
    static isEditAllowed(authUser: UserDTO, listing?: ListingFullDTO): boolean {
        return !!!listing || authUser.id === listing.details.fkUser || listing.approval?.allowListingEdit;
    }

    static isDefaultLevelAllowed(level: ListingLevelDTO, approval: ListingApprovalDTO): boolean {
        return level.intPriority >= approval.defaultLevelEditStart && level.intPriority <= approval.defaultLevelEditEnd;
    }

    static allowApprove(listing: ListingFullDTO): boolean {
        return listing?.approval?.allowListingApprove;
    }

    static allowListingLevels(listing: ListingFullDTO): boolean {
        return listing?.approval?.allowPremiumLevelEdit || listing?.approval?.allowDefaultLevelEdit;
    }

    static allowListingPremiumLevels(authUser: UserProfileDTO, listing?: ListingFullDTO): boolean {
        return listing !== undefined && authUser.id === listing.details.fkUser && authUser.userType === "Administrator";
    }

    static urlImageLarge(image: ImageDTO): string {
        return baseUrlImages + image.txtLargeURL;
    }

    static urlFile(file: FileDTO): string {
        return baseUrlFiles + file.txtFileURL;
    }

    public static isEmailValid(email: string): boolean {
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(email);
    }

    public static isDisplaynameValid(name?: string): boolean {
        return name && !/\s/.test(name) && name.length >= 10;
    }

    public static isPhoneNumberValid(phoneNumber: string): boolean {
        if(phoneNumber?.length > 16) return false;
        const cleaned = phoneNumber.replace(/\s+/g, "");
        return /^\+(?:[1-9]\d{0,2})?[2-9]\d{4,14}(?:\s+\d{2,4})*$|^\d{8,10}$/.test(cleaned);
    }

    public static isUrlValidOrEmpty(url: string, domains?: string[]) {
        if (url && url.length > 0) {
            if (domains) {
                return this.isUrlValid(url) && domains.find(d => url.includes(d));
            } else {
                return this.isUrlValid(url);
            }
        }
        return true;
    }

    public static isUrlValid(url: string): boolean {
        return IS_URL_VALID_REGEX.test(url);
    }

    public static completeUrlWithProtocol(url: string): string {
        if (!url || url?.length < 1) return url;
        if (url.startsWith("http://") || url.startsWith("https://")) return url;
        return "https://" + url;
    }

    public static fileExists(url: string): Promise<boolean> {
        return fetch(url, { method: "HEAD" })
            .then((res) => res.ok)
            .catch((err) => false);
    }

    public static generatePreviewLink(previewUrl: string): string {
        return `https://localista.com.au${previewUrl}`;
    }

    public static scoopStatus(isApproved: boolean, isVisible: boolean, dtApproved?: string): ScoopStatus {
        if (isApproved) {
            return ScoopStatus.APPROVED;
        } else if (!!!dtApproved) {
            return ScoopStatus.PENDING;
        } else if (isVisible && dtApproved) {
            return ScoopStatus.PENDING;
        } else {
            return ScoopStatus.UNAPPROVED;
        }
    }

    public static scoopStatusFromListingDetails(listing: ListingFullDTO): ScoopStatus {
        const { details } = listing;
        return ScoopUtil.scoopStatus(details.bitApproved, details.isVisible, details.dtApproved);
    }

    public static scoopStatusFromListing(listing: ListingDTO): ScoopStatus {
        return ScoopUtil.scoopStatus(listing.isApproved, listing.isVisible, listing.dtApproved);
    }

    public static scoopStatusFromApproval(listing: ListingApprovalDTO): ScoopStatus {
        return ScoopUtil.scoopStatus(listing.isApproved, listing.isVisible, listing.dtApproved);
    }

    public static isExpired(now: Date, eventTo?: Date): boolean {
        if (eventTo) {
            return !isBefore(now, eventTo);
        }
        return false;
    }

    public static isAdmin(user: UserProfileDTO): boolean {
        return user && user.userLevel === 2;
    }

    public static tryGetParam(key: string): string | undefined {
        let params = new URLSearchParams(window.location.search);
        if (params.has(key)) {
            return params.get(key);
        }
        return undefined;
    }

    public static tryGetParamNumber(key: string): number | undefined {
        let value = this.tryGetParam(key);
        let numberValue = Number(value);
        if (isNaN(numberValue)) return undefined;
        return numberValue;
    }

    public static removeParameterFromUri(key) {
        if (window.history.replaceState) {
            const params = new URLSearchParams(window.location.search);
            params.delete(key);
            const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${params}`;
            window.history.replaceState({ path: newUrl }, "", newUrl);
        }
    }

    public static getOrdinal(n: number): string {
        var s = ["th", "st", "nd", "rd"];
        var v = n % 100;
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
    }

    public static wordCount(text?: string): number {
        return text?.split(/[ ]+/).length || 0;
    }

    public static isHtmlText(text: string): boolean {
        return IS_HTML_REGEX.test(text);
    }

    public static formatPriceInCents(amount?: number): string {
        return CURRENCY_FORMATTER.format((amount || 0) / 100);
    }

    public static resetHours(date: Date): Date {
        date.setHours(0, 0, 0, 0);
        return date;
    }

    public static compareListingLevelLinks(l1: ListingLevelLinkDTO, l2: ListingLevelLinkDTO): number {
        var order = l1.startDate.localeCompare(l2.startDate);
        if (order !== 0) return order;
        return this.listingLevelLinkStatusOrderValues(l1) - this.listingLevelLinkStatusOrderValues(l2);
    }

    private static listingLevelLinkStatusOrderValues(l: ListingLevelLinkDTO): number {
        if (l.status === "active") return 10;
        else if (l.status === "future") return 20;
        else return 0;
    }
}
