import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApiUsers from "../../../Api/ApiUsers";
import { UserProfileDTO } from "../../../Api/Model";
import { actionUserDeSelected, actionUserUpdated } from "../../../Store/Users/actions";
import { selectSelectedUser } from "../../../Store/Users/selectors";
import { UserFormValues } from "../../UI/UserForm";
import AddUpdateUserDialog from "./AddUpdateUserDialog";

const UpdateUserDialog = () => {
    const dispatch = useDispatch();
    const selectedUser = useSelector(selectSelectedUser);

    const [profile, setProfile] = useState<UserProfileDTO>(undefined);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const res = await ApiUsers.getUserProfile(selectedUser.id);
                setProfile(res);
            } catch (err) {
                //todo
            }
        };
        if (selectedUser) {
            fetchProfile();
        } else {
            setProfile(undefined);
        }
    }, [selectedUser]);

    const handleClose = () => {
        dispatch(actionUserDeSelected());
    };

    const handleSave = async (values: UserFormValues) => {
        try {
            const updated = await ApiUsers.updateUserByAdmin(profile.id, values);
            dispatch(actionUserUpdated(updated))
            dispatch(actionUserDeSelected());
        } catch(err) {
            //todo
        }
    };

    return (
        <AddUpdateUserDialog
            open={selectedUser !== undefined}
            onClose={handleClose}
            profile={profile}
            onSave={handleSave}
        />
    );
};

export default UpdateUserDialog;
