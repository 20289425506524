import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SaveIcon from '@mui/icons-material/Save';
import { Alert } from '@mui/material';
import "cropperjs/dist/cropper.css";
import React, { useEffect, useRef, useState } from 'react';
import Cropper from 'react-cropper';
import FileUtil from '../../../../Util/FileUtil';


interface CropImageProps {
    file: File;
    minHeight: number;
    minWidth: number;
    maxSize?: number;
    onFileCropped: (file: File) => void;
    onCancel: () => void;
}


const useStyles = makeStyles((theme) => ({
    loading: {
        backgroundColor: '#000',
        opacity: '0.5',
        background: theme.palette.grey[300],
        minHeight: '500px',
        width: '100%',
        height: '100%',
        padding: theme.spacing(12),
        textAlign: 'center',
    },
    loadingSpinner: {
        paddingTop: '25%',
        paddingBottom: '25%',
    },
    cropperContainer: {


    },
    cropper: {

    },
}));

const CropImage = (props: CropImageProps) => {
    const { file, minHeight, minWidth, maxSize } = props;
    const classes = useStyles();
    const cropperRef = useRef<any | null>(null);
    const [message, setMessage] = useState<JSX.Element>(undefined);
    const [isLoadingBase64, setIsLoadingBase64] = useState(true);
    const [imageBase64, setImageBase64] = useState<string | null>(null);

    useEffect(() => {
        if (props.file) {
            FileUtil.fileToBase64Image(props.file).then(base64 => {
                setImageBase64(base64);
                setIsLoadingBase64(false);
            })
        }
    }, [props.file])

    const handleFinishCrop = () => {
        if (!cropperRef.current) return;

        const cropped = cropperRef.current.cropper.getCroppedCanvas();
        if (maxSize && cropped.size > maxSize) {
            alert(`Cropped image size is too big (${cropped.size}). Must be at max ${maxSize}`)
        } else if (cropped.width < minWidth || cropped.height < minHeight) {
            alert(`Cropped image selection is too small (${cropped.width}x${cropped.height}px). Must be at least ${minWidth}x${minHeight}px`)
        } else {
            const fileData = cropped.toDataURL(file.type);

            FileUtil.base64ImageToFile(fileData, file.name, file.type)
                .then((file) => {
                    props.onFileCropped(file);
                });
        }
    }

    const onCropEnd = () => {
        if (!cropperRef.current) return;

        const cropped = cropperRef.current.cropper.getCroppedCanvas();
        if (cropped.width < minWidth || cropped.height < minHeight) {
            setMessage(<Alert severity="warning">{`Cropped image selection [${cropped.width}x${cropped.height}px] is too small.`}</Alert>)

        } else {
            setMessage(<Alert severity='success'>{`Cropped image selection [${cropped.width}x${cropped.height}px] is OK.`}</Alert>)
        }
    }

    if (isLoadingBase64 || !imageBase64) {
        return (
            <Grid item container xs={12} spacing={2}>
                <div className={classes.loading}>
                    <Grid item>
                        <CircularProgress />
                    </Grid>

                    <Grid item>
                        Loading Image
                    </Grid>
                </div>
            </Grid>
        )
    }

    return (
        <React.Fragment>
            <Grid container item xs={12} spacing={1}>
                <Grid item xs={12}>
                    <div className={classes.cropperContainer}>
                        <Cropper
                            ref={cropperRef}
                            className={classes.cropper}
                            src={imageBase64}
                            aspectRatio={minWidth / minHeight}
                            viewMode={1}
                            guides={true}
                            minCropBoxHeight={20}
                            minCropBoxWidth={20}
                            background={false}
                            rotatable={true}
                            responsive={true}
                            autoCropArea={0}
                            checkOrientation={true}
                            zoomable={false}
                            cropend={onCropEnd}
                            ready={onCropEnd}
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                        {message}
                    </Grid>


                <Grid container item xs={12} justifyContent="center" spacing={3}>
                    <Grid item >
                        <Button
                            onClick={props.onCancel}
                            variant="contained"
                            startIcon={<CancelOutlinedIcon />}
                            color="primary">Cancel
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button
                            onClick={() => handleFinishCrop()}
                            variant="contained"
                            startIcon={<SaveIcon />}
                            color="primary">Finish
                        </Button>
                    </Grid>

                </Grid>
            </Grid>

        </React.Fragment>
    );
};

export default CropImage;