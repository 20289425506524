import { Checkbox, FormControlLabel } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { isOccurrenceDayChecked } from './DatesStep';


interface Props {
    occurrenceDays: number;
    dayOfWeek: number;
    dayLabel: string;
    onChange: (value: number, selected: boolean) => void;
}

const OccurrenceDay = (props: Props) => {
    const { occurrenceDays, dayOfWeek, dayLabel, onChange } = props;
    const [selected, setSelected] = useState(false);

    useEffect(() => {
        setSelected(isOccurrenceDayChecked(dayOfWeek, occurrenceDays))
    }, [occurrenceDays, dayOfWeek])

    const onClick = () => {
        onChange(dayOfWeek, !!!selected);
    }

    return (
            <Grid item >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selected}
                            color="primary"
                            onClick={onClick}
                            name={dayLabel}
                        />
                    }
                    label={dayLabel}
                />
            </Grid>
    )
};

export default OccurrenceDay;