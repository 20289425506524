import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ApiListing from "../../../../Api/ApiListing";
import { ListingFullDTO } from "../../../../Api/Model";
import { selectUser } from "../../../../Store/User/selectors";
import { listingModifiedAction, updateWizardStepAction } from "../../../../Store/Wizard/actions";
import { selectAllowEdit, selectWizardConfig, selectListing } from "../../../../Store/Wizard/selectors";
import ScoopUtil from "../../../../Util/ScoopUtil";
import { deepEqual } from "../../../../Util/Util";
import Loading from "../../../UI/Loading";
import WizardGuide from "../../WizardGuide";
import WizardHeader from "../../WizardHeader";
import WizardMain from "../../WizardMain";
import WizardSidebar from "../../WizardSidebar";
import { WizardStep } from "../../WizardSteps";
import BackForwardButtons from "../BackForwardButtons";
import { FormDescriptionValues } from "../form-values";
import DescriptionForm from "./DescriptionForm";

export const WORD_LIMIT_WARNING_DESCRIPTION = 190;
export const WORD_LIMIT_ERROR_DESCRIPTION = 5000;
export const CHARACTERS_LIMIT_ERROR_TEASER = 200;
export const CHARACTERS_LIMIT_ERROR_ATTRIBUTION = 250;



const mapValues = (listing: ListingFullDTO): FormDescriptionValues => {
    let desc = listing.details.txtPageContentPub || "";
    if (ScoopUtil.isHtmlText(desc)) {
        desc = desc
            .replace(/\n/g, "") //remove end of lines
            .replace(/<h[0-9]>/gm, "# ") //replace end of <h.> with 2 new lines
            .replace(/<\/h[0-9]>/gm, "\n\n") //replace end of </p> with 2 new lines
            .replace(/<li>/gm, "- ") //replace end of </p> with 2 new lines
            .replace(/<\/li>/gm, "\n") //replace end of </p> with 2 new lines
            .replace(/<br\s*\/?>/gm, "\n") //replace <br> with new line
            .replace(/<\/p>/gm, "\n\n") //replace end of </p> with 2 new lines
            .replace(/\t/g, "") //remove tab with nothing
            .replace(/&nbsp;/g, " ") //replace non-breakable empty space with normal space
            .replace(/&quot;/g, '"') //replace double quotes characters
            .replace(/&lsquo;/g, "'") //replace double quotes characters
            .replace(/&rsquo;/g, "'") //replace double quotes characters
            .replace(/&ldquo;/g, '"') //replace single quotes characters
            .replace(/&rdquo;/g, '"') //replace single quotes characters
            .replace(/&#39;/g, "`") //replace apostrophe characters
            .replace(/[\t ]+</g, "<")
            .replace(/>[\t ]+</g, "><")
            .replace(/>[\t ]+$/g, ">")
            .replace(/(<([^>]+)>)/gi, ""); //remove rest of html tags
    }
    return {
        teaser: listing.details.txtTeaser || "",
        description: desc,
        attribution: listing.details.txtAttribution || "",
    };
};

const DescriptionStep = () => {
    const dispatch = useDispatch();
    let params = useParams<{ id: any; licenseId: any }>();
    const { allowEvents } = useSelector(selectWizardConfig);
    const allowEdit = useSelector(selectAllowEdit);
    const listing = useSelector(selectListing);
    const user = useSelector(selectUser);
    const [values, setValues] = useState<FormDescriptionValues>(undefined);
    const [origValues, setOrigValues] = useState<FormDescriptionValues>(undefined);
    const [formValid, setFormValid] = useState(false);

    useEffect(() => {
        if (listing) {
            const mapped = mapValues(listing);
            setOrigValues({ ...mapped });
            setValues(mapped);
        }
    }, [listing]);

    const generatePromise = (): Promise<ListingFullDTO> => {
        if (formValid) {
            const { teaser, description, attribution } = values;

            let promise: Promise<ListingFullDTO> = undefined;
            let changed = false;
            if (teaser !== listing.details.txtTeaser) changed = true;
            if (description !== listing.details.txtPageContentPub) changed = true;
            if (attribution !== listing.details.txtAttribution) changed = true;
            if (changed) {
                promise = ApiListing.updateListingDescription(
                    listing.details.pkListing,
                    teaser,
                    description,
                    attribution,
                    params.licenseId
                );
            }
            return promise;
        }
        return Promise.reject("Form is not valid");
    };

    const onWizardStepChange = (step: WizardStep) => {
        if (formValid) {
            let promise = generatePromise();
            dispatch(updateWizardStepAction(step, promise));
        }
    };

    const onValuesChange = (values: FormDescriptionValues) => {
        setValues(values);
    };

    const onSaveHook = (): Promise<any> => {
        return generatePromise();
    };

    useEffect(() => {
        dispatch(listingModifiedAction(!deepEqual(origValues, values)));
    }, [values, origValues, dispatch]);

    return (
        <>
            <WizardHeader disabled={!!!formValid} backToListingsHook={onSaveHook} />
            <WizardSidebar disableAll={!!!formValid} onSaveHook={onSaveHook} />
            <WizardMain>
                {values ? (
                    <DescriptionForm
                        disabled={!allowEdit}
                        values={values}
                        onChange={onValuesChange}
                        onFormValidChange={setFormValid}
                        isAdmin={user?.userLevel === 2}
                    />
                ) : (
                    <Loading />
                )}

                <BackForwardButtons
                    disabled={!!!formValid}
                    onClickPrevious={() => onWizardStepChange(WizardStep.Location)}
                    onClickNext={() => onWizardStepChange(allowEvents ? WizardStep.Dates : WizardStep.LinkedListings)}
                />
            </WizardMain>
            <WizardGuide step={WizardStep.Description} />
        </>
    );
};

export default DescriptionStep;
