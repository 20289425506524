import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ClickAwayListener, Link } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ApiListing from '../../../Api/ApiListing';
import { ServerError } from "../../../Api/Api";
import { ListingApprovalDTO } from '../../../Api/Model';
import { listingValidationErrorAction } from '../../../Store/App/actions';
import { approvalsListingUpdatedAction, approvalsTableSettingUpdatedAction } from '../../../Store/Dashboard/actions';
import { selectApprovalsSelectedLicense, selectApprovalsTableSetting } from '../../../Store/Dashboard/selectors';
import DateUtil from '../../../Util/DateUtil';
import ScoopUtil from '../../../Util/ScoopUtil';
import EnhancedTable, { HeadCell, TableSetting } from '../../UI/EnhancedTable';
import LoadingDialog from '../../UI/LoadingDialog';
import ScoopStatusIcon from '../ScoopStatusIcon';
import ApprovalActionButton from './ApprovalActionButton';
import { ListingApproval } from './Approvals';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cell: {
            padding: '0px 10px'
        },
        cellListingName: {
            fontWeight: 'bold'
        },
        cellActive: {
            cursor: 'pointer'
        },
    }),
);

interface Props {
    list: ListingApproval[];
}

const DataTable = (props: Props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const { list } = props;
    const licenseId = useSelector(selectApprovalsSelectedLicense);
    const [updating, setUpdating] = useState(false);
    const [opened, setOpened] = useState<number>(undefined);
    const tableSetting = useSelector(selectApprovalsTableSetting);

    const tableSettingUpdated = (setting: TableSetting) => dispatch(approvalsTableSettingUpdatedAction(setting));

    const onEdit = async (listing: ListingApprovalDTO) => {
        history.push(`/wizard/${listing.listingID}/${licenseId}`)
    }

    const handleActiveClick = async (listing: ListingApprovalDTO) => {
        setUpdating(true)
        try {
            let res = await ApiListing.updateListingVisibility(listing.listingID, !!!listing.isVisible, licenseId);
            let copy = { ...listing };
            copy.isVisible = res.details.isVisible;
            dispatch(approvalsListingUpdatedAction(copy));
        } catch (err) {
            dispatch(listingValidationErrorAction((err as ServerError)?.errors));
        } finally {
            setUpdating(false)
        }
    };

    let headCells: HeadCell[] = [
        { id: 'listingName', sortable: true, disablePadding: false, label: 'Listing Name', minWidth: 160 },
        { id: 'listingTypeName', sortable: true, disablePadding: false, label: 'Type' },
        { id: 'listingUserDisplayName', sortable: true, disablePadding: false, label: 'Listing Owner' },
        { id: 'dtLastUpdated', sortable: true, disablePadding: false, label: 'Last Updated' },
        { id: 'defaultListingLevel', sortable: true, disablePadding: false, label: 'Default Level' },
        { id: 'listingLevel', sortable: true, disablePadding: false, label: 'Active Level' },
        { id: 'isVisible', disablePadding: false, label: 'User Activated' },
        { id: 'approved', disablePadding: false, label: 'localista Approved' },
        { id: 'view', disablePadding: false, label: 'View' },
        { id: 'actions', disablePadding: false, label: 'Actions'}
    ];

    const mapRow = (listing: ListingApproval, index: number): JSX.Element => {
        return (
            <TableRow hover tabIndex={-1} key={listing.listingID}>
                <TableCell onClick={() => onEdit(listing)} className={`${classes.cell} ${classes.cellListingName}`}>
                    <Link style={{ color: 'blue' }}>{listing.listingName}</Link>
                </TableCell>
                <TableCell className={`${classes.cell}`} >{listing.listingTypeName}</TableCell>
                <TableCell className={`${classes.cell}`} >{listing.listingUserDisplayName}</TableCell>
                <TableCell padding='none' align='center' className={`${classes.cell}`}>{DateUtil.formatDateFromString(listing.dtLastUpdated)}</TableCell>
                <TableCell align="center" className={`${classes.cell}`}>{listing.displayPriorityDefault || 0}</TableCell>
                <TableCell align="center" className={`${classes.cell}`}>{listing.displayPriorityActive}</TableCell>
                <TableCell className={`${classes.cell} ${classes.cellActive}`} align="center" onClick={() => handleActiveClick(listing)}>{listing.isVisible ? <CheckIcon htmlColor="green" /> : <ClearIcon htmlColor="red" />}</TableCell>
                <TableCell align="center" className={`${classes.cell}`}><ScoopStatusIcon status={listing.scoopStatus} /></TableCell>
                <TableCell align="center" className={`${classes.cell}`}>
                    <IconButton
                        href={ScoopUtil.generatePreviewLink(listing.listingPreviewURL)}
                        target="_blank"
                        color="primary"
                        size="large">
                        <VisibilityIcon />
                    </IconButton>
                </TableCell>
                <TableCell className={`${classes.cell}`}>
                    <ApprovalActionButton opened={opened} onOpen={setOpened} listing={listing} />
                </TableCell>
            </TableRow>
        );
    }

    const handleClickAway = () => {
        if (opened) setOpened(undefined)
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <EnhancedTable
                    headCells={headCells}
                    rows={list || []}
                    mapRow={mapRow}
                    tableSetting={tableSetting}
                    onTableSettingChange={tableSettingUpdated}
                />
                {updating && <LoadingDialog text="Updating listing" />}

            </div>
        </ClickAwayListener>
    );
}

export default DataTable;