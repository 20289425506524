import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { ReactNode } from 'react';

interface Props {
    title?: string;
    note?: string;
    noteColor?: string;
    sm?: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    md?: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    lg?: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    children?: ReactNode;
    buttons?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
    gridSection: {
        marginBottom: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(2),
        }
    },
    titleSection: {
        display: "flex",
        '& h6': {
            flexGrow: 1
        }
    },
    note: {
        fontSize: "small"
    }

}));

const SectionGrid = (props: Props) => {
    const theme = useTheme();
    const widthXS = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    const { title, note, noteColor, children, buttons, sm, md, lg } = props;
    return (
        <Grid container item xs={12} sm={sm} md={md} lg={lg} spacing={widthXS ? 1 : 2} className={classes.gridSection}>
            {(title || buttons) && <Grid item xs={12}>
                <div className={classes.titleSection}>
                    <Typography variant="h6" >
                        {title}
                    </Typography>
                    {buttons && <div>
                        {buttons}
                    </div>}
                </div>
                {note && <Typography className={classes.note} color={noteColor} >{note}</Typography>}

            </Grid>}
            {children}
        </Grid >
    )
}

export default SectionGrid;