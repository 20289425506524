import { TextField, useMediaQuery, useTheme } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from "@mui/material/useAutocomplete";
import React, { useEffect, useState } from "react";
import { Region } from "../../Api/Model";

interface Props {
    disabled?: boolean;
    selected?: Region;
    regions: Region[];
    onSelected: (region?: Region) => void;
    onlySuburbs?: boolean;
    required?: boolean;
}

const RegionAutocomplete = (props: Props) => {
    const { disabled, selected, regions, onlySuburbs, required, onSelected } = props;
    const theme = useTheme();
    const widthXS = useMediaQuery(theme.breakpoints.down("sm"));
    const [options, setOptions] = useState<Region[]>([]);

    useEffect(() => {
        let tempOptions = regions
            .filter(r => !onlySuburbs || r.isSuburb)
            .map((r) => {
                if (r.parentsNames) {
                    r.parentsLabel = widthXS ? r.parentsNames[r.parentsNames.length - 1] : r.parentsNames.join(" / ");
                }
                return r;
            })
            .sort((a, b) => {
                return a.txtRegionName.localeCompare(b.txtRegionName);
            });
            //todo deal with duplicates here somehow
            // tempOptions = tempOptions.filter((item, index) => tempOptions.indexOf(item) === index);
        setOptions(tempOptions);
    }, [onlySuburbs, regions, widthXS]);

    const filterOptions = createFilterOptions({
        matchFrom: "start",
        stringify: (option: Region) => option.txtRegionName,
    });

    return (
        <>
            <Autocomplete
                disabled={disabled}
                disableClearable
                options={options}
                filterOptions={filterOptions}
                fullWidth
                value={selected}
                onChange={(event, value: Region) => {
                    onSelected(value);
                }}
                getOptionLabel={(option) =>
                    option.parentsLabel ? `${option.txtRegionName} - ${option.parentsLabel}` : option.txtRegionName
                }
                isOptionEqualToValue={(option, value) => option.pkRegion === value.pkRegion}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={onlySuburbs ? "Select town/suburb" : "Select town, suburb or region"}
                        variant="outlined"
                        required={required}
                        error={!!!selected}
                    />
                )}
            />
        </>
    );
};

export default RegionAutocomplete;
