import { Button, Link } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ApiPremium from "../../../Api/ApiPremium";
import { snackMessageCaughtErrorAction } from "../../../Store/App/actions";
import Loading from "../../UI/Loading";

interface InvoiceLinkProps {
    chargebeeCreditNoteId: string;
}

const CreditNoteLink = (props: InvoiceLinkProps) => {
    const dispatch = useDispatch();
    const { chargebeeCreditNoteId } = props;

    const [link, setLink] = useState<string>(undefined);
    const [processing, setProcessing] = useState(false);

    const onClick = async () => {
        setProcessing(true);
        try {
            let res = await ApiPremium.getCreditNotePdfLink(chargebeeCreditNoteId);
            setLink(res);
        } catch (err) {
            dispatch(snackMessageCaughtErrorAction(err));
        } finally {
            setProcessing(false);
        }
    };

    return (
        <>
            {processing && <Loading />}
            {!!!processing && !!!link && <Button onClick={onClick}>generate link</Button>}
            {!!!processing && link && (
                <Link href={link} target="_blank" rel="noopener">
                    {" "}
                    download{" "}
                </Link>
            )}
        </>
    );
};

export default CreditNoteLink;
