import { Alert } from "@mui/material";
import Grid from "@mui/material/Grid";
import { addDays, addMonths, addWeeks, addYears, getWeekOfMonth, subWeeks } from "date-fns";
import { endOfMonth, startOfMonth } from "date-fns/esm";
import React from "react";
import DateUtil from "../../../../Util/DateUtil";
import { FormDatesValues } from "../form-values";
import SectionGrid from "../SectionGrid";
import { isOccurrenceDateChecked } from "./DatesStep";
import RecurringDatesMonthly from "./RecurringDatesMonthly";
import RecurringDatesStart from "./RecurringDatesStart";
import RecurringDatesStop from "./RecurringDatesStop";
import RecurringDatesWeekly from "./RecurringDatesWeekly";

const OCCURRENCE_MONTHS = 4095;

interface Props {
    disabled: boolean;
    values: FormDatesValues;
    onChange: (values: FormDatesValues) => void;
}

const calculateEndDate = (
    dateFrom: Date,
    maxOccurences: number | undefined,
    dtEventTo: string | undefined,
    add: (date: Date, amount: number) => Date,
    repeateInterval?: number
): Date | undefined => {
    if (maxOccurences > 0) {
        return add(dateFrom, maxOccurences * (repeateInterval || 1));
    } else if (dtEventTo) {
        return new Date(dtEventTo);
    }
    return undefined;
};

const RecurringDatesForm = (props: Props) => {
    const { disabled, values, onChange } = props;
    const {
        repeatFrequency,
        repeatInterval,
        occurenceDays,
        dtEventFrom,
        dtEventTo,
        maxOccurences,
        monthlyExactDay,
        monthlyInterval,
        dates,
    } = values;

    const onFormChange = (form: FormDatesValues) => {
        let dates: string[] = [];
        if (form.dtEventFrom) {
            let dateFrom = new Date(form.dtEventFrom);
            const dateLimit = addYears(dateFrom, 2).getTime();
            let date = new Date(form.dtEventFrom);
            //weekly repeats
            if (form.repeatFrequency === 2) {
                // add number of weeks to the start date or set end date from the form
                const endDate = calculateEndDate(dateFrom, form.maxOccurences, form.dtEventTo, addWeeks, form.repeatInterval);
                // start only if endDate is defined
                if (endDate) {
                    while (date.getTime() < endDate.getTime() && date.getTime() <= dateLimit) {
                        if (isOccurrenceDateChecked(date, form.occurenceDays)) {
                            dates.push(DateUtil.formatDateString(date));
                        }
                        date = addDays(date, 1);
                        if (form.repeatInterval > 1 && dateFrom.getDay() === date.getDay()) {
                            date = addWeeks(date, form.repeatInterval - 1);
                        }
                    }
                }
            }
            //monthly repeats
            if (form.repeatFrequency === 3) {
                // add number of weeks to the start date or set end date from the form
                const endDate = calculateEndDate(dateFrom, form.maxOccurences, form.dtEventTo, addMonths);
                if (endDate) {
                    while (date.getTime() <= endDate.getTime() && date.getTime() <= dateLimit) {
                        // generate dates when exact date is selected
                        if (form.monthlyExactDay > 0 && form.monthlyExactDay === date.getDate()) {
                            dates.push(DateUtil.formatDateString(date));
                        } else if (form.occurenceDays > 0 && form.monthlyInterval > 0 && isOccurrenceDateChecked(date, form.occurenceDays)) {
                            // deal with last occurance differently
                            if(form.monthlyInterval === 16) {
                                const lastDay = endOfMonth(date)
                                if(date.getTime() >= subWeeks(lastDay, 1).getTime()) {
                                    dates.push(DateUtil.formatDateString(date));
                                }
                            } else {
                                const firstDay = startOfMonth(date);
                                let weekOfMonth = getWeekOfMonth(date, { weekStartsOn: firstDay.getDay() as any });
                                if(Math.pow(2, weekOfMonth - 1) === form.monthlyInterval) {
                                    dates.push(DateUtil.formatDateString(date));
                                }
                            }
                        }
                        date = addDays(date, 1);
                    }
                }
            }
        }
        form.dates = dates;
        if (!!!form.dtEventTo && dates.length > 0) {
            form.dtEventTo = dates[dates.length - 1];
        }
        onChange(form);
    };

    const onStartChange = (repeatFrequency: number, dateFrom: string) => {
        let form: FormDatesValues;
        if (repeatFrequency !== values.repeatFrequency) {
            form = {
                pickerType: values.pickerType,
                fkEventDateType: values.fkEventDateType,
                repeatFrequency: repeatFrequency,
                dates: [],
            };
            if (repeatFrequency === 2) {
                form.occurenceDays = 0;
                form.repeatInterval = 1;
                form.occurenceMonths = 0;
            } else if (repeatFrequency === 3) {
                form.occurenceDays = 0;
                form.monthlyExactDay = 0;
                form.monthlyInterval = 0;
                form.occurenceMonths = OCCURRENCE_MONTHS;
            }
        } else {
            form = { ...values };
        }
        form.dtEventFrom = dateFrom;
        onFormChange(form);
    };

    const onStopChange = (maxOccurences: number, dateTo?: string) => {
        let form: FormDatesValues = { ...values, maxOccurences: maxOccurences, dtEventTo: dateTo };
        onFormChange(form);
    };

    const onWeeklyRecurringDatesChange = (days: number, interval: number) => {
        let form: FormDatesValues = {
            ...values,
            occurenceDays: days,
            repeatInterval: interval,
            dates: [],
            monthlyExactDay: undefined,
            monthlyInterval: undefined,
            occurenceMonths: undefined,
        };
        onFormChange(form);
    };

    const onMonthlyRecurringDatesChange = (monthlyExactDay: number, monthlyInterval: number, occurenceDays: number) => {
        let form: FormDatesValues = {
            ...values,
            occurenceDays: occurenceDays,
            repeatInterval: undefined,
            dates: [],
            monthlyExactDay: monthlyExactDay,
            monthlyInterval: monthlyInterval,
            occurenceMonths: OCCURRENCE_MONTHS,
        };
        onFormChange(form);
    };

    return (
        <>
            <SectionGrid title="Step 2: Select dates">
                <RecurringDatesStart
                    disabled={disabled}
                    dtEventFrom={dtEventFrom}
                    repeatFrequency={repeatFrequency}
                    onChange={onStartChange}
                />

                {values.repeatFrequency === 2 && (
                    <RecurringDatesWeekly
                        disabled={disabled}
                        interval={repeatInterval}
                        days={occurenceDays}
                        onChange={onWeeklyRecurringDatesChange}
                    />
                )}
                {values.repeatFrequency === 3 && (
                    <RecurringDatesMonthly
                        disabled={disabled}
                        monthlyExactDay={monthlyExactDay}
                        monthlyInterval={monthlyInterval}
                        occurenceDays={occurenceDays}
                        onChange={onMonthlyRecurringDatesChange}
                    />
                )}
                <RecurringDatesStop
                    disabled={disabled}
                    dtEventTo={dtEventTo}
                    maxOccurrences={maxOccurences}
                    onChange={onStopChange}
                />
            </SectionGrid>
            <SectionGrid title={`Selected dates (${dates.length}) `}>
                {dates.length === 0 && (
                    <Grid item xs={12}>
                        <Alert severity="info">Current setting does not generate any date.</Alert>
                    </Grid>
                )}
                {dates.map((d) => (
                    <Grid key={d} item xs={6} md={3}>
                        {d}
                    </Grid>
                ))}
            </SectionGrid>
        </>
    );
};

export default RecurringDatesForm;
