import makeStyles from "@mui/styles/makeStyles";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { PerformanceDTO } from "../../../Api/Model";

const useStyles = makeStyles((theme) => ({
    chart: {
        "& li.recharts-legend-item": {
            border: "1px black solid",
            padding: "10px",
            borderRadius: "10px",
        },
        "& .custom-legend-wrapper": {
            display: "flex",
        },
        "& .custom-legend-item": {
            border: "1px black solid",
            padding: "10px",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
        },
        "& .custom-legend-item:not(:first-child)": {
            marginLeft: "10px",
        },
        "& .custom-legend-item>div": {
            marginRight: "10px",
            // width: "250px"
        },
        "& span.recharts-legend-item-text": {
            // textAlign: "left",
            color: "black!important",
        },
        "& div.recharts-legend-wrapper": {
            paddingBottom: "10px",
        },
    },
}));

export interface Props {
    performance: PerformanceDTO[];
}

const PerformanceChart = (props: Props) => {
    const { performance } = props;
    const classes = useStyles();

    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        let mapped = performance
            .sort((p1, p2) => p1.year - p2.year || p1.month - p2.month)
            .map((p) => ({
                name: format(new Date(p.year, p.month - 1, 1), "MMM yy"),
                listing: p.views.listing,
                avgFree: p.views.avgFree,
                avgPremium: p.views.avgPremium,
            }));
        setData(mapped);
    }, [performance]);

    return (
        <ResponsiveContainer className={classes.chart} width="100%" height="100%">
            <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend verticalAlign="top" align="left" content={renderLegend} />
                <Line
                    dataKey="listing"
                    name="Total views per month for YOUR listing"
                    stroke="#4CAF51"
                    strokeWidth={3}
                    activeDot={{ r: 8 }}
                />
                <Line
                    dataKey="avgFree"
                    name="Average views per listing per month for FREE listings"
                    stroke="#9AD5E1"
                    strokeWidth={1}
                />
                <Line
                    dataKey="avgPremium"
                    name="Average views per listing per month for PREMIUM listings"
                    stroke="#16A2B8"
                    strokeWidth={2}
                />
            </LineChart>
        </ResponsiveContainer>
    );
};

const renderLegend = (props) => {
    const { payload } = props;

    return (
        <div className="custom-legend-wrapper">
            {payload.map((entry, index) => (
                <div className="custom-legend-item" key={`item-${index}`}>
                    <div>{entry.value}</div>
                    <svg
                        className="recharts-surface"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                    >
                        <title></title>
                        <desc></desc>
                        <path
                            stroke-width="4"
                            fill="none"
                            stroke={entry.color}
                            d="M0,16h10.666666666666666
            A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
            H32M21.333333333333332,16
            A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16"
                            className="recharts-legend-icon"
                        ></path>
                    </svg>
                </div>
            ))}
        </div>
    );
};

export default PerformanceChart;
