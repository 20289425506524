import { FormControl, FormLabel, InputAdornment } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React from "react";
import OccurrenceDay from "./OccurrenceDay";

interface Props {
    disabled: boolean;
    interval: number;
    days: number;
    onChange: (days: number, interval: number) => void;
}

const RecurringDatesWeekly = (props: Props) => {
    const { disabled, interval, days, onChange } = props;

    const onDayOccurrenceChange = (dayOfWeek: number, selected: boolean) => {
        let dayMask = Math.pow(2, dayOfWeek);
        let newDays = selected ? days + dayMask : days - dayMask;
        onChange(newDays, interval);
    };

    const onRepeatsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value as any as number;
        onChange(days, value);
    };

    return (
        <>
            <Grid item xs={12} container>
                <FormControl disabled={disabled} component="fieldset">
                    <FormLabel component="legend">Repeats On Days</FormLabel>
                    <Grid item xs={12} container>
                        <OccurrenceDay
                            occurrenceDays={days}
                            dayLabel="Mon"
                            dayOfWeek={0}
                            onChange={onDayOccurrenceChange}
                        />
                        <OccurrenceDay
                            occurrenceDays={days}
                            dayLabel="Tue"
                            dayOfWeek={1}
                            onChange={onDayOccurrenceChange}
                        />
                        <OccurrenceDay
                            occurrenceDays={days}
                            dayLabel="Wed"
                            dayOfWeek={2}
                            onChange={onDayOccurrenceChange}
                        />
                        <OccurrenceDay
                            occurrenceDays={days}
                            dayLabel="Thu"
                            dayOfWeek={3}
                            onChange={onDayOccurrenceChange}
                        />
                        <OccurrenceDay
                            occurrenceDays={days}
                            dayLabel="Fri"
                            dayOfWeek={4}
                            onChange={onDayOccurrenceChange}
                        />
                        <OccurrenceDay
                            occurrenceDays={days}
                            dayLabel="Sat"
                            dayOfWeek={5}
                            onChange={onDayOccurrenceChange}
                        />
                        <OccurrenceDay
                            occurrenceDays={days}
                            dayLabel="Sun"
                            dayOfWeek={6}
                            onChange={onDayOccurrenceChange}
                        />
                    </Grid>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    disabled={disabled}
                    label="Repeats Every"
                    type="number"
                    value={interval}
                    onChange={onRepeatsChange}
                    name="repeatsEveryWeeks"
                    fullWidth
                    InputProps={{
                        endAdornment: <InputAdornment position="end">weeks</InputAdornment>,
                    }}
                />
            </Grid>
        </>
    );
};

export default RecurringDatesWeekly;
