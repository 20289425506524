import VisibilityIcon from '@mui/icons-material/Visibility';
import { Checkbox, MenuItem, Select } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApiListing from '../../../Api/ApiListing';
import { ListingLevelDTO, ListingWebsiteDTO } from '../../../Api/Model';
import { selectListingLevels } from '../../../Store/App/selectors';
import { myWebsiteListingUpdated } from '../../../Store/Dashboard/actions';
import ScoopUtil from '../../../Util/ScoopUtil';
import EnhancedTable, { HeadCell, TableSetting } from '../../UI/EnhancedTable';

interface Props {
    list: ListingWebsiteDTO[];
    onExcludeAll: (exclude: boolean, ids: number[]) => void;
}

const DataTable = (props: Props) => {
    const { list, onExcludeAll } = props;
    const dispatch = useDispatch();
    const listingLevels = useSelector(selectListingLevels);

    const [levels, setLevels] = useState<ListingLevelDTO[]>([]);
    const [tableSetting, setTableSetting] = useState<TableSetting>({page: 0, pageSize: 15, order: 'asc', orderBy: 'txtListingName'});

    useEffect(() => {
        if (listingLevels) {
            setLevels(listingLevels.sort((a, b) => a.intPriority - b.intPriority));
        }
    }, [listingLevels]);

    const onExcludedChange = (listing: ListingWebsiteDTO, checked: boolean) => {
        ApiListing.updateListingsExclusion(checked, [listing.pkListing])
            .then(res => {
                let copy = { ...listing };
                copy.excluded = checked;
                dispatch(myWebsiteListingUpdated(copy))
            })
            .catch(err => {

            })
    }


    const onClientListingLevelChange = (listing: ListingWebsiteDTO, fkListingLevel: number) => {
        ApiListing.addUpdateListingClientLevel(listing.pkListing, fkListingLevel, listing.pkClientListingLevel)
            .then(res => {
                let copy = { ...listing };
                copy.pkClientListingLevel = res;
                copy.fkClientListingLevel = fkListingLevel;
                dispatch(myWebsiteListingUpdated(copy))
            })
            .catch(err => {

            })
    }

    const mapRow = (listing: ListingWebsiteDTO, index: number): JSX.Element => {
        let listingLevelOption: ListingLevelDTO = levels.filter(l => l.pkListingLevel === listing.fkListingLevel).pop();
        return (
            <TableRow
                hover
                tabIndex={-1}
                key={listing.pkListing}
            >
                <TableCell>{listing.txtListingName}</TableCell>
                <TableCell >{listing.txtListingTypeName}</TableCell>
                <TableCell>{listing.listingUserDisplayName}</TableCell>
                <TableCell align='center'>
                    {listingLevelOption?.displayPriority}
                </TableCell>
                <TableCell align='center'>
                    <Select
                        value={listing.fkClientListingLevel}
                        onChange={e => onClientListingLevelChange(listing, e.target.value as number)}
                    >
                        {levels.filter(l => l.intPriority <= 10).map(l =>
                            <MenuItem key={`${listing.pkListing}_${l.pkListingLevel}`} value={l.pkListingLevel}>{l.displayPriority}</MenuItem>
                        )}
                    </Select>
                </TableCell>

                <TableCell align="center">
                    <Checkbox
                        checked={listing.excluded}
                        color="primary"
                        onChange={e => onExcludedChange(listing, e.target.checked)}
                    />
                </TableCell>
                <TableCell align="center">
                    <IconButton
                        href={ScoopUtil.generatePreviewLink(listing.listingPreviewURL)}
                        target="_blank"
                        color="primary"
                        size="large">
                        <VisibilityIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    }

    if (!!!list) return <></>

    const selectAll = list.filter(l => !!!l.excluded).length > 0

    const onExcludedAllChange = () => {
        let toUpdate = list.filter(l => l.excluded === !selectAll).map(l => l.pkListing);
        onExcludeAll(selectAll, toUpdate);
    }


    const headCells: HeadCell[] = [
        { id: 'txtListingName', sortable: true, disablePadding: false, label: 'Listing Name', minWidth: 160 },
        { id: 'txtListingTypeName', sortable: true, disablePadding: false, label: 'Type' },
        { id: 'listingUserDisplayName', sortable: true, disablePadding: false, label: 'Listing Owner' },
        { id: 'listingLevel', sortable: false,disablePadding: false, label: 'Network Default Level' },
        { id: 'clientListingLevel', sortable: false, disablePadding: false, label: 'My Website Level' },
        { id: 'excluded', disablePadding: false, label: 'Excluded',  selectAll: !selectAll, onSelectAll: onExcludedAllChange },
        { id: 'view', disablePadding: false, label: 'View' },
    ];

    return (<>
        <EnhancedTable
            headCells={headCells}
            rows={list}
            mapRow={mapRow}
            tableSetting={tableSetting}
            onTableSettingChange={setTableSetting}
        />
    </>

    );
}

export default DataTable;