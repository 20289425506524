import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import PublishIcon from '@mui/icons-material/Publish';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import Dropzone, { DropEvent, DropzoneRef, FileRejection } from 'react-dropzone';

const useStyles = makeStyles((theme) => ({
    dropzoneContainer: {
        color: theme.palette.grey[700],
        background: theme.palette.grey[200],
        borderStyle: 'dashed',
        borderWidth: '1px',
        borderRadius: '3px',
        borderColor: theme.palette.primary.main,
        width: '100%',
        height: '100%',
        outline: 'none',
        textAlign: 'center',
        transition: 'border .24s ease-in-out'
    },
    dropzoneContainerDragState: {
        borderColor: theme.palette.primary.dark,
        borderWidth: '2px',
        background: theme.palette.grey[400],
    },
    dropzoneDropArea: {
        paddingTop: '30px',
        width: '100%',
        height: '100%',
    },
    dropzoneIcon: {
        fontSize: '6rem',
        marginTop: theme.spacing(4),
    },
    dropzoneText: {
    }
}));

interface SelectImageProps {
    minWidth: number;
    minHeight: number;
    maxSize?: number;
    onFileSelected: (file: File) => void;
    onError: (error: string) => void;
};

const SelectImage = (props: SelectImageProps) => {
    const { minHeight, minWidth, maxSize, onError, onFileSelected } = props;
    const classes = useStyles();
    const dropzoneRef = useRef<DropzoneRef | null>(null);
    const [isDragging, setIsDragging] = useState(false);

    const onSelected = (file: File) => {
        let reader = new FileReader();
        reader.onload = () => {
            var image = new Image();
            image.src = reader.result as string;
            image.onload = () => {
                if (image.height < minHeight || image.width < minWidth) {
                    onError(`Image is too small (${image.width}x${image.height}px).  Must be at least ${minWidth}x${minHeight}px`)
                } else {
                    onFileSelected(file);
                }
            };
        };
        reader.readAsDataURL(file);
    }

    const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
        console.log('ImageDropped: ', acceptedFiles);
        console.log('ImageDropped: ', fileRejections);
        console.log('ImageDropped: ', event);

        if (fileRejections.length > 1) {
            onError('Only 1 image can be selected at one time');
        } else if (fileRejections.length === 1) {
            onError(fileRejections[0].errors[0].message);
        } else if (acceptedFiles.length === 1) {
            onSelected(acceptedFiles[0]);
        } else {
            onError('Unknown error');
        }
    }

    const onDropRejected = (fileRejections: FileRejection[], event: DropEvent) => {
        console.log('ImageDropped: ', fileRejections);
        console.log('ImageDropped: ', event);

        // if (fileRejections.length > 1) {
        //     props.onErorr('Only 1 image can be selected at one time');
        // } else if (fileRejections.length === 1) {
        //     props.onErorr(fileRejections[0].errors[0].message);
        // } else if (acceptedFiles.length === 1) {
        //     onFileSelected(acceptedFiles[0]);
        // } else {
        //     props.onErorr('Unknown error');
        // }
    }

    return (
        <React.Fragment>
            <div className={isDragging ? clsx(classes.dropzoneContainer, classes.dropzoneContainerDragState) : classes.dropzoneContainer}>
                <Dropzone
                    accept={['image/jpg', 'image/jpeg', 'image/png']}
                    maxSize={maxSize}
                    maxFiles={1}
                    onDragEnter={() => setIsDragging(true)}
                    onDragLeave={() => setIsDragging(false)}
                    onDropAccepted={() => setIsDragging(false)}
                    ref={dropzoneRef}
                    onDrop={onDrop}
                    onDropRejected={onDropRejected}>

                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} className={classes.dropzoneDropArea}>
                            <input {...getInputProps()} />
                            <Typography className={classes.dropzoneText} variant="body2">
                                Drag 'n' drop a file here, or click to select a file
                            </Typography>
                            <PublishIcon className={classes.dropzoneIcon} />
                        </div>
                    )}
                </Dropzone>
            </div>

        </React.Fragment>
    )
};

export default SelectImage