import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from "react-markdown";

interface Props {
    markdownFile: string;
    open: boolean;
    onClose: () => void;
}

const HelpPopup = (props: Props) => {
    const { open, markdownFile } = props;

    const [markdown, setMarkdown] = useState<string>(undefined);

    useEffect(() => {
        if (markdownFile) {
            fetch(markdownFile).then(res => {
                return res.text();
            }).then(markdown => {
                setMarkdown(markdown);
            })
        } else {
            setMarkdown(undefined)
        }
    }, [markdownFile]);

    const handleClose = () => {
        props.onClose()
    }

    if (!markdown) {
        return <React.Fragment />
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                 <ReactMarkdown
                    children={markdown}
                    linkTarget={"_blank"}
                    components={{
                        h1: (props: any) => <Typography variant="h6" {...props} />,
                        h2: (props: any) => <Typography variant="h6" {...props} />,
                        h3: (props: any) => <Typography variant="h6" {...props} />,
                        h4: (props: any) => <Typography variant="h6" {...props} />,
                        h5: (props: any) => <Typography variant="h6" {...props} />,
                        h6: (props: any) => <Typography variant="h6" {...props} />,
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default HelpPopup;