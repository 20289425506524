import { Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ListingApprovalDTO, ListingFullDTO, ScoopStatus } from "../../../../Api/Model";
import { updateListingSuccessAction } from "../../../../Store/Wizard/actions";
import { selectListing } from "../../../../Store/Wizard/selectors";
import ScoopUtil from "../../../../Util/ScoopUtil";
import ApprovalDialog, { ApprovalDialogMode } from "../../../UI/ApprovalDialog";
import FormGrid from "../FormGrid";
import SectionGrid from "../SectionGrid";

const useStyles = makeStyles((theme) => ({
    approvedBy: {
        fontSize: "large",
    },
    button: {
        margin: "0px 5px",
    },
}));

interface Props {}

const formatApprovalText = (status?: ScoopStatus, listing?: ListingFullDTO): string => {
    if (status && listing) {
        let approvedAt = listing.details.dtApproved ? format(new Date(listing.details.dtApproved), "do MMM yyyy") : 'unknown';
        let updatedAt = format(new Date(listing.details.dtLastUpdated || listing.details.dtCreated), "do MMM yyyy");
        if (status === ScoopStatus.APPROVED) {
            return `${ScoopStatus.APPROVED} by ${listing.details.userApprovedByDisplayname} on ${approvedAt}`;
        } else if (status === ScoopStatus.UNAPPROVED) {
            return `${ScoopStatus.UNAPPROVED} by ${listing.details.userApprovedByDisplayname} on ${approvedAt}`;
        } else {
            return `Pending and last updated on ${updatedAt}`;
        }
    } else {
        return "Loading details...";
    }
};

const ApprovalForm = (props: Props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    let params = useParams<{ id: any; licenseId: any }>();

    const listing = useSelector(selectListing);

    const [status, setStatus] = useState<ScoopStatus>(undefined);
    const [approvalMode, setApprovalMode] = useState<ApprovalDialogMode>(undefined);

    useEffect(() => {
        if (listing?.approval) {
            setStatus(ScoopUtil.scoopStatusFromApproval(listing.approval));
        }
    }, [listing]);

    const onApprovalClosed = (updated?: ListingApprovalDTO) => {
        if(updated) {
            let copy = {...listing}
            copy.approval = updated;
            copy.details.bitApproved = updated.isApproved;
            copy.details.dtApproved = updated.dtApproved;
            copy.details.userApprovedBy = updated.userApprovedBy;
            copy.details.dtLastUpdated = updated.dtLastUpdated;
            dispatch(updateListingSuccessAction(copy));
        }
        setApprovalMode(undefined);
    }

    return (
        <FormGrid>
            <SectionGrid title={`Current approval status:`}>
                <Grid item xs={12} className={classes.approvedBy}>
                    {formatApprovalText(status, listing)}
                </Grid>
            </SectionGrid>


            <SectionGrid title="Approvals">
                <Button
                    onClick={() => setApprovalMode(ApprovalDialogMode.Approve)}
                    disabled={status === undefined || status === ScoopStatus.APPROVED}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                >
                    Approve
                </Button>
                <Button
                    onClick={() => setApprovalMode(ApprovalDialogMode.Unapprove)}
                    disabled={status === undefined || status === ScoopStatus.UNAPPROVED}
                    variant="contained"
                    color="primary"
                >
                    Unapprove
                </Button>
            </SectionGrid>
            {approvalMode !== undefined && listing?.approval && (
                <ApprovalDialog
                    licenseId={params.licenseId}
                    listing={listing.approval}
                    mode={approvalMode}
                    onClose={onApprovalClosed}
                />
            )}
        </FormGrid>
    );
};

export default ApprovalForm;
