import { Theme } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { AnalyticsDTO } from "../../../Api/Model";
import Loading from "../../UI/Loading";
import PerformanceMetricDetails, { DetailsData } from "./PerformanceMetricDetails";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tabs: {
            "& button": {
                color: "#0097B2",
                textTransform: "none",
                fontWeight: "500",
                fontSize: "20px"
            },
            "& button.Mui-selected": {
                color: "#0097B2",
                fontWeight: "700"
            },
            "& .MuiTabs-indicator": {
                backgroundColor: "#0097B2",
                height: "5px",
                borderRadius: "15px"
              },
        },
    })
);

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

interface Props {
    analytics: AnalyticsDTO;
}

interface Data {
    views: DetailsData;
    opens: DetailsData;
    clicks: DetailsData;
    other: DetailsData;
}

export default function PerformanceMetricsTab(props: Props) {
    const classes = useStyles();

    const { analytics } = props;
    const [value, setValue] = React.useState(0);
    const [data, setData] = React.useState<Data>(undefined);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        const searches = analytics.performance.reduce((sum, current) => sum + current.searches, 0);
        setData({
            views: {
                searches: searches,
                total: analytics.performance.reduce((sum, current) => sum + current.views.total, 0),
                listing: analytics.performance.reduce((sum, current) => sum + current.views.listing, 0),
                avgListing: analytics.avgListing.views,
                avgFree: analytics.avgFree.views,
                avgPremium: analytics.avgPremium.views,
            },
            opens: {
                searches: searches,
                total: analytics.performance.reduce((sum, current) => sum + current.opens.total, 0),
                listing: analytics.performance.reduce((sum, current) => sum + current.opens.listing, 0),
                avgListing: analytics.avgListing.opens,
                avgFree: analytics.avgFree.opens,
                avgPremium: analytics.avgPremium.opens,
            },
            clicks: {
                searches: searches,
                total: analytics.performance.reduce((sum, current) => sum + current.clicks.total, 0),
                listing: analytics.performance.reduce((sum, current) => sum + current.clicks.listing, 0),
                avgListing: analytics.avgListing.clicks,
                avgFree: analytics.avgFree.clicks,
                avgPremium: analytics.avgPremium.clicks,
            },
            other: {
                searches: searches,
                total: analytics.performance.reduce((sum, current) => sum + current.other.total, 0),
                listing: analytics.performance.reduce((sum, current) => sum + current.other.listing, 0),
                avgListing: analytics.avgListing.other,
                avgFree: analytics.avgFree.other,
                avgPremium: analytics.avgPremium.other,
            },
        });
    }, [analytics]);

    if (!data) return <Loading />;

    return (
        <Box sx={{ width: "100%" }}>
            <Box>
                <Tabs className={classes.tabs} value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Views" {...a11yProps(0)} />
                    <Tab label="Opens" {...a11yProps(1)} />
                    <Tab label="Clicks" {...a11yProps(2)} />
                    <Tab label="Other" {...a11yProps(3)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <PerformanceMetricDetails data={data.views} name="views" />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <PerformanceMetricDetails data={data.opens} name="opens" />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <PerformanceMetricDetails data={data.clicks} name="clicks" />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <PerformanceMetricDetails data={data.other} name="other" />
            </TabPanel>
        </Box>
    );
}
