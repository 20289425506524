import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ApiListing from "../../../../Api/ApiListing";
import { HoursPeriod, ListingFullDTO, OpeningHoursData } from "../../../../Api/Model";
import { listingModifiedAction, updateWizardStepAction } from "../../../../Store/Wizard/actions";
import { selectAllowEdit, selectListing } from "../../../../Store/Wizard/selectors";
import ScoopUtil from "../../../../Util/ScoopUtil";
import { deepEqual } from "../../../../Util/Util";
import Loading from "../../../UI/Loading";
import WizardHeader from "../../WizardHeader";
import WizardMain from "../../WizardMain";
import WizardSidebar from "../../WizardSidebar";
import { WizardStep } from "../../WizardSteps";
import BackForwardButtons from "../BackForwardButtons";
import { FormOpeningHoursValues } from "../form-values";
import OpeningHoursForm from "./OpeningHoursForm";
import { formatDayText } from "./DayHoursInput";
import { selectUser } from "../../../../Store/User/selectors";

const defaultOpenHours = (day: number) : HoursPeriod[] => [{open: {time: "9:00", day: day}, close: {time: "17:00", day: day}}]
const defaultHoursDayText = formatDayText(false, false, defaultOpenHours(1));

const defaultData: OpeningHoursData = {
    monday: { closed: false, periods: defaultOpenHours(1), day_text: `Monday: ${defaultHoursDayText}`, open_24_hours: false },
    tuesday: { closed: false, periods: defaultOpenHours(2), day_text: `Tuesday: ${defaultHoursDayText}`, open_24_hours: false },
    wednesday: { closed: false, periods: defaultOpenHours(3), day_text: `Wednesday: ${defaultHoursDayText}`, open_24_hours: false },
    thursday: { closed: false, periods: defaultOpenHours(4), day_text: `Thursday: ${defaultHoursDayText}`, open_24_hours: false },
    friday: { closed: false, periods: defaultOpenHours(5), day_text: `Friday: ${defaultHoursDayText}`, open_24_hours: false },
    saturday: { closed: false, periods: defaultOpenHours(6), day_text: `Saturday: ${defaultHoursDayText}`, open_24_hours: false },
    sunday: { closed: false, periods: defaultOpenHours(0), day_text: `Sunday: ${defaultHoursDayText}`, open_24_hours: false },
    pubhol: { closed: false, periods: [], day_text: `Public Holiday: ${ formatDayText(false, false, [], true)}`, open_24_hours: false, vary: true },
};

const mapValues = (listing: ListingFullDTO): FormOpeningHoursValues => {
    let values: FormOpeningHoursValues = {
        additionalInfo: listing.openingHours?.additionalInfo || "",
        statusId: listing.openingHours?.openStatusId || 0,
        data: listing.openingHours?.data || defaultData,
    };
    return values;
};

interface Props {}

const OpeningHoursStep = (props: Props) => {
    const dispatch = useDispatch();
    let params = useParams<{ id: any; licenseId: any }>();
    const listing = useSelector(selectListing);
    const allowEdit = useSelector(selectAllowEdit);
    const user = useSelector(selectUser)

    const [origValues, setOrigValues] = useState<FormOpeningHoursValues>(undefined);
    const [values, setValues] = useState<FormOpeningHoursValues>(undefined);
    const [formValid, setFormValid] = useState(false);

    useEffect(() => {
        if (listing) {
            const mapped = mapValues(listing);
            setOrigValues(mapped);
            setValues({ ...mapped });
            setFormValid(true);
        }
    }, [listing]);


    const generatePromise = (): Promise<ListingFullDTO> => {
        if (!formValid) return Promise.reject("Form is not valid");
        if (deepEqual(origValues, values)) return undefined;

        return ApiListing.addUpdateListingOpeningTimes(
            listing.details.pkListing,
            listing.openingHours?.id || 0,
            values.statusId,
            values.additionalInfo,
            values.data,
            params.licenseId
        );
    };

    const onWizardStepChange = (step: WizardStep) => {
        if (formValid) {
            let promise = generatePromise();
            dispatch(updateWizardStepAction(step, promise));
        }
    };

    const onValuesChange = (values: FormOpeningHoursValues, formValid: boolean) => {
        setValues(values);
        setFormValid(formValid);
    };

    const onSaveHook = (): Promise<any> => {
        return generatePromise();
    };

    useEffect(() => {
        if (values && origValues) {
            dispatch(listingModifiedAction(!deepEqual(origValues, values)));
        }
    }, [values, origValues, dispatch]);

    return (
        <>
            <WizardHeader disabled={!!!formValid} backToListingsHook={onSaveHook} />
            <WizardSidebar disableAll={!!!formValid} onSaveHook={onSaveHook} />
            <WizardMain>
                {values ? (
                    <OpeningHoursForm
                        disabled={!allowEdit}
                        values={values}
                        onChange={onValuesChange}
                        lastUpdated={listing?.openingHours?.lastUpdated}
                    />
                ) : (
                    <Loading />
                )}

                <BackForwardButtons
                    disabled={!!!formValid}
                    onClickPrevious={() => onWizardStepChange(WizardStep.Categories)}
                    onClickNext={() =>
                        onWizardStepChange(
                            ScoopUtil.allowListingPremiumLevels(user, listing) ? WizardStep.PremiumLevels : ScoopUtil.allowListingLevels(listing) ? WizardStep.Levels : WizardStep.Images
                        )
                    }
                />
            </WizardMain>
        </>
    );
};

export default OpeningHoursStep;
