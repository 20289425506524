import { combineReducers } from 'redux';

import appReducer from './App/reducer';
import userReducer from './User/reducer';
import dashboardReducer from './Dashboard/reducer';
import wizardReducer from './Wizard/reducer';
import usersReducer from './Users/reducer';

export const state = combineReducers({
    appReducer,
    userReducer,
    dashboardReducer,
    wizardReducer,
    usersReducer
});

export type AppState = ReturnType<typeof state>;

export interface Action {
    type: string;
    payload?: any;
};