import { getValidToken } from "../Store/LocalStorage/UserStorage";
import Api from "./Api";
import { FeaturesMetadataDTO, ListingDirectoryDTO, ListingLevelDTO, ListingTypeDTO, MetadataAdminDTO, MetadataDTO, MetadataPublicDTO, RegionDTO, SocialLinksDTO } from "./Model";

export default class ApiMetadata extends Api {

    public static getPublicMetadata(): Promise<MetadataPublicDTO> {
        var parameters = {
            cache: true,
        };
        return this.axios.get("/admin/metadata/public", { params: parameters }).then((res: any) => {
            return res.data;
        });
    }

    public static getAdminMetadata(): Promise<MetadataAdminDTO> {
        const token = getValidToken();
        if(token) {
            return this.axios.get("/admin/metadata/admin", {headers: { Authorization:  "Bearer " + token}})
            .then((res: any) => {
                return res.data;
            });
        } else {
            return Promise.reject("Not logged in");
        }

    }

    public static getMetadata(): Promise<MetadataDTO> {
        const token = getValidToken();
        if (token) {
            var parameters = {
                includeRegions: false,
                cache: true,
            };
            return this.axios
                .get("/admin/metadata", {
                    headers: { Authorization: "Bearer " + token },
                    params: parameters,
                })
                .then((res: any) => {
                    return res.data;
                })
                .then((data: any) => {
                    const types: ListingTypeDTO[] = data.types.map((t: any) => t as ListingTypeDTO);
                    const levels: ListingLevelDTO[] = data.listingLevels.map((t: any) => t as ListingLevelDTO);
                    const socialLinks: SocialLinksDTO[] = data.socialLinks.map((t: any) => t as SocialLinksDTO);
                    const directories: ListingDirectoryDTO[] = types
                        .map((t) => {
                            const directory: ListingDirectoryDTO = {
                                pkListingTypeGroupId: t.pkListingTypeGroup,
                                listingTypeGroupName: t.groupName.replace("&amp;", "&"),
                            };
                            return directory;
                        })
                        .reduce((arr: ListingDirectoryDTO[], item: ListingDirectoryDTO) => {
                            if (!arr.find((t) => t.pkListingTypeGroupId === item.pkListingTypeGroupId)) {
                                arr.push(item);
                            }
                            return arr;
                        }, []);

                    return {
                        types: types,
                        directories: directories,
                        levels: levels,
                        socialLinks: socialLinks
                    };
                });
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static getMetadataRegions(): Promise<RegionDTO[]> {
        const token = getValidToken();
        if (token) {
            var parameters = {
                cache: true
            };
            return this.axios
                .get("/admin/metadata/regions", {
                    headers: { Authorization: "Bearer " + token },
                    params: parameters,
                })
                .then((res: any) => {
                    return res.data.regions;
                });
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static getListingMetadataFeatures(listingType: number): Promise<FeaturesMetadataDTO> {
        const token = getValidToken();
        if (token) {
            return this.axios
                .get(`/admin/metadata/features?listingTypeId=${listingType}`, {
                    headers: { Authorization: "Bearer " + token },
                })
                .then((res: any) => {
                    return res.data;
                });
        } else {
            return Promise.reject("Not logged in");
        }
    }

}
