import { Grid, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';
import ApiUsers from '../../../Api/ApiUsers';

interface Props {
}

const UpdatePasswordDialog = (props: Props) => {

  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  const handleClose = () => {
    setOpen(false);
  }

  const handleOpen = () => {
    setPassword('');
    setNewPassword('');
    setNewPasswordConfirm('');
    setOpen(true);
  }

  const handleSave = async () => {
    try{
      if(password === newPassword || newPassword !== newPasswordConfirm) {
        //todo error
        return;
      }
      await ApiUsers.updateUserPassword(password, newPassword);
      handleClose();
    } catch(err) {
      //todo error
    }
  }

  return (
    <>
    <Button color="primary" variant="contained" onClick={handleOpen}>Update Password</Button>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Update Password</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} >
            <TextField
              type="password"
              name="password"
              id="password"
              label="Current Password"
              placeholder="Current Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="password"
              name="newPassword"
              id="newPassword"
              label="New Password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="password"
              name="newPasswordConfirm"
              id="newPasswordConfirm"
              label="Confirm New Password"
              placeholder="Confirm New Password"
              value={newPasswordConfirm}
              onChange={(e) => setNewPasswordConfirm(e.target.value)}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
          </Button>
        <Button onClick={handleSave} color="primary" >
          Save
          </Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

export default UpdatePasswordDialog;