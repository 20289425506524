import { IconButton, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';

interface Props {
    disabled: boolean;
    videoUrl: string;
    onDelete: () => void;
}

const VideoItem = (props: Props) => {
    const { disabled, videoUrl } = props;

    return <>
        <ListItem>
            <ListItemText
                primary={videoUrl}
            />
            <ListItemSecondaryAction>
                {!disabled && <IconButton onClick={props.onDelete} edge="end" aria-label="delete" size="large">
                    <DeleteIcon />
                </IconButton>}
            </ListItemSecondaryAction>
        </ListItem>
    </>;
};

export default VideoItem;