import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExclusionType, ListingTypeDTO, ListingWebsiteDTO } from "../../../Api/Model";
import { fetchMyWebsiteListingsAction } from "../../../Store/Dashboard/actions";
import { selectMyWebsiteListingsLoading, selectMyWebsiteSelectedLicense } from "../../../Store/Dashboard/selectors";
import { selectContentLicenses } from "../../../Store/User/selectors";

interface Props {
    types: ListingTypeDTO[];
    list: ListingWebsiteDTO[];
    onFilterUpdate: (filtered: ListingWebsiteDTO[]) => void;
}

const Filters = (props: Props) => {
    const dispatch = useDispatch();
    const { list, types, onFilterUpdate } = props;

    const contentLicenses = useSelector(selectContentLicenses);
    const licenseId = useSelector(selectMyWebsiteSelectedLicense);
    const loading = useSelector(selectMyWebsiteListingsLoading);

    const [keyword, setKeyword] = useState("");
    const [listingTypeId, setListingTypeId] = useState<number>(undefined);
    const [exclusion, setExclusion] = useState<ExclusionType>(ExclusionType.All);

    useEffect(() => {
        let filtered: ListingWebsiteDTO[];
        if (list) {
            filtered = list.filter((l) =>
                exclusion === ExclusionType.Included
                    ? !!!l.excluded
                    : exclusion === ExclusionType.Excluded
                    ? l.excluded
                    : true
            );
            if (keyword?.length > 0) {
                let filterKeyword = keyword.toLocaleLowerCase();
                filtered = filtered.filter((l) => l.txtListingName.toLocaleLowerCase().includes(filterKeyword));
            }
            if (listingTypeId > 0) {
                filtered = filtered.filter((l) => l.fkListingType === listingTypeId);
            }
        }
        onFilterUpdate(filtered || []);
    }, [onFilterUpdate, list, keyword, listingTypeId, exclusion]);

    const onKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value);
    };

    const onListingTypeChange = (e: SelectChangeEvent<Number>) => {
        let id = e.target.value as any as number;
        setListingTypeId(id > 0 ? id : undefined);
    };

    const onExclusionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setExclusion(e.target.value as ExclusionType);
    };

    const onLicenseIdChange = (e: SelectChangeEvent<Number>) => {
        let id = e.target.value as any as number;
        dispatch(fetchMyWebsiteListingsAction(id > 0 ? id : undefined));
    };

    useEffect(() => {
        if (contentLicenses?.length > 0 && !!!licenseId && !loading) {
            dispatch(fetchMyWebsiteListingsAction(licenseId));
        }
    }, [dispatch, licenseId, contentLicenses, loading]);

    return (
        <>
            <Grid item sm={4} xs={12}>
                <FormControl variant="outlined" fullWidth disabled={loading}>
                    <InputLabel id="select-type-label">Content License</InputLabel>
                    <Select label="Content License" value={licenseId || 0} onChange={onLicenseIdChange}>
                        {contentLicenses?.map((lic) => (
                            <MenuItem
                                key={`license_${lic.pkLicense}`}
                                value={lic.pkLicense}
                            >{`${lic.txtLicenseName} (${lic.pkLicense})`}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item sm={3} xs={12}>
                <TextField
                    disabled={loading}
                    variant="outlined"
                    label="Search keyword"
                    placeholder="Keyword"
                    value={keyword}
                    fullWidth
                    onChange={onKeywordChange}
                />
            </Grid>

            <Grid item sm={3} xs={8}>
                <FormControl variant="outlined" fullWidth disabled={loading}>
                    <InputLabel id="select-type-label">Listing Type</InputLabel>
                    <Select label="Listing Type" value={listingTypeId || 0} onChange={onListingTypeChange}>
                        <MenuItem key={`type_unselected`} value={0}>
                            All
                        </MenuItem>
                        {types?.map((type) => (
                            <MenuItem key={`type_${type.pkListingType}`} value={type.pkListingType}>
                                {type.txtTypeName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item sm={2} xs={4}>
                <FormControl variant="outlined" fullWidth disabled={loading}>
                    <InputLabel id="select-type-label">Exclusion</InputLabel>
                    <Select label="Exclusion" value={exclusion} onChange={onExclusionChange}>
                        <MenuItem key={`all`} value={ExclusionType.All}>
                            All
                        </MenuItem>
                        <MenuItem key={`excluded`} value={ExclusionType.Excluded}>
                            Excluded
                        </MenuItem>
                        <MenuItem key={`included`} value={ExclusionType.Included}>
                            Included
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </>
    );
};

export default Filters;
