import { AppState } from "../root-reducer";

export const selectWizardStep = (state: AppState) => state.wizardReducer.wizardStep;

export const selectWizardConfig = (state: AppState) => state.wizardReducer.wizardConfig;

export const selectShowActivatedDialog = (state: AppState) => state.wizardReducer.showActivatedDialog;

export const selectListing = (state: AppState) => state.wizardReducer.listing;

export const selectAllowEdit = (state: AppState) => state.wizardReducer.allowEdit

export const selectListingModified = (state: AppState) => state.wizardReducer.modified;

export const selectListingLoading = (state: AppState) => state.wizardReducer.loading;

export const selectListingUpdating = (state: AppState) => state.wizardReducer.updating;

export const selectUploadingImages = (state: AppState) => state.wizardReducer.uploadingImages;

export const selectPremiumPlans = (state: AppState) => state.wizardReducer.premiumPlans;
