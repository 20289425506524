import RefreshIcon from "@mui/icons-material/Refresh";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, Grid } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { endOfDay } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListingApprovalDTO, ListingLevelDTO, ListingTypeDTO, Region, ScoopStatus, Status } from "../../../Api/Model";
import { selectListingLevels, selectRegions, selectTypes } from "../../../Store/App/selectors";
import { fetchListingsApprovalsAction } from "../../../Store/Dashboard/actions";
import {
    selectApprovalsExpired,
    selectApprovalsFilter,
    selectApprovalsListings,
    selectApprovalsListingsLoading,
    selectApprovalsSelectedLicense
} from "../../../Store/Dashboard/selectors";
import ScoopUtil from "../../../Util/ScoopUtil";
import HeaderActions from "../HeaderActions";
import HeaderContainer from "../HeaderContainer";
import HeaderTitle from "../HeaderTitle";
import DataTable from "./DataTable";
import Filters from "./Filters";
import ListingTableNoData from "./ListingTableNoData";

export interface ListingApproval extends ListingApprovalDTO {
    displayPriorityDefault: string;
    displayPriorityActive: string;
    userKeyword: string;
    listingKeyword: string;
    scoopStatus?: ScoopStatus;
    expired: boolean;
}

interface Data {
    allowedTypes: ListingTypeDTO[];
    allowedRegions: Region[];
    list: ListingApproval[];
    widgetRegion?: string;
    widgetType?: string;
}

const DEFAULT_DATA: Data = {allowedTypes: [], allowedRegions: [], list: []};

interface Props {}

const Approvals = (props: Props) => {
    const dispatch = useDispatch();
    const listings = useSelector(selectApprovalsListings);
    const licenseId = useSelector(selectApprovalsSelectedLicense);
    const showExpired = useSelector(selectApprovalsExpired);
    const listingsLoading = useSelector(selectApprovalsListingsLoading);
    const listingLevels = useSelector(selectListingLevels);
    const types = useSelector(selectTypes);
    const regions = useSelector(selectRegions);
    const [data, setData] = useState<Data>(DEFAULT_DATA);
    const [filtered, setFiltered] = useState<ListingApproval[]>([]);
    const filterValues = useSelector(selectApprovalsFilter);

    useEffect(() => {
        if (regions && types && listings && listingLevels) {
            let today = endOfDay(new Date());
            let validTypes = new Set<number>();
            let validRegions = new Set<number>();
            let mapped = listings.map((l) => {
                validTypes.add(l.listingTypeID);
                validRegions.add(l.regionID);
                let listing: ListingApproval = l as ListingApproval;
                let listingLevel: ListingLevelDTO = listingLevels
                    .filter((l) => l.pkListingLevel === listing.fkListingLevel)
                    .pop();
                let defaultListingLevel: ListingLevelDTO = listingLevels
                    .filter((l) => l.pkListingLevel === listing.fkListingLevelDefault)
                    .pop();
                listing.displayPriorityDefault = defaultListingLevel?.displayPriority || '00';
                listing.displayPriorityActive = listingLevel?.displayPriority || '00';
                listing.userKeyword = `${listing.listingUserDisplayName.toLowerCase()} ${listing.listingUserFullname.toLowerCase()}`;
                listing.listingKeyword = `${listing.listingName.toLowerCase()}`;
                listing.scoopStatus = ScoopUtil.scoopStatusFromApproval(listing);
                listing.expired = listing.eventEndDate
                    ? ScoopUtil.isExpired(today, new Date(listing.eventEndDate))
                    : false;
                return listing;
            });
            let validRegionsWithParents = new Set(validRegions);
            regions.forEach((r) => {
                if (validRegionsWithParents.has(r.pkRegion)) {
                    r.parents.forEach((p) => validRegionsWithParents.add(p));
                }
            });
            let allowedTypes = types.filter((t) => validTypes.has(t.pkListingType));
            let allowedRegionsWithParents = regions.filter((r) => validRegionsWithParents.has(r.pkRegion));
            let allowedRegions = regions.filter((r) => validRegions.has(r.pkRegion)).sort((a, b) => a.intLevel - b.intLevel);

            setData({
                list: mapped,
                allowedTypes: allowedTypes,
                allowedRegions: allowedRegionsWithParents,
                widgetType: allowedTypes.length > 0 ? allowedTypes[0].txtListingTypeSEO : undefined,
                widgetRegion: allowedRegions.length > 0 ? allowedRegions[0].txtURLAlias : undefined
            })
        }
    }, [listings, listingLevels, types, regions]);

    useEffect(() => {
        const { userKeyword, listingKeyword, status, scoopStatus, typeId, region } = filterValues;
        let filteredTemp: ListingApproval[] = [];
        if (data?.list?.length > 0) {
            filteredTemp = data.list.filter((l) =>
                status === Status.Active ? l.isVisible : status === Status.Inactive ? !l.isVisible : true
            );
            if (userKeyword?.length > 0) {
                let filterKeyword = userKeyword.toLocaleLowerCase();
                filteredTemp = filteredTemp.filter((l) => l.userKeyword.includes(filterKeyword));
            }
            if (listingKeyword?.length > 0) {
                let filterKeyword = listingKeyword.toLocaleLowerCase();
                filteredTemp = filteredTemp.filter((l) => l.listingKeyword.includes(filterKeyword));
            }
            if (scoopStatus) {
                filteredTemp = filteredTemp.filter((l) => l.scoopStatus === scoopStatus);
            }
            if (typeId > 0) {
                filteredTemp = filteredTemp.filter((l) => l.listingTypeID === typeId);
            }
            if (region) {
                filteredTemp = filteredTemp.filter((l) => region.allChildren.includes(l.regionID));
            }
        }
        setFiltered(filteredTemp);
    }, [data, filterValues]);

    const refresh = () => dispatch(fetchListingsApprovalsAction(licenseId, showExpired));

    return (
        <React.Fragment>
            <HeaderContainer>
                <HeaderTitle title={"Approvals"} />
                <HeaderActions>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={listingsLoading}
                        href={`/widget/${licenseId}/${data.widgetRegion}/${data.widgetType}`}
                        target="_blank"
                    >
                        <VisibilityIcon />
                        Widget Example
                    </Button>
                    <Button
                        style={{ marginLeft: "10px" }}
                        variant="contained"
                        color="primary"
                        onClick={refresh}
                        disabled={listingsLoading}
                    >
                        <RefreshIcon /> Refresh
                    </Button>
                </HeaderActions>
            </HeaderContainer>

            <Grid container spacing={1}>
                <Filters allowedRegions={data.allowedRegions} allowedTypes={data.allowedTypes} />
                {listingsLoading && (
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                )}
                <Grid item xs={12}>
                    {!listingsLoading && filtered.length > 0 && <DataTable list={filtered} />}
                    {!listingsLoading && filtered.length === 0 && <ListingTableNoData />}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default Approvals;
