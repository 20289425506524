import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid, Radio,
    RadioGroup, TextField
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ApiListing from "../../Api/ApiListing";
import { ListingApprovalDTO } from "../../Api/Model";
import { approvalsListingUpdatedAction } from "../../Store/Dashboard/actions";
import LoadingDialog from "./LoadingDialog";

export enum ApprovalDialogMode {
    Approve = "Approve",
    Unapprove = "Unapprove",
}

interface Props {
    licenseId?: number;
    listing: ListingApprovalDTO;
    mode: ApprovalDialogMode;
    onClose: (updated?: ListingApprovalDTO) => void;
}

enum EmailOption {
    Send = "send",
    Silent = "silent",
    SendByScoop = "sendByScoop",
}

const ApprovalDialog = (props: Props) => {
    const dispatch = useDispatch();
    const { mode, listing, licenseId, onClose } = props;
    const [option, setOption] = useState<EmailOption>(EmailOption.Send);
    const [note, setNote] = useState("");
    const [processing, setProcessing] = useState(false);

    const handleChange = (event) => {
        setOption(event.target.value as EmailOption);
    };

    const close = () => onClose();

    const onConfirm = async () => {
        setProcessing(true);
        try {
            let updated = await ApiListing.updateListingApproval(
                licenseId,
                listing.listingID,
                mode === ApprovalDialogMode.Approve,
                option !== EmailOption.Silent,
                note.split(/\n/),
                option === EmailOption.SendByScoop ? 'scoop' : undefined
            );
            dispatch(approvalsListingUpdatedAction(updated));
            onClose(updated);
        } catch (err) {
            //todo 
        } finally {
            setProcessing(false);
        }
    }
 
    return (
        <>
        <Dialog
            open={true}
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{`${mode} Listing`}</DialogTitle>

            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Email options</FormLabel>
                            <RadioGroup
                                aria-label="gender"
                                name="controlled-radio-buttons-group"
                                value={option}
                                onChange={handleChange}
                            >
                                {mode === ApprovalDialogMode.Approve && <>
                                    <FormControlLabel value={EmailOption.Send} control={<Radio />} label="Send Approved email" />
                                    <FormControlLabel value={EmailOption.SendByScoop} control={<Radio />} label="Send Added By Scoop email" />
                                    <FormControlLabel value={EmailOption.Silent} control={<Radio />} label="No Email / Silent Approval" />
                                </>}
                                {mode === ApprovalDialogMode.Unapprove && <>
                                    <FormControlLabel value={EmailOption.Send} control={<Radio />} label="Send Not Approved email" />
                                    <FormControlLabel value={EmailOption.Silent} control={<Radio />} label="No Email / Silent Disapproval" />
                                </>}
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                                multiline
                                rows={5}
                                fullWidth
                                placeholder="Extra comments for email"
                                helperText="Add notes in the comments field if required. If a listing has been
                            disapproved, you must include the reason e.g. “Your listing has
                            been disapproved because…”"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onConfirm} color="primary">
                    {mode}
                </Button>
                <Button onClick={close} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
        {processing && <LoadingDialog text="Processing update"/>}
        </>
    );
};

export default ApprovalDialog;
