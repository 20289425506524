import { Action } from '../root-reducer';
import { actionTypes as at } from './constants';
import { UserProfileDTO } from '../../Api/Model';

interface UserState {
    user: UserProfileDTO | undefined;
};

const initialState: UserState = {
    user: undefined

};

const reducer = (state : UserState = initialState, action: Action) : UserState => {
    switch (action.type) {
        case at.LOGIN_SUCCESS:
            return {
                ...state,
                user: action.payload.user
            }

        case at.LOGIN_FAILURE:
            return {
                ...state,
                user: undefined
            }
        default:
            return state
    }
};
export default reducer;