import { Checkbox, Divider, useMediaQuery } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Theme, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export interface HeadCell {
    disablePadding: boolean;
    id: string;
    sortId?: string;
    label: string;
    subLabel?: string;
    align?: "inherit" | "left" | "center" | "right" | "justify";
    sortable?: boolean;
    minWidth?: number;
    selectAll?: boolean;
    className?: string;
    onSelectAll?: () => void;
}

interface EnhancedTableProps {
    headCells: HeadCell[];
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
    order: Order;
    orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, headCells, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow className={classes.tableHeadRow}>
                {headCells.map((headCell) => (
                    <TableCell
                        style={{ minWidth: `${headCell.minWidth ? headCell.minWidth : 0}px` }}
                        className={headCell.className || classes.headCell}
                        key={headCell.id}
                        align={headCell.align || "center"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.sortable ? (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.sortId || headCell.id)}
                            >
                                {headCell.label} 
                                {headCell.subLabel ? <br/> : null}{headCell.subLabel}
                                {headCell.selectAll ? <button>Action</button> : null}
                                {orderBy === (headCell.id) ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        ) : (
                            <>
                                {headCell.label}
                                {headCell.onSelectAll ? (
                                    <Checkbox
                                        checked={headCell.selectAll}
                                        color="primary"
                                        onChange={headCell.onSelectAll}
                                    />
                                ) : null}
                            </>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        paper: {
            width: "100%",
            marginBottom: theme.spacing(2),
        },
        table: {},
        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1,
        },
        tableHeadRow: {
            height: "65px",
        },
        headCell: {
            fontWeight: "bold",
            padding: "10px",
        },
    })
);

export interface TableSetting {
    orderBy: string;
    order: Order;
    page: number;
    pageSize: number;
}

interface Props {
    headCells: HeadCell[];
    rows: any[];
    mapRow: (item: any, index: number) => JSX.Element;
    tableSetting: TableSetting;
    onTableSettingChange: (setting: TableSetting) => void;
    hidePaginationTop?: boolean;
    hidePaginationBottom?: boolean;
    mapFooter?: (data: any[]) => JSX.Element;
}

const EnhancedTable = (props: Props) => {
    const theme = useTheme();
    const widthXS = useMediaQuery(theme.breakpoints.down("sm"));
    const { headCells, rows, mapRow, hidePaginationTop, hidePaginationBottom, mapFooter, tableSetting, onTableSettingChange } = props;
    const classes = useStyles();
    const { page, pageSize, order, orderBy } = tableSetting;

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
        const isAsc = orderBy === property && order === "asc";
        onTableSettingChange({ ...tableSetting, order: isAsc ? "desc" : "asc", orderBy: property, page: 0 });
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        onTableSettingChange({ ...tableSetting, page: newPage });
    };

    const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
        let ps = parseInt(event.target.value, 10);
        let loadedItems = page * pageSize;
        let p = 0;
        if (loadedItems > 0) {
            //todo calculate correct page based on currently loaded items
        }
        onTableSettingChange({ ...tableSetting, pageSize: ps, page: p });
    };

    const emptyRows = pageSize - Math.min(pageSize, rows.length - page * pageSize);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                {!!!hidePaginationTop && (
                    <>
                        <TablePagination
                            rowsPerPageOptions={[5, 15, 30, 50, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={pageSize}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangePageSize}
                            labelRowsPerPage={widthXS ? "Rows" : "Rows per page"}
                        />
                        <Divider />
                    </>
                )}
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={"small"}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            headCells={headCells}
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * pageSize, page * pageSize + pageSize)
                                .map((row, index) => mapRow(row, index))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        {mapFooter && mapFooter(rows)}
                    </Table>
                </TableContainer>
                {!!!hidePaginationBottom && (
                    <TablePagination
                        rowsPerPageOptions={[5, 15, 30, 50, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={pageSize}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangePageSize}
                        labelRowsPerPage={widthXS ? "Rows" : "Rows per page"}
                    />
                )}
            </Paper>
        </div>
    );
};

export default EnhancedTable;
