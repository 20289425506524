import { Box, CardContent, IconButton, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import makeStyles from "@mui/styles/makeStyles";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import React, { useEffect, useRef, useState } from "react";
import { ImageDTO } from "../../../../Api/Model";
import ScoopUtil from "../../../../Util/ScoopUtil";
import AlertDialog from "../../../UI/AlertDialog";

const styles = makeStyles({
    media: {
        height: 0,
        paddingTop: "75%",
    },
    mediaLoading: {
        height: 0,
        paddingTop: "75%", // 16:9
        backgroundSize: "auto",
    },
});

interface Props {
    disabled: boolean;
    image: ImageDTO;
    primary?: boolean;
    onEditClick: () => void;
    onDeleteClick: () => void;
    onUpClick?: () => void;
    onDownClick?: () => void;
    onPrimaryClick?: () => void;
}

const ImageCard = (props: Props) => {
    const { disabled,  image, primary } = props;

    let classes = styles();

    const [confirm, setConfirm] = useState(false);
    const [imageExists, setImageExists] = useState<boolean>(false);
    const activeTimeout = useRef(null);

    useEffect(() => {
        if (imageExists && activeTimeout && activeTimeout.current) {
            clearTimeout(activeTimeout.current);
            activeTimeout.current = null;
        }
    }, [imageExists]);

    useEffect(() => {
        const checkIfImageExists = async () => {
            let res;
            try {
                res = await ScoopUtil.fileExists(ScoopUtil.urlImageLarge(image));
            } catch (err) {
                res = false;
            }
            if (res !== imageExists) {
                setImageExists(res);
            }
            if (!!!res) {
                activeTimeout.current = setTimeout(checkIfImageExists, 2500);
            }
        };
        checkIfImageExists();
    }, [image, imageExists]);

    const clearActiveTimeout = () => {
        if (activeTimeout.current) {
            clearTimeout(activeTimeout.current);
        }
    };

    useEffect(() => {
        return () => {
            clearActiveTimeout();
        };
    }, []);

    const onDeleteConfirm = () => {
        setConfirm(false);
        props.onDeleteClick();
    };

    return (
        <>
            <Card>
                <CardMedia
                    className={imageExists ? classes.media : classes.mediaLoading}
                    image={imageExists ? ScoopUtil.urlImageLarge(image) : "/assets/loading.gif"}
                />
                <CardContent>
                    <Typography>{image.displayCaption}</Typography>
                </CardContent>
                <CardActions>
                    {!disabled && (
                        <Box display="flex" justifyContent="flex-end" width={"100%"}>
                            {!!!primary && (
                                <IconButton onClick={props.onPrimaryClick} title="Make primary" size="large">
                                    <StarBorderIcon />
                                </IconButton>
                            )}
                            {props.onUpClick && (
                                <IconButton onClick={props.onUpClick} title="Move Up" size="large">
                                    <ArrowUpwardIcon />
                                </IconButton>
                            )}
                            {props.onDownClick && (
                                <IconButton onClick={props.onDownClick} title="Move Down" size="large">
                                    <ArrowDownwardIcon />
                                </IconButton>
                            )}
                            <IconButton title="Edit Image" onClick={props.onEditClick} aria-label="edit" size="large">
                                <EditIcon />
                            </IconButton>
                            <IconButton
                                title="Delete Image"
                                onClick={() => setConfirm(true)}
                                aria-label="delete"
                                size="large"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    )}
                </CardActions>
            </Card>
            {confirm && (
                <AlertDialog
                    onConfirm={onDeleteConfirm}
                    onClose={() => setConfirm(false)}
                    title={"Delete Image"}
                    message={"Are you sure you want to delete this image?"}
                    confirmText={"Delete Image"}
                />
            )}
        </>
    );
};

export default ImageCard;
