import { ListingApprovalDTO, ListingDTO, Status, ListingWebsiteDTO } from "../../Api/Model";
import { FilterValues as ApprovalsFilterValues } from "../../Components/Dashboard/Approvals/Filters";
import { FilterValues as ListingsFilterValues } from "../../Components/Dashboard/Listings/Filters";
import { TableSetting } from "../../Components/UI/EnhancedTable";
import { Action } from "../root-reducer";
import { actionTypes as at } from "./constants";

interface DashboardState {
    listings: ListingDTO[] | undefined;
    listingsLoading: boolean;
    listingsError: Error | undefined;
    listingsFilter: ListingsFilterValues;
    listingsTableSetting: TableSetting;

    myWebsiteLicense: number | undefined;
    myWebsiteListings: ListingWebsiteDTO[];
    myWebsiteListingsLoading: boolean;
    myWebsiteListingsError: Error | undefined;

    approvalsLicense: number | undefined;
    approvalsFilter: ApprovalsFilterValues;
    approvalsExpired: boolean;
    approvalsTableSetting: TableSetting;

    approvalsListings: ListingApprovalDTO[];
    approvalsListingsLoading: boolean;
    approvalsListingsError: Error | undefined;
}

const approvalFilter: ApprovalsFilterValues = {
    status: Status.All,
    typeId: 0,
    userKeyword: "",
    listingKeyword: "",
};

const listingsFilter: ListingsFilterValues = {
    keyword: "",
    typeId: 0,
    includeExpired: false,
};

const initialState: DashboardState = {
    listings: undefined,
    listingsLoading: false,
    listingsError: undefined,
    listingsFilter: listingsFilter,
    listingsTableSetting: {
        page: 0,
        pageSize: 15,
        order: "desc",
        orderBy: "dtLastUpdated",
    },
    approvalsLicense: undefined,
    approvalsListings: [],
    approvalsListingsLoading: false,
    approvalsListingsError: undefined,
    approvalsFilter: approvalFilter,
    approvalsExpired: false,
    approvalsTableSetting: {
        page: 0,
        pageSize: 15,
        order: "desc",
        orderBy: "dtLastUpdated",
    },
    myWebsiteLicense: undefined,
    myWebsiteListings: [],
    myWebsiteListingsLoading: false,
    myWebsiteListingsError: undefined,
};

const reducer = (state = initialState, action: Action): DashboardState => {
    switch (action.type) {
        case at.DASHBOARD_LISTING_DELETED:
            return {
                ...state,
                listings: state.listings.filter((l) => action.payload.pkListing !== l.pkListing),
            };
        case at.DASHBOARD_LISTING_UPDATED:
            let listingToUpdate: ListingDTO = action.payload.listing;
            return {
                ...state,
                listings: state.listings.map((l) => {
                    if (l.pkListing === listingToUpdate.pkListing) {
                        return listingToUpdate;
                    } else {
                        return l;
                    }
                }),
            };
        case at.DASHBOARD_FETCH_LISTINGS:
            return {
                ...state,
                listingsLoading: true,
            };

        case at.DASHBOARD_FETCH_LISTINGS_SUCCESS:
            return {
                ...state,
                listingsLoading: false,
                listings: action.payload.listings,
                listingsError: undefined,
            };

        case at.DASHBOARD_FETCH_LISTINGS_ERROR:
            return {
                ...state,
                listingsLoading: false,
                listings: undefined,
                listingsError: action.payload.error,
            };
        case at.DASHBOARD_LISTINGS_FILTER_UPDATED:
            return {
                ...state,
                listingsFilter: action.payload,
                listingsTableSetting: { ...state.listingsTableSetting, page: 0 },
            };
        case at.DASHBOARD_LISTINGS_TABLE_SETTING_UPDATED:
            return {
                ...state,
                listingsTableSetting: action.payload,
            };

        case at.DASHBOARD_FETCH_MY_WEBSITE_LISTINGS:
            return {
                ...state,
                myWebsiteListingsLoading: true,
                myWebsiteLicense: action.payload.license,
                myWebsiteListings: [],
            };

        case at.DASHBOARD_FETCH_MY_WEBSITE_LISTINGS_SUCCESS:
            return {
                ...state,
                myWebsiteListingsLoading: false,
                myWebsiteListings: action.payload.listings,
                myWebsiteLicense: action.payload.license,
                myWebsiteListingsError: undefined,
            };

        case at.DASHBOARD_FETCH_MY_WEBSITE_LISTINGS_ERROR:
            return {
                ...state,
                myWebsiteListingsLoading: false,
                myWebsiteListings: [],
                myWebsiteLicense: undefined,
                myWebsiteListingsError: action.payload.error,
            };

        case at.DASHBOARD_MY_WEBSITE_LISTING_UPDATED:
            let listing: ListingWebsiteDTO = action.payload.listing;
            return {
                ...state,
                myWebsiteListingsLoading: false,
                myWebsiteListings: state.myWebsiteListings.map((l) =>
                    l.pkListing === listing.pkListing ? listing : l
                ),
                myWebsiteListingsError: undefined,
            };
        case at.DASHBOARD_FETCH_APPROVALS_LISTINGS:
            return {
                ...state,
                approvalsLicense: action.payload.license,
                approvalsExpired: action.payload.expired,
                approvalsListingsLoading: true,
            };

        case at.DASHBOARD_FETCH_APPROVALS_LISTINGS_SUCCESS:
            return {
                ...state,
                approvalsListingsLoading: false,
                approvalsLicense: action.payload.license,
                approvalsExpired: action.payload.expired,
                approvalsListings: action.payload.listings,
                approvalsListingsError: undefined,
                approvalsTableSetting: {
                    ...state.approvalsTableSetting,
                    page: 0,
                    order: "desc",
                    orderBy: "dtLastUpdated",
                },
                approvalsFilter: approvalFilter,
            };

        case at.DASHBOARD_FETCH_APPROVALS_LISTINGS_ERROR:
            return {
                ...state,
                approvalsListingsLoading: false,
                approvalsLicense: undefined,
                approvalsListings: [],
                approvalsListingsError: action.payload.error,
            };
        case at.DASHBOARD_APPROVALS_LISTING_UPDATED:
            let apprToUpdate: ListingApprovalDTO = action.payload.listing;
            return {
                ...state,
                approvalsListings: state.approvalsListings.map((l) =>
                    l.listingID === apprToUpdate.listingID ? apprToUpdate : l
                ),
            };
        case at.DASHBOARD_APPROVALS_LISTING_ADDED:
            return {
                ...state,
                approvalsListings: [action.payload.listing, ...state.approvalsListings],
            };
        case at.DASHBOARD_APPROVALS_FILTER_UPDATED:
            return {
                ...state,
                approvalsFilter: action.payload,
                approvalsTableSetting: { ...state.approvalsTableSetting, page: 0 },
            };
        case at.DASHBOARD_APPROVALS_TABLE_SETTING_UPDATED:
            return {
                ...state,
                approvalsTableSetting: action.payload,
            };
        default:
            return state;
    }
};
export default reducer;
