import { Box } from '@mui/material';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import * as React from 'react';
import { ScoopStatus } from '../../Api/Model';

interface Props {
    status: ScoopStatus;
}

const ScoopStatusIcon = (props: Props) => {
    const { status } = props;
    let icon;
    let title;
    switch(status) {
        case ScoopStatus.APPROVED: 
            icon = <CheckIcon htmlColor="green" />;
            title = 'Approved'
            break;
        case ScoopStatus.UNAPPROVED:
            icon = <ClearIcon htmlColor="red"/>;
            title = 'Rejected'
            break;
        default:
            icon = <AvTimerIcon htmlColor="orange"/>;
            title = 'Pending'
            break;
    }
    return (
        <Box justifyContent="center" display="flex" alignItems="center" title={title}>
            {icon}
        </Box>
    );
};

export default ScoopStatusIcon;