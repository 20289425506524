import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import ApiListing from '../../../../Api/ApiListing';
import { ListingDTO } from '../../../../Api/Model';


interface Props {
    disabled: boolean;
    parentId: number;
    onRemove: () => void;
}

const LinkedListing = (props: Props) => {
    const { disabled, parentId } = props;

    const [listing, setListing] = useState<ListingDTO>(undefined);

    useEffect(() => {
        if (parentId) {
            ApiListing.getListing(parentId)
                .then(res => setListing(res));
        } else {
            setListing(undefined);
        }
    }, [parentId])


    return (
        <Grid item xs={12} container>
            <Grid item xs={8}>
                {listing ? `${listing.txtListingName}` : 'Loading listing details'}
            </Grid>

            {!disabled && <Grid item xs={2}>
                <Button onClick={props.onRemove} variant="contained" color="primary">Remove</Button>
            </Grid>}
        </Grid>

    )
};

export default LinkedListing;