import { Alert } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import FormGrid from '../FormGrid';
import SectionGrid from '../SectionGrid';
import LinkedListing from './LinkedListing';
import SelectListing from './SelectListing';

interface Props {
    disabled: boolean;
    values: number[];
    onChange: (values: number[]) => void;
}

const LinkedListingsForm = (props: Props) => {
    const { disabled,  values, onChange } = props;

    const onRemove = (parentId: number) => {
        onChange(values.filter(p => p !== parentId));
    }

    const onAdd = (parentId: number) => {
        if (!!!values.includes(parentId)) {
            onChange([...values, parentId]);
        }
    }

    return (
        <FormGrid>
            <SectionGrid title={'Linked Listings'}>
                {values.length === 0 && <Grid item xs={12}><Alert severity="info">No linked listing selected</Alert></Grid>}
                {values.map(p => <LinkedListing
                    key={`linked_listing_${p}`}
                    disabled={disabled}
                    parentId={p}
                    onRemove={() => onRemove(p)}
                />)}

            </SectionGrid>

            {!disabled && <SectionGrid title={'Search for listings to link'}>
                <SelectListing
                    open={values.length < 5}
                    onSelected={onAdd}
                />
            </SectionGrid>}

        </FormGrid>
    )
};

export default LinkedListingsForm;