import ApiUsers from '../../Api/ApiUsers';
import { LoginDTO, UserProfileDTO } from '../../Api/Model';
import { setToken } from '../LocalStorage/UserStorage';
import { actionTypes as at } from './constants';


export const validateTokenAndFetchUserAction = () => {
    return async (dispatch, getState) => {
        try {
            const user = await ApiUsers.getUser();
            dispatch(updateUserAction(user));
        } catch (err) {
        }
    }
};

export const loginSuccess = (dto: LoginDTO) => {
    return async (dispatch, getState) => {
        setToken(dto);
        dispatch(updateUserAction(dto.user));
    }
};

export const updateUserAction = (user: UserProfileDTO) => {
    return {
        type: at.LOGIN_SUCCESS,
        payload: {
            user: user
        }
    }
};

export const loginFailureAction = (error: Error) => {
    return {
        type: at.LOGIN_FAILURE,
        payload: {
            error: error
        }
    }
};

