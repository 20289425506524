import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ApiListing from "../../../../Api/ApiListing";
import { ListingFullDTO } from "../../../../Api/Model";
import { listingModifiedAction, updateWizardStepAction } from "../../../../Store/Wizard/actions";
import { selectAllowEdit, selectListing } from "../../../../Store/Wizard/selectors";
import DateUtil from "../../../../Util/DateUtil";
import { deepEqual } from "../../../../Util/Util";
import Loading from "../../../UI/Loading";
import WizardGuide from "../../WizardGuide";
import WizardHeader from "../../WizardHeader";
import WizardMain from "../../WizardMain";
import WizardSidebar from "../../WizardSidebar";
import { WizardStep } from "../../WizardSteps";
import BackForwardButtons from "../BackForwardButtons";
import { FormDatesValues } from "../form-values";
import FormGrid from "../FormGrid";
import SectionGrid from "../SectionGrid";
import DateRangeForm from "./DateRangeForm";
import PickDatesForm from "./PickDatesForm";
import RecurringDatesForm from "./RecurringDatesForm";

export const mapListingFormValues = (listing: ListingFullDTO): FormDatesValues => {
    let list: string[] = [];
    if (listing.eventInfo.fkEventDateType === 1) {
        list = [DateUtil.formatDateString(new Date(listing.eventInfo.dtEventFrom))];
    } else {
        list = listing.eventDates.map((d) => DateUtil.formatDateString(new Date(d.startDate)));
    }
    const et = listing.eventInfo.fkEventDateType;
    return {
        pickerType: et === 4 ? 2 : et === 2 ? 1 : 0,
        ...listing.eventInfo,
        dates: list,
    };
};

export const isOccurrenceDateChecked = (date: Date, occurrenceDays: number): boolean => {
    // Scoop days start at Monday=0, Moment days start at Sunday=0
    const dayOfWeek = (date.getDay() + 6) % 7;
    return isOccurrenceDayChecked(dayOfWeek, occurrenceDays);
}

export const isOccurrenceDayChecked = (dayOfWeek: number, occurrenceDays: number): boolean => {
    // Mon=1, Tue=2, Wed=4, Thu=8, Fri=16, Sat=32, Sun=64 ...
    const dayMask = Math.pow(2, dayOfWeek)
    return ((occurrenceDays & dayMask) === dayMask)
}

interface Props {}

const DatesStep = (props: Props) => {
    const dispatch = useDispatch();
    let params = useParams<{ id: any; licenseId: any }>();

    const [origValues, setOrigValues] = useState<FormDatesValues>(undefined);
    const [values, setValues] = useState<FormDatesValues>(undefined);

    const listing = useSelector(selectListing);
    const allowEdit = useSelector(selectAllowEdit);

    const onDatePickerChange = (event: SelectChangeEvent<number>) => {
        const newPicker = event.target.value as number;
        let newValues: FormDatesValues = {
            pickerType: newPicker,
            fkEventDateType: newPicker === 2 ? 4 : newPicker === 1 ? 2 : 1,
            dates: [],
        };
        if (newPicker === 2) {
            newValues.dtEventFrom = DateUtil.formatDateString(new Date());
        }
        setValues(newValues);
    };

    useEffect(() => {
        if (listing) {
            const mapped = mapListingFormValues(listing);
            setOrigValues(mapped);
            setValues(mapped);
        }
    }, [listing]);

    useEffect(() => {
        dispatch(listingModifiedAction(!deepEqual(origValues, values)));
    }, [values, origValues, dispatch]);

    const generatePromise = (): Promise<ListingFullDTO> | undefined => {
        let promise: Promise<ListingFullDTO> = undefined;
        if (!deepEqual(origValues, values)) {
            const { pkEventInfo, pkEventDateRecurringID } = listing.eventInfo;
            promise = ApiListing.updateListingDates(
                listing.details.pkListing,
                pkEventInfo,
                values,
                pkEventDateRecurringID, 
                params.licenseId
            ).then((res) => {
                setOrigValues(values);
                return res;
            });
        }
        return promise;
    };

    const onWizardStepChange = (step: WizardStep) => {
        dispatch(updateWizardStepAction(step, generatePromise()));
    };

    const onClickPrevious = () => {
        onWizardStepChange(WizardStep.Description);
    };

    const onClickNext = () => {
        onWizardStepChange(WizardStep.LinkedListings);
    };

    const onSaveHook = (): Promise<any> => {
        return generatePromise();
    };

    const onSaveReturnHook = (): Promise<any> => {
        return generatePromise();
    };

    return (
        <>
            <WizardHeader backToListingsHook={onSaveReturnHook} />
            <WizardSidebar onSaveHook={onSaveHook} />
            <WizardMain>
                <FormGrid>
                    <SectionGrid title="Step 1: Select date-picker">
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth disabled={!allowEdit}>
                                <InputLabel id="state">Date-picker</InputLabel>
                                <Select
                                    name="datePicker"
                                    id="datePicker"
                                    label="Date-picker"
                                    value={values?.pickerType || 0}
                                    onChange={onDatePickerChange}
                                >
                                    <MenuItem key={`date_picker_0`} value={0}>
                                        Individual Dates
                                    </MenuItem>
                                    <MenuItem key={`date_picker_1`} value={1}>
                                        Date Range
                                    </MenuItem>
                                    <MenuItem key={`date_picker_2`} value={2}>
                                        Recurring Dates
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </SectionGrid>

                    {origValues ? (
                        <>
                            {values?.pickerType === 0 && <PickDatesForm disabled={!allowEdit} values={values} onChange={setValues} />}

                            {values?.pickerType === 1 && <DateRangeForm disabled={!allowEdit} values={values} onChange={setValues} />}

                            {values?.pickerType === 2 && <RecurringDatesForm disabled={!allowEdit} values={values} onChange={setValues} />}
                        </>
                    ) : (
                        <Loading text="Loading listing details" />
                    )}
                </FormGrid>
                <BackForwardButtons onClickNext={onClickNext} onClickPrevious={onClickPrevious} />
            </WizardMain>
            <WizardGuide step={WizardStep.Dates} />
        </>
    );
};

export default DatesStep;
