import { FormDatesValues, FormFeaturesValue } from "../Components/Wizard/Forms/form-values";
import { getValidToken } from "../Store/LocalStorage/UserStorage";
import Api from "./Api";
import {
    CreateImageDTO,
    ExclusionType,
    ListingApprovalDTO,
    ListingApprovalStatus, ListingDTO,
    ListingFullDTO,
    ListingWebsiteDTO,
    OpeningHoursData,
    Status
} from "./Model";

export default class ApiListing extends Api {
    public static getListings(
        keyword: string,
        userKeyword: string,
        regionId: number,
        typeId: number,
        groupId: number,
        approval: ListingApprovalStatus,
        status: Status,
        showExpiredEvents: boolean,
        onlyOwn: boolean,
        limit: number
    ): Promise<ListingDTO[]> {
        const token = getValidToken();
        if (token) {
            var parameters = {
                region: regionId,
                keyword: keyword,
                keywordUser: userKeyword,
                listingTypeId: typeId,
                listingTypeGroupId: groupId,
                approval: approval,
                status: status,
                expiredEvents: showExpiredEvents,
                onlyOwn: onlyOwn,
                limit: limit,
            };
            return this.axios
                .get("/admin/listings", {
                    headers: { Authorization: "Bearer " + token },
                    params: parameters,
                })
                .then((res: any) => {
                    return res.data.listings;
                })
                .then((listings: any) => {
                    return listings.map((l: any) => l as ListingDTO);
                });
        } else {
            return Promise.resolve([]);
        }
    }

    public static getListingsByKeyword(keyword: string, onlyOwn: boolean, limit: number): Promise<ListingDTO[]> {
        const token = getValidToken();
        if (token) {
            let params = {
                region: 12023,
                keyword,
                onlyOwn,
                limit,
            };
            return this.axios
                .get("/admin/listings", { headers: { Authorization: "Bearer " + token }, params })
                .then((res: any) => {
                    return res.data.listings;
                })
                .then((listings: any) => {
                    return listings.map((l: any) => l as ListingDTO);
                });
        } else {
            return Promise.resolve([]);
        }
    }

    public static getListingDetails(id: number, licenseId?: number): Promise<ListingFullDTO | undefined> {
        const token = getValidToken();
        if (token) {
            let params = {
                listingId: id,
                licenseId,
            };
            return this.axios
                .get("/admin/listing", { params, headers: { Authorization: "Bearer " + token } })
                .then((res) => res.data);
        } else {
            return Promise.resolve(undefined);
        }
    }

    public static getListing(id: number): Promise<ListingDTO | undefined> {
        const token = getValidToken();
        if (token) {
            var parameters = {
                region: 12023,
                listingId: id,
                expiredEvents: true,
                onlyOwn: false,
                limit: 1,
            };
            return this.axios
                .get("/admin/listings", {
                    headers: { Authorization: "Bearer " + token },
                    params: parameters,
                })
                .then((res: any) => {
                    return res.data.listings;
                })
                .then((listings: ListingDTO[]) => {
                    return listings.length > 0 ? listings[0] : undefined;
                });
        } else {
            return Promise.resolve(undefined);
        }
    }

    public static createListing(listingTitle: string, pkListingType: number, userId?: number): Promise<ListingFullDTO> {
        const token = getValidToken();

        if (token) {
            const headers = { Authorization: "Bearer " + token };
            const data = {
                pkListingType: pkListingType,
                listingName: listingTitle,
                userId: userId,
            };
            return this.axios.post("/admin/listing", data, { headers: headers }).then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static copyListing(
        listingId: number,
        pkListingType: number,
        duplicateImages: boolean,
        duplicateFiles: boolean,
        licenseId?: number
    ): Promise<ListingFullDTO> {
        const token = getValidToken();

        if (token) {
            const headers = { Authorization: "Bearer " + token };
            const parameters = { listingId, licenseId };
            const data = {
                pkListingType: pkListingType,
                duplicateImages: duplicateImages,
                duplicateFiles: duplicateFiles,
            };
            return this.axios
                .post("/admin/listing/clone", data, { headers: headers, params: parameters, })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static deleteListing(pkListing: number): Promise<ListingFullDTO> {
        const token = getValidToken();

        if (token) {
            const headers = { Authorization: "Bearer " + token };
            return this.axios
                .delete("/admin/listing?listingId=" + pkListing, { headers: headers })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static updateListing(
        listingId: number,
        listingTitle: string,
        typeId: number,
        licenseId?: number
    ): Promise<ListingFullDTO> {
        const token = getValidToken();

        if (token) {
            const headers = { Authorization: "Bearer " + token };
            const parameters = { listingId, licenseId };
            const data = { listingName: listingTitle, listingTypeId: typeId };
            return this.axios
                .put("/admin/listing", data, { headers: headers, params: parameters })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static listingReviewed(listingId: number, licenseId?: number): Promise<ListingDTO> {
        const token = getValidToken();

        if (token) {
            const headers = { Authorization: "Bearer " + token };
            const parameters = { listingId, licenseId };
            return this.axios
                .post("/admin/listing/reviewed", {}, { headers: headers, params: parameters })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static updateListingVisibility(
        listingId: number,
        isVisible: boolean,
        licenseId?: number
    ): Promise<ListingFullDTO> {
        const token = getValidToken();

        if (token) {
            const headers = { Authorization: "Bearer " + token };
            const parameters = { listingId, licenseId };
            const data = { isVisible: isVisible };
            return this.axios
                .put("/admin/listing/visibility", data, { headers: headers, params: parameters })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static updateListingContacts(
        listingId: number,
        phone?: string,
        email?: string,
        website?: string,
        bookingLink?: string,
        affiliateLink?: string,
        linkId1?: number,
        link1?: string,
        linkId2?: number,
        link2?: string,
        linkId3?: number,
        link3?: string,
        licenseId?: number
    ): Promise<ListingFullDTO> {
        const token = getValidToken();

        if (token) {
            const headers = { Authorization: "Bearer " + token };
            const parameters = { listingId, licenseId };
            const data = {
                phone: phone,
                email: email,
                web: website,
                bookingLink: bookingLink,
                affiliateLink: affiliateLink,
                linkId1: linkId1,
                link1: link1,
                linkId2: linkId2,
                link2: link2,
                linkId3: linkId3,
                link3: link3,
            };

            return this.axios
                .put("/admin/listing/contacts", data, { headers: headers, params: parameters })
                .then((res) => {
                    return res.data;
                });
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static updateListingLocation(
        listingId: number,
        regionId: number,
        address: string,
        postCode: string,
        suburb: string,
        city: string,
        stateId: number,
        googleMapUrl: string,
        latitude: number,
        longitude: number,
        noMapProfile: boolean,
        noMapSearch: boolean,
        location: string,
        venue: string,
        venueIsGoogle: boolean,
        licenseId?: number
    ): Promise<ListingFullDTO> {
        const token = getValidToken();
        if (token) {
            const headers = { Authorization: "Bearer " + token };
            const parameters = { listingId, licenseId };
            const data = {
                regionId: regionId,
                address: address,
                postCode: postCode,
                suburb: suburb,
                city: city,
                stateId: stateId,
                googleMapUrl: googleMapUrl,
                latitude: latitude,
                longitude: longitude,
                noMapProfile: noMapProfile,
                noMapSearch: noMapSearch,
                location: location,
                venue: venue,
                venueIsGoogle: venueIsGoogle
            };

            return this.axios
                .put("/admin/listing/location", data, { headers: headers, params: parameters })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static updateListingDescription(
        listingId: number,
        teaser: string,
        content: string,
        attribution: string,
        licenseId?: number
    ): Promise<ListingFullDTO> {
        const token = getValidToken();
        if (token) {
            const headers = { Authorization: "Bearer " + token };
            const parameters = { listingId, licenseId };
            const data = {
                teaser: teaser,
                content: content,
                attribution: attribution,
            };
            return this.axios
                .put("/admin/listing/description", data, { headers: headers, params: parameters })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static updateListingDates(
        listingId: number,
        eventInfoId: number,
        form: FormDatesValues,
        eventDateRecurringID?: number,
        licenseId?: number
    ): Promise<ListingFullDTO> {
        const token = getValidToken();
        if (token) {
            const headers = { Authorization: "Bearer " + token };
            const parameters = { listingId, eventInfoId, licenseId };
            let fkEventDateType = form.fkEventDateType;
            if (fkEventDateType === 1 && form.dates?.length !== 1) {
                fkEventDateType = 3;
            }
            const body = {
                fkEventDateType: fkEventDateType,
                eventFrom: form.dtEventFrom,
                eventTo: form.dtEventTo,
                eventDates: form.dates,
                eventDateRecurringID: eventDateRecurringID,
                maxOccurences: form.maxOccurences,
                repeatFrequency: form.repeatFrequency,
                repeatInterval: form.repeatInterval,
                occurenceDays: form.occurenceDays,
                occurenceMonths: form.occurenceMonths,
                monthlyExactDay: form.monthlyExactDay,
                monthlyInterval: form.monthlyInterval,
            };
            return this.axios
                .put("/admin/listing/dates", body, { headers: headers, params: parameters })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static updateListingParent(
        listingId: number,
        parentId1?: number,
        parentId2?: number,
        parentId3?: number,
        parentId4?: number,
        parentId5?: number,
        licenseId?: number
    ): Promise<ListingFullDTO> {
        const token = getValidToken();
        if (token) {
            const headers = { Authorization: "Bearer " + token };
            const parameters = { listingId, licenseId };
            const data = {
                parentId1,
                parentId2,
                parentId3,
                parentId4,
                parentId5,
            };
            return this.axios
                .put("/admin/listing/parent", data, { headers: headers, params: parameters })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static updateListingFeatures(
        listingId: number,
        features: FormFeaturesValue[],
        licenseId?: number
    ): Promise<ListingFullDTO> {
        const token = getValidToken();
        if (token) {
            const headers = { Authorization: "Bearer " + token };
            const parameters = { listingId, licenseId };
            return this.axios
                .put("/admin/listing/feature", features, { headers: headers, params: parameters })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static createImage(
        listingId: number,
        caption: string,
        credit: string,
        file: File,
        licenseId?: number
    ): Promise<CreateImageDTO> {
        const token = getValidToken();
        if (token) {
            const params = {
                listingId,
                licenseId,
            };
            const body = {
                listingId,
                captionText: caption?.length > 0 ? caption : "",
                creditText: credit?.length > 0 ? credit : "",
                primary: false,
                filename: file.name,
            };
            const headers = {
                Authorization: "Bearer " + token,
            };
            return this.axios
                .post("/admin/listing/image", body, { headers: headers, params: params })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static updateImage(
        listingId: number,
        caption: string,
        credit: string,
        isPrimary: boolean,
        pkImage: number,
        licenseId?: number
    ): Promise<ListingFullDTO> {
        const token = getValidToken();
        if (token) {
            const params = {
                listingId,
                licenseId,
            };
            const body = {
                listingId,
                captionText: caption?.length > 0 ? caption : "",
                creditText: credit?.length > 0 ? credit : "",
                primary: isPrimary,
                imageId: pkImage,
            };
            const headers = {
                Authorization: "Bearer " + token,
            };
            return this.axios
                .post("/admin/listing/image", body, { headers: headers, params: params })
                .then((res) => res.data.listing);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static updateImageOrder(
        listingId: number,
        pkImage: number,
        moveUp: boolean,
        licenseId?: number
    ): Promise<ListingFullDTO> {
        const token = getValidToken();
        if (token) {
            const params = {
                listingId,
                imageId: pkImage,
                licenseId,
            };
            const headers = {
                Authorization: "Bearer " + token,
            };
            const body = {
                moveUp,
            };
            return this.axios
                .post("/admin/listing/image/order", body, { headers: headers, params: params })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static deleteImage(listingId: number, pkImage: number, licenseId?: number): Promise<ListingFullDTO> {
        const token = getValidToken();
        if (token) {
            const params = {
                listingId,
                imageId: pkImage,
                licenseId,
            };
            const headers = { Authorization: "Bearer " + token };
            return this.axios
                .delete("/admin/listing/image", { headers: headers, params: params })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static addUpdateVideo(
        listingId: number,
        url: string,
        pkVideo?: number,
        licenseId?: number
    ): Promise<ListingFullDTO> {
        const token = getValidToken();
        if (token) {
            const params = {
                listingId,
                videoId: pkVideo,
                videoUrl: url,
                licenseId,
            };
            const headers = { Authorization: "Bearer " + token };
            return this.axios
                .post("/admin/listing/video", null, { headers: headers, params: params })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static deleteVideo(listingId: number, pkVideo?: number, licenseId?: number): Promise<ListingFullDTO> {
        const token = getValidToken();
        if (token) {
            const params = {
                listingId,
                videoId: pkVideo,
                licenseId,
            };
            const headers = { Authorization: "Bearer " + token };
            return this.axios
                .delete("/admin/listing/video", { headers: headers, params: params })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static updateVideoPreference(
        listingId: number,
        showImagesFirst: boolean,
        licenseId?: number
    ): Promise<ListingFullDTO> {
        const token = getValidToken();
        if (token) {
            const params = {
                listingId,
                licenseId,
            };
            const body = {
                showImagesFirst: showImagesFirst
            };
            const headers = { Authorization: "Bearer " + token };
            return this.axios
                .put("/admin/listing/video-preference", body, { headers: headers, params: params })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static addFile(listingId: number, title: string, file: File, licenseId?: number): Promise<ListingFullDTO> {
        const token = getValidToken();
        if (token) {
            const params = {
                listingId,
                title: title,
                filename: file.name,
                licenseId
            };
            const headers = {
                Authorization: "Bearer " + token,
                "Content-Type": file.type,
            };
            return (file as any)
                .arrayBuffer()
                .then((res) => this.axios.post("/admin/listing/file", res, { headers: headers, params: params }))
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static updateFile(
        listingId: number,
        pkFile: number,
        title: string,
        filename: string,
        licenseId?: number
    ): Promise<ListingFullDTO> {
        const token = getValidToken();
        if (token) {
            const params = {
                listingId,
                title: title,
                filename: filename,
                fileId: pkFile,
                licenseId
            };
            const headers = {
                Authorization: "Bearer " + token,
            };
            return this.axios
                .post("/admin/listing/file", null, { headers: headers, params: params })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static deleteFile(listingId: number, pkFile: number, licenseId?: number): Promise<ListingFullDTO> {
        const token = getValidToken();
        if (token) {
            const params = {
                listingId,
                fileId: pkFile,
                licenseId
            };
            const headers = { Authorization: "Bearer " + token };
            return this.axios
                .delete("/admin/listing/file", { headers: headers, params: params })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static getMyWebsiteListings(
        licenseId: number,
        typeId?: number,
        keyword?: string,
        keywordUser?: string,
        exclusion?: ExclusionType
    ): Promise<ListingWebsiteDTO[]> {
        const token = getValidToken();
        if (token) {
            var parameters = {
                licenseId,
                listingTypeId: typeId,
                keyword,
                keywordUser,
                exclusion: exclusion?.toString(),
            };
            return this.axios
                .get("/admin/website/listings", {
                    headers: { Authorization: "Bearer " + token },
                    params: parameters,
                })
                .then((res: any) => {
                    return res.data.listings;
                });
        } else {
            return Promise.resolve([]);
        }
    }

    public static updateListingsExclusion(excluded: boolean, listingsId: number[]): Promise<any> {
        const token = getValidToken();
        if (token) {
            const headers = { Authorization: "Bearer " + token };
            const body = {
                excluded,
                listingsId,
            };
            return this.axios.put("/admin/website/exclusion", body, { headers: headers }).then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static addUpdateListingClientLevel(
        listingId: number,
        fkListingLevel: number,
        pkClientListingLevel?: number
    ): Promise<number> {
        const token = getValidToken();
        if (token) {
            const headers = { Authorization: "Bearer " + token };
            const body = {
                fkListingLevel,
                pkClientListingLevel,
            };
            const params = {
                listingId: listingId,
            };
            return this.axios
                .put("/admin/website/level", body, { headers: headers, params: params })
                .then((res) => res.data.pkClientListingLevel);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static updateListingOwner(licenseId: number, listingId: number, userId: number): Promise<ListingFullDTO> {
        const token = getValidToken();
        if (token) {
            const headers = { Authorization: "Bearer " + token };
            const data = { userId };
            var params = {
                licenseId,
                listingId,
            };
            return this.axios.put("/admin/listing/owner", data, { headers, params }).then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static getApprovalListings(licenseId: number, includeExpired: boolean): Promise<ListingApprovalDTO[]> {
        const token = getValidToken();
        if (token) {
            var parameters = {
                licenseId,
                expiredEvents: includeExpired,
            };
            return this.axios
                .get("/admin/approvals/listings", {
                    headers: { Authorization: "Bearer " + token },
                    params: parameters,
                })
                .then((res) => res.data.listings);
        } else {
            return Promise.resolve([]);
        }
    }

    public static listingChecked(licenseId: number, listingId: number): Promise<ListingApprovalDTO> {
        const token = getValidToken();

        if (token) {
            const headers = { Authorization: "Bearer " + token };
            const parameters = {
                licenseId,
                listingId,
            };
            return this.axios
                .put("/admin/approvals/check", {}, { params: parameters, headers: headers })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static updateListingApproval(
        licenseId: number,
        listingId: number,
        approved: boolean,
        sendEmail: boolean,
        paragraphs?: string[],
        template?: string
    ): Promise<ListingApprovalDTO> {
        const token = getValidToken();
        if (token) {
            const headers = { Authorization: "Bearer " + token };
            const parameters = {
                licenseId,
                listingId,
            };
            const body = {
                approved,
                sendEmail,
                paragraphs: paragraphs?.map(t => ({ text: t})),
                template,
            };
            return this.axios
                .put("/admin/approvals/approval", body, { params: parameters, headers: headers })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static addListingLevel(
        licenseId: number,
        listingId: number,
        listingLevelId: number,
        startDate: string,
        endDate: string
    ): Promise<ListingFullDTO> {
        const token = getValidToken();
        if (token) {
            const headers = { Authorization: "Bearer " + token };
            const body = {
                listingLevelId,
                startDate,
                endDate,
            };
            const parameters = {
                licenseId,
                listingId,
            };
            return this.axios
                .post("/admin/listing/level", body, { params: parameters, headers: headers })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static updateListingLevel(
        licenseId: number,
        listingLevelListingId: number,
        listingId: number,
        listingLevelId: number,
        startDate: string,
        endDate: string
    ): Promise<ListingFullDTO> {
        const token = getValidToken();
        if (token) {
            const headers = { Authorization: "Bearer " + token };
            const body = {
                listingLevelListingId,
                listingLevelId,
                startDate,
                endDate,
            };
            const parameters = {
                licenseId,
                listingId,
            };
            return this.axios
                .put("/admin/listing/level", body, { params: parameters, headers: headers })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static deleteListingLevel(
        licenseId: number,
        listingId: number,
        listingLevelListingId: number
    ): Promise<ListingFullDTO> {
        const token = getValidToken();
        if (token) {
            const headers = { Authorization: "Bearer " + token };
            const parameters = {
                licenseId,
                listingId,
                listingLevelListingId,
            };
            return this.axios
                .delete("/admin/listing/level", { params: parameters, headers: headers })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static updateDefaultListingLevel(
        licenseId: number,
        listingId: number,
        listingLevelId: number
    ): Promise<ListingFullDTO> {
        const token = getValidToken();
        if (token) {
            const headers = { Authorization: "Bearer " + token };
            const body = {
                listingLevelId,
            };
            const parameters = {
                licenseId,
                listingId,
            };
            return this.axios
                .put("/admin/listing/level/default", body, { params: parameters, headers: headers })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static sendEmailToListingOwner(
        licenseId: number,
        listingId: number,
        paragraphs: string[]
    ): Promise<Boolean> {
        const token = getValidToken();
        if (token) {
            const headers = { Authorization: "Bearer " + token };
            const body = {
                paragraphs: paragraphs.map(t => ({ text: t}))
            };
            const parameters = {
                licenseId,
                listingId,
            };
            return this.axios
                .post("/admin/listing/notification/email", body, { params: parameters, headers: headers })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static generateNewSeoNamePreview(
        listingId: number,
        licenseId?: number
    ): Promise<string> {
        const token = getValidToken();
        if (token) {
            const headers = { Authorization: "Bearer " + token };
            const parameters = {
                licenseId,
                listingId,
            };
            return this.axios
                .get("/admin/listing/seo", { params: parameters, headers: headers })
                .then((res) => res.data.seoName);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static generateNewSeoName(
        listingId: number,
        licenseId?: number
    ): Promise<ListingFullDTO> {
        const token = getValidToken();
        if (token) {
            const headers = { Authorization: "Bearer " + token };
            const parameters = {
                listingId,
                licenseId
            };
            return this.axios
                .post("/admin/listing/seo", {}, { params: parameters, headers: headers })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static updateListingSearchSitemaps(
        listingId: number,
        includeInSearch: boolean,
        includeInSitemaps: boolean,
        licenseId?: number
    ): Promise<ListingFullDTO> {
        const token = getValidToken();
        if (token) {
            const headers = { Authorization: "Bearer " + token };
            const body = {
                includeInSearch,
                includeInSitemaps
            };
            const parameters = {
                listingId,
                licenseId
            };
            return this.axios
                .put("/admin/listing/search-sitemaps", body, { params: parameters, headers: headers })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static addUpdateListingOpeningTimes(
        listingId: number,
        openingHoursId: number,
        statusId: number,
        additionalInfo: string,
        data?: OpeningHoursData,
        licenseId?: number
    ): Promise<ListingFullDTO> {
        const token = getValidToken();
        if (token) {
            const headers = { Authorization: "Bearer " + token };
            const body = {
                id: openingHoursId,
                statusId: statusId,
                additionalInfo: additionalInfo,
                data: data
            };
            const parameters = {
                listingId,
                licenseId
            };
            return this.axios
                .post("/admin/listing/opening-hours", body, { params: parameters, headers: headers })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

}

