import { Card } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { ReactNode } from 'react';

const useStyles = makeStyles((theme) => ({
    floatingCard: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1),
            padding: theme.spacing(1),
        },
    }
}));

interface Props {
    children: ReactNode;
}

const FloatingCard = (props: Props) => {
    const classes = useStyles();

    return <Card className={classes.floatingCard}>
        {props.children}
    </Card>
}

export default FloatingCard;