import { DatePicker } from "@mui/lab";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React from "react";
import DateUtil from "../../../../Util/DateUtil";

interface Props {
    disabled: boolean;
    dtEventFrom: string;
    repeatFrequency: number;
    onChange: (repeatFrequency: number, dateFrom: string) => void;
}

const RecurringDatesStart = (props: Props) => {
    const { disabled, dtEventFrom, repeatFrequency } = props;

    const onRepeatFrequencyChange = (e: SelectChangeEvent<number>) => {
        const value = e.target.value as number;
        props.onChange(value, dtEventFrom);
    };

    const onDateFromChange = (date: string) => {
        const value = DateUtil.formatDateString(new Date(date));
        props.onChange(repeatFrequency, value);
    }

    return (
        <>
            <Grid item xs={12} md={6}>
                <FormControl disabled={disabled} fullWidth>
                    <InputLabel id="select-type-label">Repeats</InputLabel>
                    <Select
                        label="Repeats"
                        value={repeatFrequency === 0 ? 2 : repeatFrequency}
                        onChange={onRepeatFrequencyChange}
                    >
                        <MenuItem value={2}>Weekly</MenuItem>
                        <MenuItem value={3}>Monthly</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
                <DatePicker
                disabled={disabled} 
                    disablePast
                    label="Date From"
                    inputFormat="dd/MM/yyyy"
                    value={dtEventFrom}
                    onChange={(newValue) => onDateFromChange(newValue)}
                    renderInput={(props) => <TextField fullWidth {...props} />}
                />
            </Grid>
        </>
    );
};

export default RecurringDatesStart;
