import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApiUsers from "../../../Api/ApiUsers";
import { UserProfileDTO } from "../../../Api/Model";
import { snackMessageErrorAction } from "../../../Store/App/actions";
import { selectMetadataPublic } from "../../../Store/App/selectors";
import { updateUserAction } from "../../../Store/User/actions";
import { selectUser } from "../../../Store/User/selectors";
import MetadataUtil, { CountryWithStates } from "../../../Util/MetadataUtil";
import ScoopUtil from "../../../Util/ScoopUtil";
import Loading from "../../UI/Loading";
import HeaderContainer from "../HeaderContainer";
import HeaderTitle from "../HeaderTitle";
import UpdatePasswordDialog from "./UpdatePasswordDialog";
import UserPremiumDetailsDialog from "./UserPremiumDetailsDialog";

const useStyles = makeStyles((theme) => ({
    formEditable: {
        backgroundColor: theme.palette.common.white,
    },
    form: {
        marginTop: "10px",
        padding: "20px 0px",
    },
    button: {
        margin: "10px",
    },
    label: {
        fontWeight: "bold",
        [theme.breakpoints.up("sm")]: {
            width: "160px",
        },
    },
    row: {
        marginBottom: "10px",
    },
}));

interface Props {}

const MyDetails = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    let authUser = useSelector(selectUser);
    let metadata = useSelector(selectMetadataPublic);
    const [countries, setCountries] = useState<CountryWithStates[]>([]);

    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [displayname, setDisplayname] = useState("");
    const [displaynameError, setDisplaynameError] = useState<string>(undefined);
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [phone, setPhone] = useState("");

    const [address, setAddress] = useState("");
    const [postcode, setPostcode] = useState("");
    const [state, setState] = useState(0);
    const [country, setCountry] = useState(0);
    const [suburb, setSuburb] = useState("");

    const [industries, setIndustries] = useState([]);

    const [disabled, setDisabled] = useState(true);

    const updateFromUser = (user?: UserProfileDTO) => {
        setEmail(user?.userName || "");
        setCompany(user?.company || "");
        setDisplayname(user?.displayName || "");
        setFirstname(user?.firstName || "");
        setLastname(user?.lastName || "");
        setPhone(user?.telephone || "");
        setAddress(user?.address || "");
        setPostcode(user?.postCode || "");
        setState(user?.stateId);
        setCountry(user?.countryId);
        setSuburb(user?.suburb || "");
        setIndustries(user?.industries?.map((i) => i.fkUserIndustryID) || []);
        setDisabled(true);
    };

    useEffect(() => {
        updateFromUser(authUser);
    }, [authUser]);

    useEffect(() => {
        if (metadata) {
            let sortedCountries = MetadataUtil.mergeCountriesAndStates(metadata);
            setCountries(sortedCountries);
        }
    }, [metadata]);

    const onCancel = () => {
        updateFromUser(authUser);
    };

    const formInvalid =
        displaynameError !== undefined ||
        displayname?.length < 1 ||
        company.length < 1 ||
        firstname.length < 1 ||
        lastname.length < 1 ||
        industries.length < 1 ||
        industries.length > 5 || 
        country < 0 ||
        state < 0;

    const onSave = async () => {
        try {
            if (!formInvalid) {
                let updated = await ApiUsers.updateUser(
                    email,
                    company,
                    displayname,
                    firstname,
                    lastname,
                    address,
                    suburb,
                    postcode,
                    country,
                    state,
                    phone,
                    industries
                );
                dispatch(updateUserAction(updated));
                setDisabled(true);
            }
        } catch (err) {
            let message = err.data || err.message || "Server error";
            if (message === "email-taken") {
                message = "This email has already been used for an existing account.";
            } else if (message === "dispalyname-taken") {
                message =
                    "This Display Name has already been used for an existing account. Please use a different Display Name.";
            }
            dispatch(snackMessageErrorAction(message));
        }
    };

    const onIndustryChange = (event: SelectChangeEvent<number[]>) => {
        let values = event.target.value as number[];
        if (values.length > 5) {
        } else {
            setIndustries(values);
        }
    };

    const onCountryChange = (event: SelectChangeEvent<number>) => {
        let value = event.target.value as number;
        setCountry(value);
        let foundState = metadata.states.find((s) => s.fkCountryID === value);
        if (foundState) {
            setState(foundState.pkStateID);
        }
    };

    useEffect(() => {
        if (displayname.length > 0 && !ScoopUtil.isDisplaynameValid(displayname)) {
            setDisplaynameError("No spaces allowed and minimum 10 characters");
        } else {
            setDisplaynameError(undefined);
        }
    }, [displayname]);

    if (!metadata) return <Loading />;

    return (
        <>
            <HeaderContainer>
                <HeaderTitle title={"My Details"} />
            </HeaderContainer>
            <Box
                className={`${classes.form} ${disabled ? "" : classes.formEditable}`}
                justifyContent="center"
                alignContent="center"
                display="flex"
            >
                <Grid container item md={6} xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            error={!!!(email?.length > 0)}
                            disabled={disabled}
                            name="email"
                            id="email"
                            label="Login/Contact Email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            disabled={disabled}
                            name="company"
                            id="company"
                            label="Company"
                            placeholder="Company"
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            required
                            disabled={disabled}
                            name="displayname"
                            id="displayname"
                            label="Display name"
                            placeholder="Display name"
                            value={displayname}
                            onChange={(e) => setDisplayname(e.target.value)}
                            variant="outlined"
                            fullWidth
                            error={displaynameError !== undefined}
                            helperText={displaynameError}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl required variant="outlined" fullWidth error={industries?.length < 1}>
                            <InputLabel id="industries">Industry</InputLabel>
                            <Select
                                disabled={disabled}
                                name="industries"
                                id="industries"
                                label="Industries"
                                value={industries}
                                multiple
                                onChange={onIndustryChange}
                                renderValue={(selected: number[]) =>
                                    metadata.industries
                                        .filter((i) => selected.includes(i.pkUserIndustryID))
                                        .map((i) => i.industryName)
                                        .join(", ")
                                }
                            >
                                {metadata?.industries.map((i) => (
                                    <MenuItem key={`ind_${i.pkUserIndustryID}`} value={i.pkUserIndustryID}>
                                        <Checkbox checked={industries.indexOf(i.pkUserIndustryID) > -1} />
                                        <ListItemText primary={i.industryName} />
                                    </MenuItem>
                                ))}
                            </Select>

                            <FormHelperText>Select up to 5 industry categories</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <TextField
                            required
                            disabled={disabled}
                            name="firstname"
                            id="firstname"
                            label="First Name"
                            placeholder="First Name"
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            required
                            disabled={disabled}
                            name="lastname"
                            id="lastname"
                            label="Last Name"
                            placeholder="Last Name"
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            disabled={disabled}
                            name="address"
                            id="address"
                            label="Address"
                            placeholder="Address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item lg={9} md={8} xs={7}>
                        <TextField
                            disabled={disabled}
                            name="suburb"
                            id="suburb"
                            label="Suburb"
                            placeholder="Suburb"
                            value={suburb}
                            onChange={(e) => setSuburb(e.target.value)}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item lg={3} md={4} xs={5}>
                        <TextField
                            disabled={disabled}
                            name="postcode"
                            id="postcode"
                            label="Postcode"
                            placeholder="Postcode"
                            value={postcode}
                            onChange={(e) => setPostcode(e.target.value)}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <FormControl required variant="outlined" fullWidth>
                            <InputLabel id="country">Country</InputLabel>
                            <Select
                                disabled={disabled}
                                name="country"
                                id="country"
                                label="Country"
                                value={country}
                                onChange={onCountryChange}
                            >
                                {countries.map((c) => (
                                    <MenuItem key={`country_${c.id}`} value={c.id}>
                                        {c.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <FormControl required variant="outlined" fullWidth>
                            <InputLabel id="state">State</InputLabel>
                            <Select
                                disabled={disabled}
                                name="state"
                                id="state"
                                label="State"
                                value={state || 1}
                                onChange={(e) => setState(e.target.value as number)}
                            >
                                {metadata?.states
                                    ?.filter((s) => s.fkCountryID === country)
                                    .map((s) => (
                                        <MenuItem key={`state_${s.pkStateID}`} value={s.pkStateID}>
                                            {s.stateName}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            disabled={disabled}
                            name="phone"
                            id="phone"
                            label="Phone"
                            placeholder="Phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    {authUser?.clientId > 0 && (
                        <Grid item xs={12}>
                            <Typography variant="h6">License details</Typography>
                            <Line label="LICENSE GROUP" value={`${authUser.clientName} (${authUser.clientId})`} />
                            {authUser?.approvalLicenses?.length > 0 &&
                                authUser.approvalLicenses.map((l) => (
                                    <Line label="Approval" value={`${l.txtLicenseName} (${l.pkLicense})`} />
                                ))}
                            {authUser?.contentLicenses?.length > 0 &&
                                authUser.contentLicenses.map((l) => (
                                    <Line label="Content" value={`${l.txtLicenseName} (${l.pkLicense})`} />
                                ))}
                        </Grid>
                    )}
                    <Grid alignItems="center" justifyContent="center" item container xs={12}>
                        {disabled && (
                            <Box>
                                {authUser?.userLevel === 2 && <UserPremiumDetailsDialog />}
                                <UpdatePasswordDialog />
                                <Button
                                    color="primary"
                                    className={classes.button}
                                    variant="contained"
                                    onClick={() => setDisabled(false)}
                                >
                                    Edit
                                </Button>
                            </Box>
                        )}
                        {!!!disabled && (
                            <Box>
                                <Button
                                    color="primary"
                                    className={classes.button}
                                    variant="contained"
                                    onClick={onCancel}
                                >
                                    Cancel
                                </Button>
                                <Button disabled={formInvalid} color="primary" className={classes.button} variant="contained" onClick={onSave}>
                                    Save
                                </Button>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default MyDetails;

const Line = (props: { label: string; value: string | number }): JSX.Element => {
    const classes = useStyles();
    return (
        <Box className={classes.row} display="flex">
            <div className={classes.label}>{props.label}:</div>
            {props.value}
        </Box>
    );
};
