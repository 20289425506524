export const actionTypes = {
    SNACK_MESSAGE_UPDATED: 'APP/SNACK_MESSAGE_UPDATED',
    ERRORS_UPDATED: 'APP/ERRORS_UPDATED',

    FETCH_LISTING_METADATA: 'LISTINGS/FETCH_METADATA',
    FETCH_LISTING_METADATA_SUCCESS: 'LISTINGS/FETCH_METADATA_SUCCESS',
    FETCH_LISTING_METADATA_REGIONS_SUCCESS: 'LISTINGS/FETCH_METADATA_REGIONS_SUCCESS',
    FETCH_LISTING_METADATA_ERROR: 'LISTING/FETCH_METADATA_ERROR',
    
    FETCH_USERS_AUTOCOMPLETE: 'APP/FETCH_USERS_AUTOCOMPLETE',
    FETCH_USERS_AUTOCOMPLETE_SUCCESS: 'APP/FETCH_USERS_AUTOCOMPLETE_SUCCESS',
    FETCH_USERS_AUTOCOMPLETE_ERROR: 'APP/FETCH_USERS_AUTOCOMPLETE_ERROR',
    USERS_AUTOCOMPLETE_ADD: 'APP/USERS_AUTOCOMPLETE_ADD',
    
    FETCH_PUBLIC_METADATA: 'APP/FETCH_PUBLIC_METADATA',
    FETCH_PUBLIC_METADATA_SUCCESS: 'APP/FETCH_PUBLIC_METADATA_SUCCESS',
    FETCH_PUBLIC_METADATA_ERROR: 'APP/FETCH_PUBLIC_METADATA_ERROR',

    FETCH_ADMIN_METADATA: 'APP/FETCH_ADMIN_METADATA',
    FETCH_ADMIN_METADATA_SUCCESS: 'APP/FETCH_ADMIN_METADATA_SUCCESS',
    FETCH_ADMIN_METADATA_ERROR: 'APP/FETCH_ADMIN_METADATA_ERROR',

};