import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApiListing from "../../../../Api/ApiListing";
import { ListingApprovalDTO, UserAutocompleteDTO } from "../../../../Api/Model";
import { selectUsersAutocomplete } from "../../../../Store/App/selectors";
import { approvalsListingUpdatedAction } from "../../../../Store/Dashboard/actions";
import { updateListingSuccessAction } from "../../../../Store/Wizard/actions";
import Loading from "../../../UI/Loading";
import LoadingDialog from "../../../UI/LoadingDialog";

interface Props {
    licenseId: number;
    listing: ListingApprovalDTO;
}

const UsersFilter = (props: Props) => {
    const dispatch = useDispatch();

    const { listing, licenseId } = props;
    const [selected, setSelected] = useState<UserAutocompleteDTO>(null);
    const users = useSelector(selectUsersAutocomplete);
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        if (users) {
            setSelected(users.find((u) => u.id === listing.listingUserID) || null);
        } else {
            setSelected(null);
        }
    }, [listing, users]);

    useEffect(() => {
        if (selected !== null && listing.listingUserID && selected.id !== listing.listingUserID) {
            const onOwnerChanged = async (pkUser: number) => {
                try {
                    setProcessing(true);
                    let res = await ApiListing.updateListingOwner(licenseId, listing.listingID, pkUser);
                    dispatch(updateListingSuccessAction(res));
                    if(res.approval) {
                        dispatch(approvalsListingUpdatedAction(res.approval));
                    }
                } catch (err) {
                    //todo
                } finally {
                    setProcessing(false);
                }
            };
            onOwnerChanged(selected.id)
        }
    }, [dispatch, licenseId, listing, selected]);

    if (!!!users) {
        return <Loading text="Loading list of users" />;
    }

    return (
        <>
            <Autocomplete
                disabled={!!!listing?.allowListingEdit}
                disableClearable
                fullWidth
                onChange={(event, input: UserAutocompleteDTO | null) => {
                    setSelected(input);
                }}
                value={selected ? selected : null}
                options={users ? users : []}
                getOptionLabel={(option) => `${option.label} (${option.id})`}
                renderInput={(params) => (
                    <TextField fullWidth variant="outlined" name="user" {...params} label="Listing owner" />
                )}
            />
            <LoadingDialog open={processing} text={"Processing update"} />
        </>
    );
};

export default UsersFilter;
