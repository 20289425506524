
// https://github.com/mui-org/material-ui/issues/13394
import { unstable_createMuiStrictModeTheme as createMuiTheme, adaptV4Theme } from '@mui/material/styles';


export const theme = createMuiTheme(adaptV4Theme({
    palette: {
        primary: {
            main: '#667479',
            contrastText: '#ffffff',
            dark: '#B9B9B9',
            light: '#77b4cc'
        },
        background: {
            default: '#E0E0E0',
            paper: '#ffffff'
        },
        secondary: {
            main: "#F32013"
        },
    },
    // breakpoints: {
    //     values: {
    //         mobile: 0,
    //         tablet: 890,
    //         laptop: 1024,
    //         desktop: 1450

    //     },
    //   },
}));
