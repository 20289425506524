import ApiUsers from "../../Api/ApiUsers";
import { UserDTO } from "../../Api/Model";
import { FilterValues } from "../../Components/Dashboard/Users/Filters";
import { TableSetting } from "../../Components/UI/EnhancedTable";
import { actionAddUserToAutocomplete } from "../App/actions";
import { actionTypes as at } from "./constants";

export const actionFetchUsers = () => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: at.USERS_FETCH,
                payload: {},
            });
            const users = await ApiUsers.getUsers();
            dispatch({
                type: at.USERS_FETCH_SUCCESS,
                payload: users,
            });
        } catch (err) {
            dispatch({
                type: at.USERS_FETCH_ERROR,
                payload: err.toString()
            });
        }
    };
};

export const actionUsersTableSettingUpdated = (setting: TableSetting) => {
    return {
        type: at.USERS_TABLE_SETTING_UPDATED,
        payload: setting,
    };
};

export const actionUsersFilterUpdated = (filter: FilterValues) => {
    return {
        type: at.USERS_FILTER_UPDATED,
        payload: filter,
    };
};

export const actionUserDeleted = (userId: number) => {
    return {
        type: at.USERS_USER_DELETED,
        payload: userId,
    };
};

export const actionUserUpdated = (user: UserDTO) => {
    return {
        type: at.USERS_USER_UPDATED,
        payload: user,
    };
};

export const actionUserCreated = (user: UserDTO) => {
    return async (dispatch, getState) => {
        // add created user into list of users
        dispatch({
            type: at.USERS_USER_CREATED,
            payload: user,
        })
        //add created user into user autocomplete list
        dispatch(actionAddUserToAutocomplete(user))
    }
};

export const actionUserSelected = (user: UserDTO) => {
    return {
        type: at.USERS_USER_SELECTED,
        payload: user
    };
};

export const actionUserDeSelected = () => {
    return {
        type: at.USERS_USER_DESELECTED,
        payload: {}
    };
};