import { VariantType } from 'notistack';
import { ServerError } from "../../Api/Api";
import ApiMetadata from '../../Api/ApiMetadata';
import ApiUsers from "../../Api/ApiUsers";
import { MetadataAdminDTO, MetadataDTO, MetadataPublicDTO, Region, RegionDTO, UserAutocompleteDTO, UserDTO } from '../../Api/Model';
import { actionTypes as at } from './constants';

export const errorsUpdatedAction = (errors: string[]) => {
    return {
        type: at.ERRORS_UPDATED,
        payload: {
            errors
        }
    }
}

export const snackMessageAction = (text: string, variant?: VariantType, autoHideDuration?: number) => {
    return {
        type: at.SNACK_MESSAGE_UPDATED,
        payload: {
            snackMessage: {
                text,
                variant,
                autoHideDuration
            }
        }
    }
}

export const snackMessageErrorAction = (text: string) => {
    return {
        type: at.SNACK_MESSAGE_UPDATED,
        payload: {
            snackMessage: {
                text: text,
                variant: 'error'
            }
        }
    }
}

export const snackMessageCaughtErrorAction = (error: any) => {
    return {
        type: at.SNACK_MESSAGE_UPDATED,
        payload: {
            snackMessage: {
                text: error.data || error.message || error || 'Unknown error',
                variant: 'error'
            }
        }
    }
}

export const serverErrorAction = (serverError: ServerError, errorsTitle?: string, errorsDescription?: string) => {
    let errors = [serverError.message]
    if (serverError.errors) {
        errors = serverError.errors
    }
    return {
        type: at.ERRORS_UPDATED,
        payload: {
            errors,
            errorsTitle,
            errorsDescription
        }
    }
}

export const listingValidationErrorAction = (errors: string[]) => {
    return {
        type: at.ERRORS_UPDATED,
        payload: {
            errors,
            errorsTitle: 'Your listing is missing content',
            errorsDescription: 'Before your listing can be activated and sent for approval, please update the following.'
        }
    }
}

export const resetErrors = () => {
    return {
        type: at.ERRORS_UPDATED,
        payload: {
            errors: undefined,
            errorsTitle: undefined,
            errorsDescription: undefined
        }
    }
}

export const fetchMetadataPublicAction = () => {
    return async (dispatch, getState) => {
        try {
            const metadata: MetadataPublicDTO = await ApiMetadata.getPublicMetadata();
            dispatch({
                type: at.FETCH_PUBLIC_METADATA_SUCCESS,
                payload: {
                    metadata: metadata
                }
            });
        } catch (err) {
            dispatch({
                type: at.FETCH_PUBLIC_METADATA_ERROR,
                payload: {
                    error: err
                }
            })
        }
    }
};

export const fetchMetadataAction = () => {
    return async (dispatch, getState) => {
        try {
            const metadata: MetadataDTO = await ApiMetadata.getMetadata();
            dispatch({
                type: at.FETCH_LISTING_METADATA_SUCCESS,
                payload: {
                    types: metadata.types,
                    directories: metadata.directories,
                    levels: metadata.levels,
                    socialLinks: metadata.socialLinks
                }
            });

            const regionsRaw: RegionDTO[] = await ApiMetadata.getMetadataRegions();
            let lookup = new Map<number, RegionDTO>(
                regionsRaw.map(r => [r.pkRegion, r])
            );
            let regions = regionsRaw.map(r => {
                let { parents, parentsNames } = findParentsRecursive(lookup, 0, r.fkParentRegion)
                let region: Region = {
                    ...r,
                    parents,
                    parentsNames
                };
                return region
            })
            dispatch({
                type: at.FETCH_LISTING_METADATA_REGIONS_SUCCESS,
                payload: {
                    regions
                }
            });
        } catch (err) {
            dispatch(fetchMetadataErrorAction(err))
        }
    }
};

//limit number of steps in case there is loop between regions coming from database. There is currently 7 levels so limit it to 10 should be safe for the future too.
const findParentsRecursive = (lookup: Map<number, RegionDTO>, steps: number, fkParent?: number): { parents: number[], parentsNames: string[] } => {
    steps++;
    let parent = fkParent ? lookup.get(fkParent) : null;
    if (parent) {
        if(steps < 10) {
            let res = findParentsRecursive(lookup, steps, parent.fkParentRegion);
            let name = parent.txtAbbreviation?.length > 0 ? parent.txtAbbreviation : parent.txtRegionName;
            //temp hack
            if(name === 'perth-wa'){
                name = 'WA'
            }
    
            return { parents: [parent.pkRegion, ...res.parents], parentsNames: [name, ...res.parentsNames] };
        } else {
            return { parents: [], parentsNames: [] };
        }
    } else {
        return { parents: [], parentsNames: [] };
    }
}

export const fetchMetadataErrorAction = (error: Error) => {
    return {
        type: at.FETCH_LISTING_METADATA_ERROR,
        payload: {
            error: error
        }
    }
};


export const fetchUsersAction = () => {
    return async (dispatch, getState) => {
        try {
            const users = await ApiUsers.getUsersAutocomplete();
            dispatch({
                type: at.FETCH_USERS_AUTOCOMPLETE_SUCCESS,
                payload: {
                    users
                }
            })
        } catch (err) {
            dispatch({
                type: at.FETCH_USERS_AUTOCOMPLETE_ERROR,
                payload: {
                    error: err
                }
            })
        }
    }
};

export const actionAddUserToAutocomplete = (user: UserDTO) => {
    let autocomplete : UserAutocompleteDTO = {
        id: user.id,
        label: user.displayName
    }
    return {
        type: at.USERS_AUTOCOMPLETE_ADD,
        payload: autocomplete
    }
}

export const fetchMetadataAdminAction = () => {
    return async (dispatch, getState) => {
        try {
            const metadata: MetadataAdminDTO = await ApiMetadata.getAdminMetadata();
            dispatch({
                type: at.FETCH_ADMIN_METADATA_SUCCESS,
                payload: {
                    metadata: metadata
                }
            });
        } catch (err) {
            dispatch({
                type: at.FETCH_ADMIN_METADATA_ERROR,
                payload: {
                    error: err
                }
            })
        }
    }
};