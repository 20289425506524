import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { ReactNode } from 'react';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        padding: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2),
        }
    }
}));

interface Props {
    children: ReactNode;
}

const FormGrid = (props: Props) => {
    const classes = useStyles();

    return (
        <Grid className={classes.formContainer} item container>
            { props.children}
        </Grid >
    )
}

export default FormGrid;