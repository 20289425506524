import React from 'react';
import { useDispatch } from 'react-redux';
import { updateWizardStepAction } from '../../../../Store/Wizard/actions';
import WizardGuide from '../../WizardGuide';
import WizardHeader from '../../WizardHeader';
import WizardMain from '../../WizardMain';
import WizardSidebar from '../../WizardSidebar';
import { WizardStep } from '../../WizardSteps';
import BackForwardButtons from '../BackForwardButtons';
import VideosForm from './VideosForm';
interface Props {

}
const VideosStep = (props: Props) => {
    const dispatch = useDispatch();

    const onWizardStepChange = (step: WizardStep) => {
        dispatch(updateWizardStepAction(step));
    }

    const onSaveHook = (): Promise<any> => {
        return undefined;
    }

    return (<>
        <WizardHeader
            disabled={false}
            backToListingsHook={onSaveHook}
        />
        <WizardSidebar
            onSaveHook={onSaveHook}
        />
        <WizardMain>
            <VideosForm />
            <BackForwardButtons
                onClickPrevious={() => onWizardStepChange(WizardStep.Images)}
            />
        </WizardMain>
        <WizardGuide step={WizardStep.Videos} />
    </>

    )
};

export default VideosStep;