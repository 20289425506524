import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetErrors } from '../../Store/App/actions';
import { selectErrors, selectErrorsDescription, selectErrorsTitle } from '../../Store/App/selectors';


interface Props {
}

const ErrorsDialog = (props: Props) => {
  const dispatch = useDispatch();
  const errors = useSelector(selectErrors);
  const title = useSelector(selectErrorsTitle);
  const description = useSelector(selectErrorsDescription);

  const onClose = () => {
    dispatch(resetErrors());
  }

  const mapError = (err: string): string => {
    switch (err) {
      case 'validation:location':
        return 'Location tab: no listing location has been selected.'
      case 'validation:location:position':
        return 'Location tab: the map pin has not been set'
      case 'validation:description':
        return 'Description tab: no teaser/overview has been entered.'
      case 'validation:images':
        return 'Images tab: no primary image has been selected.'
      case 'validation:dates':
        return 'Event date tab: no date(s) have been entered.'
      default:
        return err;
    }
  }

  return (<>
    {errors && <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title ? title : 'Something went wrong'}</DialogTitle>
      <DialogContent id="alert-dialog-description">
        <Grid container spacing={1}>
          {description && <Grid item xs={12}>{description}</Grid>}
          {errors?.map((error, idx) => <Grid key={`error_${idx}`} item xs={12}>
            <Alert severity="error">
              {mapError(error)}
            </Alert>
          </Grid>
          )}
        </Grid>

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          OK
          </Button>
      </DialogActions>
    </Dialog>}
  </>
  );
}

export default ErrorsDialog;