import { Button } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ServerError } from "../../Api/Api";
import ApiListing from "../../Api/ApiListing";
import { ListingFullDTO } from "../../Api/Model";
import { listingValidationErrorAction } from "../../Store/App/actions";
import { approvalsListingUpdatedAction, listingUpdatedFromWizardAction } from "../../Store/Dashboard/actions";
import { resetListingAction, updateListingSuccessAction } from "../../Store/Wizard/actions";
import { selectListing, selectListingModified } from "../../Store/Wizard/selectors";
import AlertDialog from "../UI/AlertDialog";
import LoadingDialog from "../UI/LoadingDialog";

interface Props {
    buttonText?: string;
    disabled: boolean;
    onSaveHook: () => Promise<ListingFullDTO>;
    size?: "small" | "medium" | "large";
    fullWidth?: boolean;
}

const ReturnButton = (props: Props) => {
    let params = useParams<{ id: any; licenseId: any }>();

    const { disabled, buttonText, size, fullWidth } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const listing = useSelector(selectListing);
    const modified = useSelector(selectListingModified);
    const [updating, setUpdating] = useState(false);

    const [showAlert, setShowAlert] = useState(false);

    const navigateBack = () => {
        if (params.licenseId) {
            history.push("/approvals");
        } else {
            history.push("");
        }
        dispatch(resetListingAction());
    };

    const handleBackToListings = async () => {
        if(listing === undefined) navigateBack();
        setUpdating(true);
        try {
            let promise = props.onSaveHook();
            let isVisible = listing.details.isVisible;
            console.log('handleBackToListings: ', isVisible, promise)
            //execute listing update promise and update isVisible after update
            if (promise !== undefined) {
                let updated = await promise;
                dispatch(listingUpdatedFromWizardAction(updated));
                dispatch(updateListingSuccessAction(updated));
                isVisible = updated.details.isVisible;
                console.log('handleBackToListings:promiseExecuted: ', isVisible, updated)
            }
            if (isVisible) {
                console.log('handleBackToListings:navigateBack: ', isVisible)
                navigateBack();
            } else {
                console.log('handleBackToListings:showAlert: ', isVisible)
                setShowAlert(true);
            }
        } catch (err) {
            //todo do something with this error
        } finally {
            setUpdating(false);
        }
    };

    const onBackClick = () => {
        handleBackToListings();
    };

    const onAlertConfirmed = async () => {
        try {
            let res = await ApiListing.updateListingVisibility(listing.details.pkListing, true, params.licenseId);
            if (listing.approval) {
                let copy = { ...listing.approval };
                copy.isVisible = res.details.isVisible;
                dispatch(approvalsListingUpdatedAction(copy));
            }
            dispatch(listingUpdatedFromWizardAction(res));
            navigateBack();
        } catch (err) {
            dispatch(listingValidationErrorAction((err as ServerError)?.errors));
        }
    };

    const onAlertClosed = () => {
        navigateBack();
    };

    return (
        <>
            <Button
                disabled={disabled}
                onClick={onBackClick}
                color="primary"
                size={size}
                variant="contained"
                fullWidth={fullWidth}
            >
                {buttonText ? buttonText : modified ? "SAVE & RETURN" : "RETURN"}
            </Button>

            {updating && <LoadingDialog text={"Saving changes"} />}
            {showAlert && (
                <AlertDialog
                    message={
                        "Your listing is currently inactive. To send your listing to localista for go-live approval, select MAKE LISTING ACTIVE below."
                    }
                    confirmText={"MAKE LISTING ACTIVE"}
                    closeText={"KEEP LISTING INACTIVE"}
                    onConfirm={onAlertConfirmed}
                    onClose={onAlertClosed}
                />
            )}
        </>
    );
};

export default ReturnButton;
