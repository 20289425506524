import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import React from "react";
import { DayHours, HoursPeriod } from "../../../../Api/Model";
import DateUtil from "../../../../Util/DateUtil";
import { format } from "date-fns";

interface Props {
  label: string;
  day: number;
  disabled: boolean;
  value: DayHours;
  onChange: (value: DayHours) => void;
  publicHoliday?: boolean;
}

const formatOpeningHoursTime = (time: string): string => {
  const date = DateUtil.parseTime(time);
  return format(date, "h:mm aaa");
};

export const formatDayText = (
  closed: boolean,
  open24Hours: boolean,
  periods?: HoursPeriod[],
  vary?: boolean
): string => {
  if (closed) {
    return "Closed";
  }
  if (vary) {
    return "Hours may vary";
  }
  if (open24Hours) {
    return "Open 24 hours";
  }
  return periods
    ?.map(
      (p) =>
        `${formatOpeningHoursTime(p.open.time)} - ${formatOpeningHoursTime(
          p.close.time
        )}`
    )
    .join(" | ");
};

const DayHoursInput = (props: Props) => {
  const { label, value, day, publicHoliday } = props;
  const { closed, day_text, open_24_hours, periods, vary } = value;

  const onUpdate = (updated: DayHours) => {
    if (
      !updated.closed &&
      !updated.open_24_hours &&
      !updated.vary &&
      (!!!updated?.periods || updated?.periods?.length === 0)
    ) {
      updated.periods = [
        {
          open: { time: "9:00", day: day },
          close: { time: "17:00", day: day },
        },
      ];
    }
    updated.day_text = `${label}: ${formatDayText(
      updated.closed,
      updated.open_24_hours,
      updated.periods,
      updated.vary
    )}`;
    props.onChange(updated);
  };

  const onPeriodUpdate = (updated: HoursPeriod, index: number) => {
    const copy = { ...value, periods: [...periods] };
    copy.periods[index] = updated;
    onUpdate(copy);
  };

  const onAddNew = () => {
    const copy = { ...value, periods: [...periods] };
    copy.periods.push({
      open: { time: "9:00", day: day },
      close: { time: "17:00", day: day },
    });
    onUpdate(copy);
  };

  const onDelete = (index: number) => {
    const copy = { ...value, periods: [...periods] };
    copy.periods.splice(index, 1);
    onUpdate(copy);
  };

  const onClosedChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    let checked = e.target.checked;
    console.log("checked", checked);
    onUpdate({ ...value, closed: checked });
  };

  const onOpen24HoursChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    let checked = e.target.checked;
    onUpdate({ ...value, open_24_hours: checked, vary: false });
  };

  const onVaryChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    let checked = e.target.checked;
    onUpdate({ ...value, vary: checked, open_24_hours: false });
  };

  return (
    <Grid container item xs={12}>
      <Grid item xs={12} style={{ marginBottom: "10px" }}>
        <Typography fontWeight={600}>{label}</Typography>
        <Typography variant="caption" color="textSecondary">
          {day_text}
        </Typography>
      </Grid>
      <Grid item container xs={6}>
        <Grid item xs={6}>
          <FormControl variant="outlined">
            <FormControlLabel
              control={
                <Checkbox
                  checked={closed}
                  color="primary"
                  onChange={onClosedChanged}
                  name={"closed"}
                />
              }
              label={"Closed"}
            />
          </FormControl>
        </Grid>
        {!closed && <Grid item xs={6}>
          <FormControl variant="outlined">
            <FormControlLabel
              control={
                <Checkbox
                  checked={open_24_hours}
                  color="primary"
                  onChange={onOpen24HoursChanged}
                  name={"open24hours"}
                />
              }
              label={"Open 24 hours"}
            />
          </FormControl>
        </Grid>}

        {publicHoliday && !closed && (
          <Grid item xs={6}>
            <FormControl variant="outlined">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={vary || false}
                    color="primary"
                    onChange={onVaryChanged}
                    name={"hoursMayVary"}
                  />
                }
                label={"Hours may vary"}
              />
            </FormControl>
          </Grid>
        )}
      </Grid>
      {!closed && !open_24_hours &&!!!vary && (
        <Grid item container xs={6} spacing={1}>
          {periods?.map((period, index) => (
            <PeriodInput
              key={index}
              period={period}
              day={day}
              onUpdate={(p) => onPeriodUpdate(p, index)}
              first={index === 0}
              onAddNew={onAddNew}
              onDelete={() => onDelete(index)}
            />
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default DayHoursInput;

const TIMES_OPTIONS = [
  "0:00",
  "0:30",
  "1:00",
  "1:30",
  "2:00",
  "2:30",
  "3:00",
  "3:30",
  "4:00",
  "4:30",
  "5:00",
  "5:30",
  "6:00",
  "6:30",
  "7:00",
  "7:30",
  "8:00",
  "8:30",
  "9:00",
  "9:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

const PeriodInput = (props: {
  period: HoursPeriod;
  day: number;
  onUpdate: (updated: HoursPeriod) => void;
  first?: boolean;
  onAddNew: () => void;
  onDelete: () => void;
}) => {
  const { period, day, onUpdate, first, onAddNew, onDelete } = props;
  return (
    <Box style={{ display: "flex", marginTop: "10px", gap: "5px" }}>
      <FormControl variant="outlined" style={{ width: "120px" }}>
        <InputLabel id="industries">Opens at</InputLabel>
        <Select
          name="open"
          id="open"
          label="Opens at"
          value={period.open.time}
          onChange={(e) => {
            onUpdate({
              ...period,
              open: { time: e.target.value, day: day },
            });
          }}
        >
          {TIMES_OPTIONS.map((v) => (
            <MenuItem key={v} value={v}>
              {v}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" style={{ width: "120px" }}>
        <InputLabel id="industries">Closes at</InputLabel>
        <Select
          name="close"
          id="close"
          label="Closes at"
          value={period.close.time}
          onChange={(e) => {
            onUpdate({
              ...period,
              close: { time: e.target.value, day: day },
            });
          }}
        >
          {TIMES_OPTIONS.map((v) => (
            <MenuItem key={v} value={v}>
              {v}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <IconButton onClick={first ? onAddNew : onDelete}>
        {first ? <AddOutlinedIcon /> : <DeleteForeverOutlinedIcon />}
      </IconButton>
    </Box>
  );
};
