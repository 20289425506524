import { Box, TableFooter } from "@mui/material";
import { Theme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { PerformanceDTO } from "../../../Api/Model";
import EnhancedTable, { HeadCell, TableSetting } from "../../UI/EnhancedTable";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            "& thead th": {
                fontSize: "15px",
                fontWeight: "700"
            },
            "& tfoot td": {
                fontSize: "15px",
                fontWeight: "700",
                padding: "15px"
            },
        },
        footerCell: {
            backgroundColor: "#F1F6F1",
        },
        footerCellAll: {
            backgroundColor: "#EDF6FB",
        },
        cell: {
            backgroundColor: "#F1F6F1",
        },
        cellAll: {
            backgroundColor: "#EDF6FB",
        },
        cellListingName: {
            cursor: "pointer",
            fontWeight: "bold",
        },
        cellActive: {
            cursor: "pointer",
        },
        head: {},
        headCell: {
            backgroundColor: "#F1F6F1",
            fontWeight: "700",
        },
        headCellAll: {
            backgroundColor: "#EDF6FB",
            fontWeight: "700",
        },
    })
);

interface Props {
    performance: PerformanceDTO[];
}

interface TableItem {
    label: string;
    timestamp: number;
    searches: number;
    views: number;
    opens: number;
    clicks: number;
    other: number;
}

const TABLE_SETTING: TableSetting = {
    page: 0,
    pageSize: 12,
    order: "desc",
    orderBy: "timestamp",
};

const MonthlyTable = (props: Props) => {
    const classes = useStyles();
    const { performance } = props;
    const [data, setData] = useState<TableItem[]>([]);
    const [tableSetting, setTableSetting] = useState(TABLE_SETTING);

    useEffect(() => {
        let mapped = performance.map((p) => {
            const date = new Date(p.year, p.month - 1, 1);
            return {
                label: format(date, "MMM yyyy"),
                timestamp: date.getTime(),
                searches: p.searches,
                views: p.views.listing,
                opens: p.opens.listing,
                clicks: p.clicks.listing,
                other: p.other.listing,
            };
        });

        setData(mapped);
    }, [performance]);

    const mapRow = (item: TableItem, index: number): JSX.Element => {
        return (
            <TableRow hover tabIndex={-1} key={item.label}>
                <TableCell key={item.timestamp}>{item.label}</TableCell>
                <TableCell align="center" className={`${classes.cellAll}`}>
                    {item.searches?.toLocaleString() || '-'}
                </TableCell>
                <TableCell></TableCell>
                <TableCell align="center" className={`${classes.cell}`}>
                    {item.views?.toLocaleString() || '-'}
                </TableCell>
                <TableCell align="center" className={`${classes.cell}`}>
                    {item.opens?.toLocaleString() || '-'}
                </TableCell>
                <TableCell align="center" className={`${classes.cell}`}>
                    {item.clicks?.toLocaleString() || '-'}
                </TableCell>
                <TableCell align="center" className={`${classes.cell}`}>
                    {item.other?.toLocaleString() || '-'}
                </TableCell>
            </TableRow>
        );
    };

    const mapFooter = (data: TableItem[]): JSX.Element => {
        return (
            <TableFooter>
                <TableCell>Total</TableCell>
                <TableCell align="center" className={classes.footerCellAll}>
                    {data.reduce((sum, current) => sum + current.searches, 0)?.toLocaleString() || '-'}
                </TableCell>
                <TableCell></TableCell>
                <TableCell align="center" className={classes.footerCell}>
                    {data.reduce((sum, current) => sum + current.views, 0)?.toLocaleString() || '-'}
                </TableCell>
                <TableCell align="center" className={classes.footerCell}>
                    {data.reduce((sum, current) => sum + current.opens, 0)?.toLocaleString() || '-'}
                </TableCell>
                <TableCell align="center" className={classes.footerCell}>
                    {data.reduce((sum, current) => sum + current.clicks, 0)?.toLocaleString() || '-'}
                </TableCell>
                <TableCell align="center" className={classes.footerCell}>
                    {data.reduce((sum, current) => sum + current.other, 0)?.toLocaleString() || '-'}
                </TableCell>
            </TableFooter>
        );
    };

    const headCells: HeadCell[] = [
        {
            id: "label",
            sortId: "timestamp",
            sortable: true,
            disablePadding: false,
            align: "left",
            label: "Month",
        },
        {
            id: "searches",
            sortable: true,
            disablePadding: false,
            label: "Total Searches",
            subLabel: "(All listings)",
            className: classes.headCellAll,
        },
        { id: "", disablePadding: false, label: "" },
        {
            id: "views",
            sortable: true,
            disablePadding: false,
            label: "Views",
            subLabel: "(Your listing)",
            className: classes.headCell,
        },
        {
            id: "opens",
            sortable: true,
            disablePadding: false,
            label: "Opens",
            subLabel: "(Your listing)",
            className: classes.headCell,
        },
        {
            id: "clicks",
            sortable: true,
            disablePadding: false,
            label: "Clicks",
            subLabel: "(Your listing)",
            className: classes.headCell,
        },
        {
            id: "other",
            sortable: true,
            disablePadding: false,
            label: "Other",
            subLabel: "(Your listing)",
            className: classes.headCell,
        },
    ];

    return (
        <Box className={classes.wrapper}>
            <EnhancedTable
                headCells={headCells}
                rows={data}
                mapRow={mapRow}
                tableSetting={tableSetting}
                onTableSettingChange={setTableSetting}
                hidePaginationTop={true}
                hidePaginationBottom={true}
                mapFooter={mapFooter}
            />
        </Box>
    );
};

export default MonthlyTable;
