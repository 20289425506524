import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ClickAwayListener, Link } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ApiListing from "../../../Api/ApiListing";
import { ServerError } from "../../../Api/Api";
import { ListingDTO } from '../../../Api/Model';
import { listingValidationErrorAction } from '../../../Store/App/actions';
import { fetchMyListingsSuccessAction, listingsTableSettingUpdatedAction, listingUpdatedAction } from '../../../Store/Dashboard/actions';
import { selectListingsTableSetting } from '../../../Store/Dashboard/selectors';
import DateUtil from '../../../Util/DateUtil';
import ScoopUtil from '../../../Util/ScoopUtil';
import EnhancedTable, { HeadCell, TableSetting } from '../../UI/EnhancedTable';
import LoadingDialog from '../../UI/LoadingDialog';
import ScoopStatusIcon from '../ScoopStatusIcon';
import ListingsActionButton from './ListingsActionButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cell: {
            padding: '0px 10px'
        },
        cellListingName: {
            cursor: 'pointer',
            fontWeight: 'bold'
        },
        cellActive: {
            cursor: 'pointer'
        },
    }),
);

interface Props {
    list: ListingDTO[];
}

const ListingsDataTable = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { list } = props;
    const [updating, setUpdating] = useState(false);
    const [opened, setOpened] = useState<number>(undefined);
    const tableSetting = useSelector(selectListingsTableSetting);
    const setTableSetting = (setting: TableSetting) => dispatch(listingsTableSettingUpdatedAction(setting));

    const onEdit = async (listing: ListingDTO) => {
        history.push('/wizard/' + listing.pkListing)
    }

    const handleActiveClick = (listing: ListingDTO) => {
        setUpdating(true)
        ApiListing.updateListingVisibility(listing.pkListing, !!!listing.isVisible).then(res => {
            let updated = list.map(l => {
                if (l.pkListing === listing.pkListing) {
                    l.isVisible = res.details.isVisible;
                }
                return l;

            })
            dispatch(fetchMyListingsSuccessAction(updated));
        }).catch(err => {
            dispatch(listingValidationErrorAction((err as ServerError)?.errors));
        }).finally(() => {
            setUpdating(false)
        })
    };

    const onListingUpdated = (listing: ListingDTO) => {
        let updated = list.map(l => {
            if (l.pkListing === listing.pkListing) {
                l.isVisible = listing.isVisible;
                l.isApproved = listing.isApproved;
            }
            return l;

        })
        dispatch(fetchMyListingsSuccessAction(updated));
    }

    let headCells: HeadCell[] = [
        { id: 'txtListingName', sortable: true, disablePadding: false, label: 'Listing Name', minWidth: 160 },
        { id: 'txtListingTypeName', sortable: true, disablePadding: false, label: 'Type' },
        { id: 'dtLastUpdated', sortable: true, disablePadding: false, label: 'Last Updated' },
        { id: 'defaultListingLevel', sortable: false, disablePadding: false, label: 'Default Level' },
        { id: 'premiumListingLevel', sortable: false, disablePadding: false, label: 'Active Level' },
        { id: 'isVisible', disablePadding: false, label: 'User Activated' },
        { id: 'approved', disablePadding: false, label: 'localista Approved' },
        { id: 'view', disablePadding: false, label: 'View' },
        { id: 'actions', disablePadding: false, label: 'Actions' }
    ];

    const onPreviewClick = async (pkListing: number) => {
        try {
            let dto = await ApiListing.listingReviewed(pkListing);
            dispatch(listingUpdatedAction(dto));
        } catch (err) {

        }
    }

    const mapRow = (listing: ListingDTO, index: number): JSX.Element => {

        return (
            <TableRow hover tabIndex={-1} key={listing.pkListing}>
                <TableCell onClick={() => onEdit(listing)} className={`${classes.cell} ${classes.cellListingName}`}>
                    <Link style={{ color: 'blue' }}>{listing.txtListingName}</Link>
                </TableCell>
                <TableCell className={`${classes.cell}`} >{listing.txtListingTypeName}</TableCell>
                <TableCell padding='none' align='center' className={`${classes.cell}`}>{DateUtil.formatDateFromString(listing.dtLastUpdated)}</TableCell>
                <TableCell align="center" className={`${classes.cell}`}>{listing.defaultDisplayPriority}</TableCell>
                <TableCell align="center" className={`${classes.cell}`}>{listing.premiumDisplayPriority}</TableCell>
                <TableCell className={`${classes.cell} ${classes.cellActive}`} align="center" onClick={() => handleActiveClick(listing)}>{listing.isVisible ? <CheckIcon htmlColor="green" /> : <ClearIcon htmlColor="red" />}</TableCell>
                <TableCell align="center" className={`${classes.cell}`}><ScoopStatusIcon status={ScoopUtil.scoopStatusFromListing(listing)} /></TableCell>
                <TableCell align="center" className={`${classes.cell}`}>
                    <IconButton
                        onClick={() => onPreviewClick(listing.pkListing)}
                        href={ScoopUtil.generatePreviewLink(listing.previewUrl)}
                        target="_blank"
                        color="primary"
                        size="large">
                        <VisibilityIcon />
                    </IconButton>
                </TableCell>
                <TableCell className={`${classes.cell}`}>
                    <ListingsActionButton opened={opened} onOpen={setOpened} listing={listing} onListingUpdated={onListingUpdated} />
                </TableCell>
            </TableRow>
        );
    }

    const handleClickAway = () => {
        if (opened) setOpened(undefined)
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <EnhancedTable
                    headCells={headCells}
                    rows={list || []}
                    mapRow={mapRow}
                    tableSetting={tableSetting}
                    onTableSettingChange={setTableSetting}
                />
                {updating && <LoadingDialog text="Updating listing" />}

            </div>
        </ClickAwayListener>
    );
}

export default ListingsDataTable;