import { LatLng, LatLngBounds } from 'leaflet';
import Config from "../config";
const axios = require('axios').default;


export interface GeocoderResult {
	location: LatLng;
	bounds: LatLngBounds;
}

export interface PlaceDetails {
    name: string;
    formattedAddress: string;
    location: LatLng;
    country: string;
    postCode: string;
    suburb: string;
    city: string;
    adminAreaLevel1: string;
    adminAreaLevel2: string;
    locality: string;
    sublocality: string;
    route: string;
    streetNumber: string;
    subPremise: string;
}

export interface MainTextMatchedSubstrings {
    offset: number;
    length: number;
}
export interface StructuredFormatting {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}

export interface PlacePrediction {
    description: string;
    structured_formatting: StructuredFormatting;
    place_id: string;
}

const PROXY_ADDRESS = 'https://api-proxy.localista.com.au'

const PLACE_DETAILS_FIELDS = 'address_component,geometry,formatted_address,type,name'

// CAUTION!
// Google places only works server side as they want you to use their own maps places search
// Removing the proxy will result in a CORS error
export default class GooglePlacesAPI {

    public static findPlaceFromText(address: string): Promise<GeocoderResult> {

		const url = `${PROXY_ADDRESS}/google-place-from-text?input=${encodeURI(address)}&inputtype=textquery&fields=geometry,formatted_address,types&key=${Config.GOOGLE_API_KEY}`

        return axios.get(url).then((result: any) => {
            let status = result.data.status;
            if("OK" === status) {
                return result.data.candidates;
            } 
            throw Error(status);
        }).then((candidates: any) => {
            const address = candidates[0];
            const latLng = new LatLng(address.geometry.location.lat, address.geometry.location.lng);
            const northEast = new LatLng(address.geometry.viewport.northeast.lat, address.geometry.viewport.northeast.lng);
            const southWest = new LatLng(address.geometry.viewport.southwest.lat, address.geometry.viewport.southwest.lng);
            const latLngBounds = new LatLngBounds(northEast, southWest);

            const geocoderResult: GeocoderResult = {
                location: latLng,
                bounds: latLngBounds
            };
            return geocoderResult;
        })
    }

    public static placeDetails(placeId: string): Promise<PlaceDetails> {

		const url = `${PROXY_ADDRESS}/google-place-details?place_id=${placeId}&fields=${PLACE_DETAILS_FIELDS}&key=${Config.GOOGLE_API_KEY}`

        return axios.get(url).then((result: any) => {
            let status = result.data.status;
            if("OK" === status) {
                return result.data.result;
            }
            throw Error(status);
        }).then((data: any) => {
            console.log('GooglePlacesAPI:data: ', data);
            let arr : any[] = data.address_components;
            console.log('GooglePlacesAPI:arr: ', arr);
            let locality = arr.find(a => a.types.includes("locality"))?.short_name || '';
            let sublocality = arr.find(a => a.types.includes("sublocality"))?.short_name || '';
            let country = arr.find(a => a.types.includes("country"))?.short_name || '';
            let adminLAreaLevel2 = arr.find(a => a.types.includes("administrative_area_level_2"))?.short_name || '';
            let res : PlaceDetails = {
                name: data.name,
                formattedAddress: data.formatted_address,
                location: new LatLng(data.geometry.location.lat, data.geometry.location.lat),
                country: country,
                postCode: arr.find(a => a.types.includes("postal_code"))?.short_name || '',
                adminAreaLevel1: arr.find(a => a.types.includes("administrative_area_level_1"))?.short_name || '',
                adminAreaLevel2: adminLAreaLevel2,
                locality: locality,
                sublocality: sublocality,
                route: arr.find(a => a.types.includes("route"))?.short_name || '',
                streetNumber: arr.find(a => a.types.includes("street_number"))?.short_name || '',
                subPremise: arr.find(a => a.types.includes("subpremise"))?.short_name || '',
                suburb: country === 'NZ' ? sublocality : locality,
                city: country === 'NZ' ? locality : ' '
            }
            return res;
        })
    }

    public static autocomplete(input: string, location?: LatLng, radius?: number, countryCode?: string): Promise<PlacePrediction[]> {

		let locationParam = ""
        if(location && radius) {
            locationParam = `&location=${location.lat},${location.lng}&radius=${radius}`
        }
        const url = `${PROXY_ADDRESS}/google-autocomplete?input=${input}${locationParam}&key=${Config.GOOGLE_API_KEY}`

        return axios.get(url).then((result: any) => {
            let status = result.data.status;
            if("OK" === status) {
                return result.data.predictions;
            }
            throw Error(status);
        })
    }

}