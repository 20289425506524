import { DateRange, StaticDateRangePicker } from '@mui/lab';
import { Alert, Box, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import React from 'react';
import DateUtil from '../../../../Util/DateUtil';
import { FormDatesValues } from '../form-values';
import SectionGrid from '../SectionGrid';

interface Props {
    disabled: boolean;
    values: FormDatesValues;
    onChange: (values: FormDatesValues) => void;
}

const DateRangeForm = (props: Props) => {
    const { disabled, values, onChange } = props;

    const onValueUpdate = (value: DateRange<Date>) => {
        let form : FormDatesValues = {
            pickerType: 1,
            fkEventDateType: 2,
            dates: []
        }
        if (value[0] !== null && value[1] !== null) {
            form.dtEventFrom = `${DateUtil.formatDateString(value[0])}T00:00:00`;
            form.dtEventTo = `${DateUtil.formatDateString(value[1])}T00:00:00`;
        }
        onChange(form);
    }

    return (
        <>
            <SectionGrid title='Step 2: Select dates'>
                <StaticDateRangePicker
                    disabled={disabled}
                    disablePast
                    displayStaticWrapperAs="desktop"
                    calendars={1}
                    value={[new Date(values.dtEventFrom), new Date(values.dtEventTo)]}
                    onChange={onValueUpdate}
                    renderInput={(startProps, endProps) => (
                        <React.Fragment>
                            <TextField {...startProps} />
                            to
                            <TextField {...endProps} />
                        </React.Fragment>
                    )}
                />
            </SectionGrid>
            <SectionGrid title={'Selected dates'}>
                <Grid item xs={12}>
                    {(values.dtEventFrom && values.dtEventTo) ?
                        <Box>{`${DateUtil.formatDateFromString(values.dtEventFrom)} to ${DateUtil.formatDateFromString(values.dtEventTo)}`}</Box>
                        :
                        <Alert severity="info">Date range has not been selected.</Alert>
                    }
                </Grid>

            </SectionGrid>
        </>
    )
};

export default DateRangeForm;