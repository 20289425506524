import { TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApiListing from "../../../Api/ApiListing";
import { snackMessageCaughtErrorAction } from "../../../Store/App/actions";
import { selectUser } from "../../../Store/User/selectors";
import ScoopUtil from "../../../Util/ScoopUtil";
import { ListingApproval } from "./Approvals";

interface Props {
    listing: ListingApproval;
    licenseId: number;
    onClose: () => void;
}

const SendEmailToListingOwnerDialog = (props: Props) => {
    const { listing, licenseId, onClose } = props;

    const dispatch = useDispatch();
    const authUser = useSelector(selectUser);

    const adminFirstName = authUser.firstName;
    const adminLastName = authUser.lastName;
    const adminEmail = authUser.userName;
    const companyName = authUser.company;

    const listingLink = ScoopUtil.generatePreviewLink(listing.listingPreviewURL);
    const listingName = listing.listingName;
    let userFirstName = listing.listingUserFullname;
    if (userFirstName && userFirstName.includes(" ")) {
        userFirstName = userFirstName.split(" ")[0];
    }

    const [text, setText] = useState<string>("");

    const handleClose = () => {
        onClose();
    };

    const handleSend = async () => {
        try {
            await ApiListing.sendEmailToListingOwner(licenseId, listing.listingID, text.split(/\n/));
            onClose();
        } catch (err) {
            dispatch(snackMessageCaughtErrorAction(err));
        }
    };

    return (
        <Dialog
            open={true}
            fullWidth
            maxWidth="md"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Email Message"}</DialogTitle>
            <DialogContent>
                <Typography marginBottom={2}>
                    This email will be sent from noreply@localista.com.au to the listing-owners email-login.
                </Typography>
                <Typography fontSize={18} marginBottom={2}>
                    EMAIL TEXT
                </Typography>
                <Typography>
                    LOGIN: https://admin.localista.com.au
                </Typography>
                <Typography>LOGIN EMAIL: (listing-owner email address)</Typography>
                <Typography marginTop={1}>
                    Hi {userFirstName?.length > 0 ? userFirstName : "(user name is empty)"}
                </Typography>
                <Typography marginBottom={1}>
                    I am touching base about your{" "}
                    <a href={listingLink} target="_blank" rel="noreferrer">
                        {listingName}
                    </a>{" "}
                    listing on localista.{" "}
                </Typography>
                <TextField
                    fullWidth
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    multiline
                    minRows={3}
                    maxRows={15}
                />
                <Typography marginTop={1}>If you have any queries, you can contact me at {adminEmail}.</Typography>
                <Typography marginTop={1}>Regards</Typography>
                <Typography marginTop={1}>
                    {adminFirstName} {adminLastName}
                </Typography>
                <Typography>{companyName}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button disabled={text.length < 1} onClick={handleSend} color="primary">
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SendEmailToListingOwnerDialog;
