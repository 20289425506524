import { Button, InputAdornment, List, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import MovieIcon from '@mui/icons-material/Movie';
import { Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApiListing from '../../../../Api/ApiListing';
import { updateListingSuccessAction } from '../../../../Store/Wizard/actions';
import { selectAllowEdit, selectListing } from '../../../../Store/Wizard/selectors';
import ScoopUtil from '../../../../Util/ScoopUtil';
import LoadingDialog from '../../../UI/LoadingDialog';
import FormGrid from '../FormGrid';
import SectionGrid from '../SectionGrid';
import VideoItem from './VideoItem';
import { useParams } from 'react-router-dom';

interface Props {
}

const VideosForm = (props: Props) => {
    const dispatch = useDispatch();
    let params = useParams<{ id: any; licenseId: any }>();

    const listing = useSelector(selectListing);
    const allowEdit = useSelector(selectAllowEdit);

    const [videoLink, setVideoLink] = useState<string>('');
    const [error, setError] = useState(false);
    const [updating, setUpdating] = useState(false);
    
    const onSave = () => {
        console.log('params', params);
        if (!!!error && videoLink?.length > 0) {
            setError(false);
            setUpdating(true);
            ApiListing.addUpdateVideo(listing?.details.pkListing, videoLink, undefined, params.licenseId)
                .then(res => {
                    dispatch(updateListingSuccessAction(res));
                    setVideoLink('');
                }).finally(() => {
                    setUpdating(false);
                })
        }
    }

    useEffect(() => {
        if ((ScoopUtil.isUrlValidOrEmpty(videoLink, ['youtube.com']) || ScoopUtil.isUrlValidOrEmpty(videoLink, ['vimeo.com']))) {
            setError(false);
        } else {
            setError(true);
        }
    }, [videoLink])

    const onDelete = (pkVideo) => {
        setUpdating(true);
        ApiListing.deleteVideo(listing?.details.pkListing, pkVideo, params.licenseId)
            .then(res => {
                dispatch(updateListingSuccessAction(res));
            }).finally(() => {
                setUpdating(false);
            })
    }

    return <>
        <FormGrid>
            <SectionGrid title={'Videos'}>
                <Grid item xs={12}>
                    {listing && listing.videos.length === 0 && <Grid item xs={12}><Alert severity="info">No video was attached</Alert></Grid>}
                    <List dense>
                        {listing && listing.videos.map(v =>
                            <VideoItem disabled={!allowEdit} videoUrl={v.videoURL} onDelete={() => onDelete(v.pkListingVideo)} />
                        )}
                    </List>
                </Grid>
            </SectionGrid>

            {allowEdit && <SectionGrid title={'Add new video link'}>
                <Grid item xs={12}>
                    <TextField
                        error={error}
                        helperText={error ? 'Must have "youtube" or "vimeo" in link' : null}
                        fullWidth
                        label="Link to video"
                        placeholder="https://www.youtube.com/watch?v=xxxxx"
                        variant="outlined"
                        value={videoLink}
                        onChange={(e) => setVideoLink(e.target.value as string)}
                        name="video-link"
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><MovieIcon color="disabled" /></InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item xs={12} container justifyContent="center" >
                    <Button color="primary" disabled={videoLink?.length === 0 || error} variant="contained" onClick={onSave}>Add</Button>
                </Grid>

            </SectionGrid>}
        </FormGrid>
        {updating && <LoadingDialog text={'Updating listing'} />}
    </>;
};

export default VideosForm;