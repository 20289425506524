import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListingTypeDTO } from '../../../Api/Model';
import { listingsFilterUpdatedAction } from '../../../Store/Dashboard/actions';
import { selectListingsFilter } from '../../../Store/Dashboard/selectors';

export interface FilterValues {
    keyword: string;
    typeId: number;
    includeExpired: boolean;
}

interface Props {
    types: ListingTypeDTO[];
}

const Filters = (props: Props) => {
    const dispatch = useDispatch();
    const { types } = props;
    const theme = useTheme();
    const widthXS = useMediaQuery(theme.breakpoints.down('sm'));
    const filterValues = useSelector(selectListingsFilter);
    const { keyword, typeId, includeExpired } = filterValues;


    const onKeywordUpdated = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(listingsFilterUpdatedAction({
            ...filterValues,
            keyword: e.target.value
        }));
    }

    const onTypeUpdated = (e: SelectChangeEvent<Number>) => {
        dispatch(listingsFilterUpdatedAction({
            ...filterValues,
            typeId: e.target.value as any as number
        }));
    }

    const onExpiredUpdated = (e: SelectChangeEvent<Number>) => {
        let value = e.target.value as any as number;
        dispatch(listingsFilterUpdatedAction({
            ...filterValues,
            includeExpired: value > 0 ? true : false
        }));
    }

    return (
        <>
            <Grid item sm={6} xs={12}>
                <TextField
                    variant="outlined"
                    label="Search keyword"
                    placeholder="Keyword"
                    value={keyword}
                    fullWidth
                    onChange={onKeywordUpdated}
                />
            </Grid>

            <Grid item sm={4} xs={8}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel id="select-type-label">Listing Type</InputLabel>
                    <Select
                        label="Select type"
                        value={typeId}
                        onChange={onTypeUpdated}
                    >
                        <MenuItem key={`type_unselected`} value={0}>All</MenuItem>
                        {types?.map(type =>
                            <MenuItem key={`type_${type.pkListingType}`} value={type.pkListingType}>{type.txtTypeName}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item sm={2} xs={4}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel id="select-type-label">{widthXS ? 'Expired' : 'Expired Events'}</InputLabel>
                    <Select
                        label={widthXS ? 'Expired' : 'Expired Events'}
                        value={includeExpired ? 1 : 0}
                        onChange={onExpiredUpdated}
                    >
                        <MenuItem key={`no`} value={0}>No</MenuItem>
                        <MenuItem key={`yes`} value={1}>Yes</MenuItem>
                    </Select>
                </FormControl>
            </Grid>


        </>
    )
};

export default Filters;