import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApiUsers from "../../Api/ApiUsers";
import { selectMetadataPublic } from "../../Store/App/selectors";
import { loginSuccess } from "../../Store/User/actions";
import ScoopUtil from "../../Util/ScoopUtil";
import MetadataUtil, { CountryWithStates } from "../../Util/MetadataUtil";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
        alignContent: "center",
    },
    textfield: {},
    registerButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        margin: "0 auto",
        display: "block",
    },
    loginMessage: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    loginLink: {
        textDecoration: "underline",
        marginLeft: theme.spacing(1),
    },
}));

interface RegisterProps {
    onClickLogin: () => void;
    onClickForgotPassword: () => void;
}

const Register = (props: RegisterProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    let metadata = useSelector(selectMetadataPublic);

    const [countries, setCountries] = useState<CountryWithStates[]>([]);

    const [registerLoading, setRegisterLoading] = useState(false);

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState<string>(undefined);
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [termsChecked, setTermsChecked] = useState(false);
    const [termsError, setTermsError] = useState(false);
    const [company, setCompany] = useState("");
    const [displayname, setDisplayname] = useState("");
    const [displaynameError, setDisplaynameError] = useState<string>(undefined);
    const [country, setCountry] = useState<number>(-1);
    const [state, setState] = useState(-1);
    const [industries, setIndustries] = useState<number[]>([]);

    const [error, setError] = useState<string>(undefined);

    useEffect(() => {
        if(metadata) {
            let sortedCountries = MetadataUtil.mergeCountriesAndStates(metadata);
            setCountries(sortedCountries);
        }
    }, [metadata]);


    useEffect(() => {
        if (email.length > 0 && !ScoopUtil.isEmailValid(email)) {
            setEmailError("Email is not valid");
        } else {
            setEmailError(undefined);
        }
    }, [email]);

    useEffect(() => {
        if (displayname.length > 0 && !ScoopUtil.isDisplaynameValid(displayname)) {
            setDisplaynameError("No spaces allowed and minimum 10 characters");
        } else {
            setDisplaynameError(undefined);
        }
    }, [displayname]);

    const formInvalid =
        !!!termsChecked ||
        emailError !== undefined ||
        displaynameError !== undefined ||
        displayname?.length < 1 ||
        password.length < 6 ||
        company.length < 1 ||
        firstName.length < 1 ||
        lastName.length < 1 ||
        industries.length < 1 ||
        industries.length > 5 || 
        country < 0 ||
        state < 0;

    const onRegisterClick = () => {
        if (formInvalid) {
            setTermsError(true);
            return;
        }
        setRegisterLoading(true);
        ApiUsers.register(email, password, firstName, lastName, company, displayname, industries, country, state)
            .then((dto) => {
                dispatch(loginSuccess(dto));
            })
            .catch((err) => {
                let message = err.data || err.message || 'Server error';
                if(message === 'email-taken') {
                    message = 'This email has already been used for an existing account. To claim this account, select Recover Password below.'
                } else if (message === 'dispalyname-taken') {
                    message = 'This Display Name has already been used for an existing account. Please use a different Display Name.'
                }
                setError(message);
            })
            .finally(() => setRegisterLoading(false));
    };

    const onIndustryChange = (event: SelectChangeEvent<number[]>) => {
        let values = event.target.value as number[];
        if (values.length > 5) {
        } else {
            setIndustries(values);
        }
    };

    const onCountryChange = (event: SelectChangeEvent<number>) => {
        let value = event.target.value as number;
        setCountry(value);
        if(value !== country) {
            setState(-1);
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography align="center" color="textPrimary" paragraph>
                    Register
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    disabled={registerLoading}
                    className={classes.textfield}
                    value={email}
                    onChange={(e) => setEmail(e.target.value as string)}
                    id="email"
                    label="Email"
                    variant="outlined"
                    error={emailError !== undefined}
                    helperText={emailError}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    disabled={registerLoading}
                    className={classes.textfield}
                    value={password}
                    type="password"
                    onChange={(e) => setPassword(e.target.value as string)}
                    id="password"
                    label="Password"
                    variant="outlined"
                    error={password.length > 0 && password.length < 6}
                    helperText={password.length > 0 && password.length < 6 ? "At least 6 characters" : undefined}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    disabled={registerLoading}
                    name="company"
                    id="company"
                    label="Company"
                    placeholder="Company"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    variant="outlined"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    disabled={registerLoading}
                    name="displayname"
                    id="displayname"
                    label="Display name"
                    placeholder="Display name"
                    value={displayname}
                    onChange={(e) => setDisplayname(e.target.value)}
                    variant="outlined"
                    fullWidth
                    error={displaynameError !== undefined}
                    helperText={displaynameError}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    disabled={registerLoading}
                    className={classes.textfield}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value as string)}
                    name="given-name"
                    id="given-name"
                    autoComplete="given-name"
                    label="First Name"
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    disabled={registerLoading}
                    className={classes.textfield}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value as string)}
                    name="family-name"
                    id="family-name"
                    label="Last Name"
                    variant="outlined"
                />
            </Grid>

            <Grid item xs={12}>
                <FormControl required variant="outlined" fullWidth>
                    <InputLabel id="industries">Industry</InputLabel>
                    <Select
                        disabled={registerLoading}
                        name="industries"
                        id="industries"
                        label="Industries"
                        value={industries}
                        multiple
                        onChange={onIndustryChange}
                        renderValue={(selected: number[]) =>
                            metadata.industries
                                .filter((i) => selected.includes(i.pkUserIndustryID))
                                .map((i) => i.industryName)
                                .join(", ")
                        }
                    >
                        {metadata?.industries.map((i) => (
                            <MenuItem key={`ind_${i.pkUserIndustryID}`} value={i.pkUserIndustryID}>
                                <Checkbox checked={industries.indexOf(i.pkUserIndustryID) > -1} />
                                <ListItemText primary={i.industryName} />
                            </MenuItem>
                        ))}
                    </Select>

                    <FormHelperText>Select up to 5 industry categories</FormHelperText>
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <FormControl required variant="outlined" fullWidth>
                    <InputLabel id="country">Country</InputLabel>
                    <Select
                        disabled={registerLoading}
                        name="country"
                        id="country"
                        label="Country"
                        value={country}
                        onChange={onCountryChange}
                    >
                        {countries.map((c) => (
                            <MenuItem key={`country_${c.id}`} value={c.id}>
                                {c.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <FormControl required variant="outlined" fullWidth>
                    <InputLabel id="state">State</InputLabel>
                    <Select
                        disabled={registerLoading || country < 0}
                        name="state"
                        id="state"
                        label="State"
                        value={state}
                        onChange={(e) => setState(e.target.value as number)}
                    >
                        {metadata?.states.filter(s => s.fkCountryID === country).map((s) => (
                            <MenuItem key={`state_${s.pkStateID}`} value={s.pkStateID}>
                                {s.stateName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <FormControl fullWidth required error={termsError} className={classes.textfield}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={termsChecked}
                                    color="primary"
                                    onChange={() => setTermsChecked(!termsChecked)}
                                    name="checkboxA"
                                />
                            }
                            label={
                                <FormLabel component="legend">
                                    I agree to the{" "}
                                    <a
                                        href="https://scoop.com.au/wp-content/uploads/2019/01/Scoop-Digital-Terms-and-Conditions.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Terms and conditions
                                    </a>
                                </FormLabel>
                            }
                        />
                    </FormGroup>
                    {<FormHelperText>You need to agree with terms</FormHelperText>}
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                {error ? (
                    <Typography align="center" color="error">
                        {error}
                    </Typography>
                ) : null}

                <Button
                    disabled={registerLoading || formInvalid}
                    size="large"
                    variant="contained"
                    color="primary"
                    className={classes.registerButton}
                    onClick={onRegisterClick}
                >
                    {registerLoading ? (
                        <CircularProgress size={20} />
                    ) : (
                        <Typography variant="button">Register</Typography>
                    )}
                </Button>

                <div className={classes.loginMessage}>
                    <Typography noWrap variant="subtitle2">
                        Already have an account?
                    </Typography>
                    <ButtonBase disableRipple={true} onClick={props.onClickLogin}>
                        <Typography className={classes.loginLink} noWrap variant="subtitle2">
                            Login
                        </Typography>
                    </ButtonBase>
                </div>
                <div className={classes.loginMessage}>
                        <Typography noWrap variant="subtitle2">Forgot your password?</Typography>
                        <ButtonBase disableRipple={true} onClick={props.onClickForgotPassword}>
                            <Typography className={classes.loginLink} noWrap variant="subtitle2">
                                Recover Password
                    </Typography>
                        </ButtonBase>
                    </div>
            </Grid>
        </Grid>
    );
};

export default Register;
