import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect, useState } from "react";
import ApiUsers from "../../../Api/ApiUsers";
import { ClientDetailsDTO, UserProfileDTO } from "../../../Api/Model";
import { deepEqual } from "../../../Util/Util";
import Loading from "../../UI/Loading";
import UserForm, { isUserFormValuesValid, mapProfileToUserFormValues, UserFormValues } from "../../UI/UserForm";

interface Props {
    open: boolean;
    profile?: UserProfileDTO;
    onSave: (formValues: UserFormValues) => void;
    onClose: () => void;
}

const AddUpdateUserDialog = (props: Props) => {
    const { open, profile } = props;

    const [origValues, setOrigValues] = useState<UserFormValues>(undefined);
    const [values, setValues] = useState<UserFormValues>(undefined);
    const [isValid, setIsValid] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const [client, setClient] = useState<ClientDetailsDTO>(undefined);

    const resetValues = () => {
        let v = mapProfileToUserFormValues();
        setOrigValues(v);
        setValues(v);
    }

    useEffect(() => {
        let v = mapProfileToUserFormValues(profile);
        setOrigValues(v);
        setValues(v);
    }, [profile]);

    useEffect(() => {
        if (values && origValues) {
            setIsValid(isUserFormValuesValid(values));
            setHasChanged(origValues === undefined || !deepEqual(values, origValues));
        }
    }, [values, origValues]);

    const handleClose = () => {
        props.onClose();
        resetValues();
    };

    const handleSave = () => {
        props.onSave(values);
        resetValues();
    };

    const onValuesChange = (values: UserFormValues) => {
        setValues(values);
    };

    useEffect(() => {
        if (values?.clientId > 0) {
            const fetchClient = async () => {
                try {
                    let c = await ApiUsers.getClientDetails(values.clientId);
                    setClient(c);
                } catch (err) {
                    setClient(undefined);
                }
            };
            fetchClient();
        } else {
            setClient(undefined);
        }
    }, [values?.clientId]);

    return (
        <Dialog
            open={open}
            maxWidth="md"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{profile ? "Update user" : "Create user"}</DialogTitle>
            <DialogContent>
                {!!!values ? (
                    <Loading text="Loading user details" />
                ) : (
                    <UserForm
                        newUser={profile === undefined}
                        isAdmin
                        values={values}
                        user={profile}
                        licenses={client?.licenses}
                        onChange={onValuesChange}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
                <Button disabled={!hasChanged || !isValid} onClick={handleSave} color="primary">
                    Save changes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddUpdateUserDialog;
