import { Alert, Button, Checkbox, FormControlLabel, InputLabel, MenuItem, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import makeStyles from "@mui/styles/makeStyles";
import { LatLng, LatLngBounds } from "leaflet";
import React, { useEffect, useRef, useState } from "react";
import { LayersControl, Map, Marker, TileLayer } from "react-leaflet";
import GooglePlacesAPI, { PlaceDetails } from "../../../../Api/GooglePlacesAPI";
import { ListingTypeDTO, Region, StateDTO } from "../../../../Api/Model";
import Config from "../../../../config";
import ScoopUtil from "../../../../Util/ScoopUtil";
import InfoDialog from "../../../UI/InfoDialog";
import Loading from "../../../UI/Loading";
import RegionAutocomplete from "../../../UI/RegionAutocomplete";
import RegionDropdown from "../../../UI/RegionDropdown";
import AutocompleteGooglePlaces from "../../../UI/AutocompleteGooglePlaces";
import { FormLocationValues } from "../form-values";
import FormGrid from "../FormGrid";
import SectionGrid from "../SectionGrid";
import { WORD_LIMIT_ERROR_DIRECTIONS } from "./LocationStep";

const { BaseLayer } = LayersControl;

const useStyles = makeStyles((theme) => ({
    map: {
        minHeight: "500px",
        width: "100%",
        height: "100%",
    },
    mapLoadingContainer: {
        background: theme.palette.grey[300],
        minHeight: "500px",
        width: "100%",
        height: "100%",
        padding: theme.spacing(12),
        textAlign: "center",
    },
}));

const formatStreetAddress = (place?: PlaceDetails): string | undefined => {
    if (place?.route) {
        let res = place.route;
        if (place.streetNumber) res = `${place.streetNumber} ${res}`;
        if (place.subPremise) res = `${place.subPremise} / ${res}`;
        return res;
    }
    return undefined;
};

const PERTH_POSITION = new LatLng(-31.9505, 115.8605);
const AUSTRALIA_BOUNDS = new LatLngBounds(
    new LatLng(-43.6345972634, 113.338953078),
    new LatLng(-10.6681857235, 153.569469029)
);

interface Props {
    values: FormLocationValues;
    disabled: boolean;
    regions: Region[];
    type: ListingTypeDTO;
    states: StateDTO[];
    onChange: (values: FormLocationValues) => void;
}

const LocationForm = (props: Props) => {
    const classes = useStyles();
    const { disabled, regions, onChange, values, type, states } = props;
    const {
        streetAddress,
        postcode,
        suburb,
        city,
        location,
        regionId,
        latitude,
        longitude,
        stateId,
        noMapProfile,
        noMapSearch,
        venue,
        venueIsGoogle,
    } = values;

    const mapRef = useRef(null);

    let resetAutocomplete = null;

    const [selectedRegion, setSelectedRegion] = useState<Region>(undefined);
    const [markerPosition, setMarkerPosition] = useState<LatLng>(undefined);

    const [geocoderInProgress, setGeocoderInProgress] = useState(false);
    const [geocoderError, setGeocoderError] = useState<string>(undefined);
    const [alerMessage, setAlertMessage] = useState<string>(undefined);
    const [directionsError, setDirectionsError] = useState(false);
    const [directionsHelp, setDirectionsHelp] = useState<string>(undefined)

    useEffect(() => {
        var wordCount = ScoopUtil.wordCount(location);
        if(wordCount > WORD_LIMIT_ERROR_DIRECTIONS) {
            setDirectionsError(true);
            setDirectionsHelp(`${wordCount - WORD_LIMIT_ERROR_DIRECTIONS} words over the allowed limit`);
        } else {
            setDirectionsError(false);
            setDirectionsHelp(`${WORD_LIMIT_ERROR_DIRECTIONS - wordCount + 1} words left`);
        }

    }, [location])

    useEffect(() => {
        if(regionId && type && regions && selectedRegion === undefined) {
            let region = regions.find((t) => t.pkRegion === regionId && (type.allowLocations || t.isSuburb)) || null;
            setSelectedRegion(region);
        }
    }, [regionId, selectedRegion, type, regions]);

    useEffect(() => {
        if (markerPosition === undefined && latitude && longitude) {
            setMarkerPosition(new LatLng(latitude, longitude));
        }
    }, [markerPosition, latitude, longitude]);

    useEffect(() => {
        if (markerPosition && mapRef.current) {
            flyTo(markerPosition);
        }
    }, [markerPosition]);

    const onRegionChanged = (region?: Region) => {
        if (region?.pkRegion !== selectedRegion?.pkRegion) {
            setSelectedRegion(region);
            onChange({ ...values, regionId: region.pkRegion });
            if (resetAutocomplete) resetAutocomplete();
        }
    };

    const updatePositionFromRegion = () => {
        if (selectedRegion) {
            if (selectedRegion.latitude && selectedRegion.longitude) {
                let location: LatLng = new LatLng(selectedRegion.latitude, selectedRegion.longitude);
                setMarkerPosition(location);
                onChange({ ...values, latitude: location.lat, longitude: location.lng });
                flyTo(location);
            } else {
                updatePosition(`${selectedRegion.txtRegionName} ${selectedRegion.parentsNames?.join("  ")}`);
            }
        } else {
            setAlertMessage("Cannot position the map pin because the Town/Suburb is blank");
        }
    };

    const updatePositionFromAddress = () => {
        let selectedState = states.find((s) => s.pkStateID === stateId);
        updatePosition(`${venue} ${streetAddress} ${suburb} ${postcode} ${selectedState?.stateName || ""}`);
    };

    const updatePositionFromAddressClick = () => {
        let address = `${venue} ${streetAddress} ${suburb} ${postcode}`.trim();
        if (address.length !== 0) {
            updatePositionFromAddress();
        } else {
            setAlertMessage("Cannot position the map pin because the Address is blank");
        }
    };

    const updatePosition = async (fullAddress: string) => {
        setGeocoderInProgress(true);
        let pos = PERTH_POSITION;
        try {
            let res = await GooglePlacesAPI.findPlaceFromText(fullAddress);
            pos = res.location;
            setGeocoderError(null);
        } catch (err) {
            setGeocoderError(
                "We were unable to locate that address on the map - please locate the pin using the buttons below or by dragging the pin manually"
            );
        } finally {
            setGeocoderInProgress(false);
        }
        setMarkerPosition(pos);
        flyTo(pos);
        onChange({ ...values, latitude: pos.lat, longitude: pos.lng });
    };

    const onLatChange = (lat: number) => {
        onLatLngChange(new LatLng(lat, longitude));
    };

    const onLngChange = (lng: number) => {
        onLatLngChange(new LatLng(latitude, lng));
    };

    const onLatLngChange = (pos: LatLng) => {
        setMarkerPosition(pos);
        panTo(pos);
        onChange({ ...values, latitude: pos.lat, longitude: pos.lng });
    };

    const flyTo = (latlng: LatLng) => {
        mapRef.current.leafletElement.flyTo(latlng, 14);
    };

    const panTo = (latlng: LatLng) => {
        mapRef.current.leafletElement.panTo(latlng);
    };

    const onMarkerMoved = (pos: LatLng) => {
        if (geocoderError) setGeocoderError(undefined);
        setMarkerPosition(pos);
        onChange({ ...values, latitude: pos.lat, longitude: pos.lng });
    };

    const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const copy = { ...values };
        copy[e.target.id] = e.target.value;
        onChange(copy);
    };

    const onStateChange = (id: number) => {
        onChange({ ...values, stateId: id });
    };

    const onGooglePlaceChange = (place?: PlaceDetails) => {
        console.debug("onGooglePlaceChange: ", place);
        const state = states.find((s) => s.stateAbbr.toUpperCase() === place?.adminAreaLevel1);
        const copy = {
            ...values,
            postcode: place?.postCode || "",
            suburb: place?.suburb || "",
            city: place?.city || "",
            streetAddress: formatStreetAddress(place) || "",
            venue: place?.name || "",
            stateId: state?.pkStateID || 0,
        };
        onChange(copy);
    };

    const onVenueIsGoolgeChange = (checked: boolean) => {
        const copy = { ...values, venueIsGoogle: checked };
        onChange(copy);
        if (resetAutocomplete) resetAutocomplete();
    };

    const venueLabel = type.allowEvents ? "Venue" : "Location";

    const onlySuburbs = !type.allowLocations;

    return (
        <>
            <FormGrid>
                <SectionGrid title={"Listing location"}>
                    <Grid item xs={12}>
                        {selectedRegion !== undefined && !onlySuburbs && (
                            <RegionDropdown
                                disabled={disabled}
                                onlySuburb={onlySuburbs}
                                regions={regions}
                                selectedRegion={selectedRegion}
                                onClickCallback={onRegionChanged}
                            />
                        )}
                        {selectedRegion !== undefined && onlySuburbs && (
                            <RegionAutocomplete
                                disabled={disabled}
                                required={true}
                                onlySuburbs={onlySuburbs}
                                selected={selectedRegion}
                                regions={regions}
                                onSelected={onRegionChanged}
                            />
                        )}
                    </Grid>
                </SectionGrid>

                <SectionGrid
                    title={type.allowEvents ? "Event venue" : "Name of business, attraction or venue"}
                    note={"Do not enter your address into this field"}
                    noteColor="red"
                >
                    <Grid item xs={12}>
                        <AutocompleteGooglePlaces
                            label="Find your location in Google"
                            onChange={onGooglePlaceChange}
                            passResetState={(fun) => (resetAutocomplete = fun)}
                            fullWidth
                            disabled={!selectedRegion || !venueIsGoogle}
                            location={new LatLng(latitude || selectedRegion?.latitude || 0, longitude || selectedRegion?.longitude || 0)}
                            radius={100000}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={disabled}
                                    checked={!venueIsGoogle}
                                    color="primary"
                                    onClick={() => onVenueIsGoolgeChange(!venueIsGoogle)}
                                    name="noMapSearch"
                                />
                            }
                            label={`Select to manually enter a ${venueLabel} and address`}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled={disabled || venueIsGoogle}
                            name="venue"
                            id="venue"
                            label={venueLabel}
                            placeholder={venueLabel}
                            value={venue}
                            onChange={onTextFieldChange}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled={disabled || venueIsGoogle}
                            name="street-address"
                            id="streetAddress"
                            label={`${venueLabel} Address`}
                            placeholder={`Enter your listings ${venueLabel} address`}
                            value={streetAddress}
                            onChange={onTextFieldChange}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            disabled={disabled || venueIsGoogle}
                            name="suburb"
                            id="suburb"
                            label="Suburb"
                            placeholder="Enter your listings suburb"
                            value={suburb}
                            onChange={onTextFieldChange}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <TextField
                            disabled={disabled || venueIsGoogle}
                            name="city"
                            id="city"
                            label="City (optional)"
                            placeholder="Enter your listings city"
                            value={city}
                            onChange={onTextFieldChange}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={3} xs={12}>
                        <TextField
                            disabled={disabled || venueIsGoogle}
                            name="postcode"
                            id="postcode"
                            label="Postcode"
                            placeholder="Postcode"
                            value={postcode}
                            onChange={onTextFieldChange}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={9}>
                        <FormControl variant="outlined" disabled={disabled || venueIsGoogle} fullWidth>
                            <InputLabel id="state">State</InputLabel>
                            <Select
                                name="state"
                                id="state"
                                label="State"
                                placeholder="Select state"
                                value={stateId}
                                onChange={(e) => onStateChange(e.target.value as number)}
                            >
                                {states.map((s) => (
                                    <MenuItem key={`state_${s.pkStateID}`} value={s.pkStateID}>
                                        {s.stateAbbr}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </SectionGrid>

                <SectionGrid title={"Coordinates"}>
                    <Grid item md={6} xs={12}>
                        <TextField
                            disabled={disabled}
                            type="number"
                            name="latitude"
                            id="latitude"
                            label="Latitude"
                            placeholder="Latitude"
                            value={latitude}
                            onChange={(e) => onLatChange(Number(e.target.value))}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <TextField
                            disabled={disabled}
                            type="number"
                            name="longitude"
                            id="longitude"
                            label="Longitude"
                            placeholder="Longitude"
                            value={longitude}
                            onChange={(e) => onLngChange(Number(e.target.value))}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </SectionGrid>

                <SectionGrid title={"Set Map Pin"}>
                    <Grid item xs={12}>
                        The map pin can be modified by entering coordinates or using drag and drop.
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button disabled={disabled} fullWidth variant="contained" onClick={updatePositionFromRegion}>
                            Use town/suburb
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            disabled={disabled}
                            fullWidth
                            variant="contained"
                            onClick={updatePositionFromAddressClick}
                        >
                            Use address
                        </Button>
                    </Grid>
                    {geocoderInProgress && <Loading text={"Searching for position"} />}
                    {geocoderError && (
                        <Alert variant="filled" severity="error">
                            {geocoderError}
                        </Alert>
                    )}

                    <Grid item xs={12}>
                        <Map ref={mapRef} bounds={AUSTRALIA_BOUNDS} className={classes.map} maxZoom={18}>
                            <LayersControl position="topright">
                                <BaseLayer checked name="Google Street">
                                    <TileLayer attribution="" url={Config.MAP_GOOGLE_STREET} />
                                </BaseLayer>
                                <BaseLayer name="Google Hybrid">
                                    <TileLayer attribution="" url={Config.MAP_GOOGLE_HYBRID} />
                                </BaseLayer>
                                <BaseLayer name="Google Satellite">
                                    <TileLayer attribution="" url={Config.MAP_GOOGLE_SATELLITE} />
                                </BaseLayer>
                            </LayersControl>
                            {markerPosition && (
                                <Marker
                                    position={markerPosition}
                                    draggable={!disabled}
                                    ondragend={(e) => onMarkerMoved(e.target._latlng)}
                                />
                            )}
                        </Map>
                    </Grid>
                </SectionGrid>

                <SectionGrid title={"Map view settings"}>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={disabled}
                                    checked={noMapSearch}
                                    color="primary"
                                    onClick={() => onChange({ ...values, noMapSearch: !noMapSearch })}
                                    name="noMapSearch"
                                />
                            }
                            label="Don't show listing on map search results"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={disabled}
                                    checked={noMapProfile}
                                    color="primary"
                                    onClick={() => onChange({ ...values, noMapProfile: !noMapProfile })}
                                    name="noMapProfile"
                                />
                            }
                            label="Disable map display on Listing Profile"
                        />
                    </Grid>
                </SectionGrid>
                
                <SectionGrid title={"Directions"}>
                    <Grid item xs={12}>
                        <TextField
                            error={directionsError}
                            disabled={disabled}
                            name="location"
                            id="location"
                            label="Directions"
                            placeholder="Enter the directions"
                            value={location}
                            onChange={onTextFieldChange}
                            variant="outlined"
                            multiline
                            rows={5}
                            fullWidth
                            helperText={directionsHelp}
                        />
                    </Grid>
                </SectionGrid>
            </FormGrid>
            {alerMessage && <InfoDialog message={alerMessage} onClose={() => setAlertMessage(undefined)} />}
        </>
    );
};

export default LocationForm;
