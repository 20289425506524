import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApiPremium, { BodyPremiumListing } from "../../../../Api/ApiPremium";
import { ListingLevelLinkDTO, PremiumEstimateDTO, PremiumPlanDTO } from "../../../../Api/Model";
import { snackMessageCaughtErrorAction } from "../../../../Store/App/actions";
import { selectUser } from "../../../../Store/User/selectors";
import { updateListingAction } from "../../../../Store/Wizard/actions";
import { selectListing, selectPremiumPlans } from "../../../../Store/Wizard/selectors";
import ScoopUtil from "../../../../Util/ScoopUtil";
import AlertDialog from "../../../UI/AlertDialog";
import LoadingDialog from "../../../UI/LoadingDialog";
import ReferralCodeInput from "./ReferralCodeInput";

const DIALOG_UPDATE = 'Select CONFIRM to update your subscription. Your current subscription will be cancelled and a credit for the remaining portion (if applicable) will be applied to the new subscription.';

interface PremiumPlanPros {}

const PremiumFormNonEvents = (props: PremiumPlanPros) => {
    const dispatch = useDispatch();

    const authUser = useSelector(selectUser);
    const listing = useSelector(selectListing);
    const plans = useSelector(selectPremiumPlans);
    const [planType, setPlanType] = useState<string>("Month");
    const [selected, setSelected] = useState<PremiumPlanDTO>(undefined);
    const [appliedReferral, setAppliedReferral] = useState<string>(undefined);
    const [hostedPageId, setHostedPagedId] = useState<string>(undefined);
    const [activeChargebee, setActiveChargebee] = useState<ListingLevelLinkDTO>(undefined);
    const [updateDialog, setUpdateDialog] = useState<boolean>(false);

    const [filteredPlans, setFilteredPlans] = useState<PremiumPlanDTO[]>([]);

    const [estimate, setEstimate] = useState<PremiumEstimateDTO>();

    useEffect(() => {
        const currencyCode = listing?.details?.countryCode === "NZ" ? "NZD" : "AUD";
        const filtered = plans.filter((p) => p.periodUnit === planType && p.currencyCode === currencyCode);
        setFilteredPlans(filtered);
        setEstimate(undefined);
        setSelected(undefined);
    }, [listing, plans, authUser, planType]);

    useEffect(() => {
        if (listing) {
            setActiveChargebee(listing.levels.find((ll) => ll.isActive && !!!ll.dtDeleted && ll.chargebeeSubscriptionID?.length > 0));
        } else {
            setActiveChargebee(undefined);
        }
    }, [listing]);

    useEffect(() => {
        if (hostedPageId) {
            dispatch(
                updateListingAction(
                    ApiPremium.processHostedPage(listing.details.pkListing, hostedPageId).then((res) => {
                        setHostedPagedId(undefined);
                        setEstimate(undefined);
                        setSelected(undefined);
                        return res;
                    })
                )
            );
        }
    }, [dispatch, hostedPageId, listing]);

    useEffect(() => {
        if (selected && listing) {
            const body = constructPremiumListingBody(activeChargebee, selected, appliedReferral);
            ApiPremium.getEstimate(listing.details.pkListing, body)
                .then((res) => {
                    setEstimate(res);
                })
                .catch((err) => {
                    dispatch(snackMessageCaughtErrorAction(err));
                    setEstimate(undefined);
                });
        } else {
            setEstimate(undefined);
        }
    }, [dispatch, selected, listing, appliedReferral, activeChargebee]);

    const onPlanChanged = (e: any) => {
        var itemPriceId = e.target.value;
        var product = plans.find((p) => p.itemPriceId === itemPriceId);
        setSelected(product);
    };

    const onUpdateClick = () => {
        setUpdateDialog(true);
    };

    const launchChargebeeHostedPage = () => {
        setUpdateDialog(false);
        const body = constructPremiumListingBody(activeChargebee, selected, appliedReferral);
        let cbInstance = (window as any).Chargebee.getInstance();
        cbInstance.openCheckout({
            hostedPage: function () {
                return ApiPremium.subscribeToPlan(listing.details.pkListing, body).catch((err) => {
                    dispatch(snackMessageCaughtErrorAction(err));
                    return err;
                });
            },
            error: function (error) {
                console.log("chargebee:error");
                console.error(error);
                // Optional
                // will be called if the promise passed causes an error
            },
            success: function (hostedPageId) {
                console.log("chargebee:success", hostedPageId);
                setHostedPagedId(hostedPageId);
                // cbInstance.closeAll();

                // Optional
                // will be called when a successful checkout happens.
            },
            close: function () {
                console.log("chargebee:close");
                // Optional
                // will be called when the user closes the checkout modal box
            },
        });
    };

    return (
        <>
            <LoadingDialog open={hostedPageId !== undefined} text="Processing subscription" />
            <Grid item xs={12}>
                Create a subscription
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="select-type-label">Select plan</InputLabel>
                    <Select label="Select plan" value={planType} onChange={(e) => setPlanType(e.target.value)}>
                        <MenuItem value={"Month"}>Monthly subscription</MenuItem>
                        <MenuItem value={"Year"}>Annual subscription (25% discount)</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="select-type-label">Select listing level</InputLabel>
                    <Select label="Select listing level" value={selected?.itemPriceId || ""} onChange={onPlanChanged}>
                        {filteredPlans.map((p) => (
                            <MenuItem key={p.itemPriceId} value={p.itemPriceId}>
                                {formatFullName(p)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <ReferralCodeInput
                listingId={listing?.details?.pkListing}
                appliedReferralCode={appliedReferral}
                constructPremiumListingBody={(code) => constructPremiumListingBody(activeChargebee, selected, code)}
                onReferralCodeUpdate={setAppliedReferral}
            />

            <Grid item xs={12}>
                Subscription details
            </Grid>
            <Grid item xs={12}>
                <TextField
                    disabled
                    label="Subscription plan"
                    value={formatFullName(selected)}
                    variant="outlined"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    disabled
                    label="Referral discount"
                    value={formatReferralDiscount(estimate, planType)}
                    variant="outlined"
                    fullWidth
                />
            </Grid>

            <Grid item xs={estimate?.tax > 0 ? 6 : 12}>
                <TextField
                    disabled
                    label="Sub total"
                    value={`${ScoopUtil.formatPriceInCents(
                        estimate?.subTotal - estimate?.referralDiscount
                    )} per ${planType?.toLocaleLowerCase()}`}
                    variant="outlined"
                    fullWidth
                />
            </Grid>

            {estimate?.tax > 0 && (
                <Grid item xs={6}>
                    <TextField
                        disabled
                        label="Tax"
                        value={ScoopUtil.formatPriceInCents(estimate?.tax)}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            )}

            <Grid item xs={12}>
                <TextField
                    disabled
                    label={`Monthly subscription${estimate?.tax > 0 ? " (incl tax)" : ""}`}
                    value={ScoopUtil.formatPriceInCents(
                        estimate?.subTotal - estimate?.referralDiscount + estimate?.tax
                    )}
                    variant="outlined"
                    fullWidth
                />
            </Grid>

            {estimate?.credits > 0 && (
                <Grid item xs={12}>
                    <TextField
                        disabled
                        label="Applied credits"
                        value={ScoopUtil.formatPriceInCents(estimate?.credits)}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            )}

            <Grid item xs={12}>
                <TextField
                    disabled
                    label={"First payment"}
                    value={ScoopUtil.formatPriceInCents(estimate?.amountDue)}
                    variant="outlined"
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
                <Button
                    variant="contained"
                    disabled={estimate === undefined}
                    fullWidth
                    onClick={activeChargebee ? onUpdateClick : launchChargebeeHostedPage}
                >
                    {activeChargebee ? "Update existing subscription" : "Subscribe"}
                </Button>
            </Grid>
            {updateDialog && (
                <AlertDialog
                    onClose={() => setUpdateDialog(false)}
                    onConfirm={launchChargebeeHostedPage}
                    title="Update Subscription"
                    message={DIALOG_UPDATE}
                    closeText="close"
                />
            )}
        </>
    );
};

export default PremiumFormNonEvents;

const formatReferralDiscount = (estimate: PremiumEstimateDTO, planType?: string): string => {
    if (estimate?.referralDiscount > 0) {
        return `${ScoopUtil.formatPriceInCents(estimate.referralDiscount)} per ${planType?.toLocaleLowerCase()} (${
            estimate.referralCode
        })`;
    } else {
        return "";
    }
};

const formatFullName = (p?: PremiumPlanDTO): string => {
    if (p) {
        return `${p.externalName} - ${ScoopUtil.formatPriceInCents(p.price)} per ${p.periodUnit.toLocaleLowerCase()}`;
    }
    return "";
};

const constructPremiumListingBody = (
    activeChargebee: ListingLevelLinkDTO,
    selected: PremiumPlanDTO,
    referralCode?: string
): BodyPremiumListing => {
    const body: BodyPremiumListing = {
        itemPriceId: selected.itemPriceId,
        itemFamilyId: selected.itemFamilyId,
        itemId: selected.itemId,
        itemType: selected.itemType,
        currencyCode: selected.currencyCode,
        referralCode: referralCode,
        listingLevelLinkId: activeChargebee?.linkID,
    };
    return body;
};
