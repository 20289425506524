import { Box, Card, Typography, useMediaQuery } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import ForgotPassword from '../Login/ForgotPassword';
import Login from '../Login/Login';
import Register from '../Login/Register';


const useStyles = makeStyles((theme) => ({
    contentContainer: {
        padding: '64px',
    },
    contentContainerMobile: {
        padding: '20px',
    },
    card: {
        padding: '20px',
        alignContent: 'center',
    },
    image: {
        width: "150%",
        marginBottom: "15px"
    },
    heroContent: {
        maxWidth: '500px',
        margin: 'auto'
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    heroHeading: {
        fontWeight: 700,
        fontFamily: 'Inter',
        fontSize: '4rem',
        color: theme.palette.common.black,
        marginBottom: '5px'
    },
    heroBody: {
        fontWeight: 400,
        fontFamily: 'Inter',
        fontSize: '18px',
        color: theme.palette.common.black
    }
}));


enum LoginMode {
    Login,
    Register,
    ForgotPassword
}

const LandingPage = () => {
    const widthMoreThan980px = useMediaQuery('(min-width:980px)');

    const classes = useStyles();
    const [loginMode, setLoginMode] = useState<LoginMode>(LoginMode.Login)

    return (
        <Container className={widthMoreThan980px ? classes.contentContainer : classes.contentContainerMobile}>
            <Grid container spacing={3} alignContent="center" alignItems="center">
                <Grid item xs >
                    <Box className={classes.heroContent}>
                        <Typography className={classes.heroHeading} component="h1" gutterBottom>
                            localista
                        </Typography>
                        <Typography className={classes.heroBody} variant="h5" paragraph>
                            Welcome to the largest lifestyle & travel network in Australia and New Zealand: 
                            sharing 120,000 listings and 800,000 pages of content from 60,000 operators with a rapidly growing network of websites 
                            and apps attracting more than 1 million users: <a href="https://localista.com.au" target="_blank" rel="noopener noreferrer">localista.com.au</a>
                        </Typography>
                        <Card className={classes.card}>
                            {loginMode === LoginMode.Login ?
                                <Login
                                    onClickRegister={() => setLoginMode(LoginMode.Register)}
                                    onClickForgotPassword={() => setLoginMode(LoginMode.ForgotPassword)}
                                />
                                : null}

                            {loginMode === LoginMode.Register ?
                                <Register
                                    onClickLogin={() => setLoginMode(LoginMode.Login)}
                                    onClickForgotPassword={() => setLoginMode(LoginMode.ForgotPassword)}
                                />
                                : null}

                            {loginMode === LoginMode.ForgotPassword ?
                                <ForgotPassword
                                    onClickLogin={() => setLoginMode(LoginMode.Login)}
                                />
                                : null}
                        </Card>
                    </Box>

                </Grid>
                {widthMoreThan980px && <Grid item xs container alignItems="flex-end">
                    <img className={classes.image} src="/assets/laptop-localista-hero.png" alt="localista-example" />
                </Grid>}

            </Grid>
        </Container>
    )
};

export default LandingPage;