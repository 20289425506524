import RefreshIcon from "@mui/icons-material/Refresh";
import { Button, Grid, useMediaQuery } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ListingDTO, ListingTypeDTO } from "../../../Api/Model";
import { selectTypes } from "../../../Store/App/selectors";
import { fetchMyListingsAction } from "../../../Store/Dashboard/actions";
import { selectListings, selectListingsFilter, selectListingsLoading } from "../../../Store/Dashboard/selectors";
import { selectUser } from "../../../Store/User/selectors";
import { resetListingAction } from "../../../Store/Wizard/actions";
import HeaderActions from "../HeaderActions";
import HeaderContainer from "../HeaderContainer";
import HeaderTitle from "../HeaderTitle";
import Filters from "./Filters";
import ListingsDataTable from "./ListingsDataTable";
import ListingTableFirstTimeUser from "./ListingTableFirstTimeUser";
import ListingTableNoData from "./ListingTableNoData";

interface ListingPreviewTableProps {}

const MyListings = (props: ListingPreviewTableProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const theme = useTheme();
    const widthXS = useMediaQuery(theme.breakpoints.down('sm'));

    const user = useSelector(selectUser);
    const listings = useSelector(selectListings);
    const loading = useSelector(selectListingsLoading);
    const types = useSelector(selectTypes);
    const filterValues = useSelector(selectListingsFilter);

    const [availableTypes, setAvailableTypes] = useState<ListingTypeDTO[]>([]);
    const [filtered, setFiltered] = useState<ListingDTO[]>([]);

    useEffect(() => {
        if (user && listings === undefined && !loading) {
            dispatch(fetchMyListingsAction());
        }
    }, [dispatch, listings, loading, user]);

    useEffect(() => {
        if (listings && types) {
            let fkTypes = listings.map((l) => l.fkListingType);
            setAvailableTypes(types.filter((lt) => fkTypes.includes(lt.pkListingType)));
        } else {
            setAvailableTypes([]);
        }
    }, [listings, types]);

    useEffect(() => {
        if (listings) {
            if (filterValues) {
                let now = new Date();
                let m = now.getMonth() + 1;
                let d = now.getDate();
                let nowISO = `${now.getFullYear()}-${m < 10 ? "0" : ""}${m}-${d < 10 ? "0" : ""}${d}T00:00:00`;
                let keyword = filterValues.keyword.toLowerCase();
                let filtered = listings.filter(
                    (l) =>
                        l.txtListingName.toLocaleLowerCase().includes(keyword) &&
                        (filterValues.typeId ? l.fkListingType === filterValues.typeId : true) &&
                        (filterValues.includeExpired ? true : l.dtEventTo ? l.dtEventTo >= nowISO : true)
                );
                setFiltered(filtered);
            } else {
                setFiltered(listings);
            }
        } else {
            setFiltered([]);
        }
    }, [listings, filterValues]);

    const handleClickNewListing = () => {
        dispatch(resetListingAction());
        history.push("/wizard");
    };

    const refresh = () => {
        dispatch(fetchMyListingsAction());
    };

    return (
        <React.Fragment>
            <HeaderContainer>
                <HeaderTitle title={"My Listings"} markdownHelp={"/assets/help/MyListings.md"} />
                <HeaderActions>
                    <Button disabled={loading} variant="contained" color="primary" onClick={handleClickNewListing}>
                        {widthXS ? "Add" : "Add new listing"}
                    </Button>
                    <Button style={{marginLeft: '10px'}} variant="contained" color="primary" onClick={refresh} disabled={loading}>
                        <RefreshIcon /> Refresh
                    </Button>
                </HeaderActions>
            </HeaderContainer>

            <Grid container spacing={1}>
                <Filters types={availableTypes} />
                {loading && (
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                )}
                <Grid item xs={12}>
                    {!loading && listings && listings.length > 0 && <ListingsDataTable list={filtered} />}
                    {!loading && listings && listings.length === 0 && <ListingTableFirstTimeUser />}
                    {!loading && listings && listings.length > 0 && filtered.length === 0 && <ListingTableNoData />}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default MyListings;
